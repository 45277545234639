import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './zamwieniabutton.css'

const Zamwieniabutton = (props) => {
  return (
    <div className="zamwieniabutton-container">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="zamwieniabutton-dummy-container">
        <button className="zamwieniabutton-zamwieniabutton">
          <div className="zamwieniabutton-group155">
            <img
              alt="Rectangle933452"
              src="/external/rectangle933452-0vvv-600w.png"
              className="zamwieniabutton-rectangle93"
            />
            <img
              alt="Rectangle943452"
              src="/external/rectangle943452-4uaa-600w.png"
              className="zamwieniabutton-rectangle94"
            />
          </div>
          <img
            alt="Rectangle713452"
            src="/external/rectangle713452-3h9i-1000h.png"
            className="zamwieniabutton-rectangle71"
          />
          <img
            alt="Rectangle703452"
            src="/external/rectangle703452-3kf-200h.png"
            className="zamwieniabutton-rectangle70"
          />
          <img
            alt="LGOOROBOCZEV00113452"
            src="/external/lgooroboczev00113452-01l8-200h.png"
            className="zamwieniabutton-lgooroboczev0011"
          />
          <div className="zamwieniabutton-frame19">
            <Link
              to="/u-stawieniakont-abutton"
              className="zamwieniabutton-text10"
            >
              <span>Konto</span>
            </Link>
            <span className="zamwieniabutton-text12">
              <span>Wiadomości</span>
            </span>
            <Link to="/zamwieniabutton" className="zamwieniabutton-text14">
              <span>Zamówienia</span>
            </Link>
            <Link
              to="/oknopowiadomi-ebutton"
              className="zamwieniabutton-text16"
            >
              <span>Powiadomienia</span>
            </Link>
            <span className="zamwieniabutton-text18">
              <span>Zamówienia</span>
            </span>
          </div>
          <img
            alt="image123452"
            src="/external/image123452-3b1h-200h.png"
            className="zamwieniabutton-image12"
          />
          <span className="zamwieniabutton-text20">
            <span>Pan Twarug</span>
          </span>
          <img
            alt="Rectangle123453"
            src="/external/rectangle123453-6spb-200h.png"
            className="zamwieniabutton-rectangle12"
          />
          <img
            alt="Rectangle723453"
            src="/external/rectangle723453-rerb-200h.png"
            className="zamwieniabutton-rectangle72"
          />
          <img
            alt="Rectangle233453"
            src="/external/rectangle233453-7a4-200h.png"
            className="zamwieniabutton-rectangle23"
          />
          <img
            alt="image133453"
            src="/external/image133453-0fed-200h.png"
            className="zamwieniabutton-image13"
          />
          <div className="zamwieniabutton-frame9">
            <span className="zamwieniabutton-text22">
              <span>Q&amp;A</span>
            </span>
          </div>
          <img
            alt="Ellipse233453"
            src="/external/ellipse233453-9ufw-200h.png"
            className="zamwieniabutton-ellipse23"
          />
          <span className="zamwieniabutton-text24">
            <span>Zamówienia</span>
          </span>
          <span className="zamwieniabutton-text26">
            <span>Zakończone Zamówienia</span>
          </span>
          <div className="zamwieniabutton-group170">
            <div className="zamwieniabutton-group991">
              <div className="zamwieniabutton-frame681">
                <button className="zamwieniabutton-button1">
                  <span className="zamwieniabutton-text28 SingleLineBodyBase">
                    <span>Potwierdź</span>
                    <br></br>
                    <span>Odbiór</span>
                  </span>
                </button>
              </div>
              <div className="zamwieniabutton-frame771">
                <button className="zamwieniabutton-button2">
                  <span className="zamwieniabutton-text32 SingleLineBodyBase">
                    <span>Otwórz Briff</span>
                  </span>
                </button>
              </div>
              <div className="zamwieniabutton-frame761">
                <div className="zamwieniabutton-frame731">
                  <div className="zamwieniabutton-frame3d-avatars151">
                    <img
                      alt="Avatars3davatar15I345"
                      src="/external/avatars3davatar15i345-ww6u-200h.png"
                      className="zamwieniabutton-avatars3davatar151"
                    />
                  </div>
                  <span className="zamwieniabutton-text34">
                    <span className="zamwieniabutton-text35">
                      Wykonuje zamówienie:
                    </span>
                    <span> Dupcyper</span>
                  </span>
                </div>
                <div className="zamwieniabutton-frame751">
                  <span className="zamwieniabutton-text37">
                    <span>Wykonaj Ahegao</span>
                  </span>
                  <div className="zamwieniabutton-frame741">
                    <div className="zamwieniabutton-group841">
                      <span className="zamwieniabutton-text39">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="zamwieniabutton-group871">
                      <span className="zamwieniabutton-text41">
                        <span>Design grafika</span>
                      </span>
                    </div>
                    <div className="zamwieniabutton-group861">
                      <span className="zamwieniabutton-text43">
                        <span>Mascot</span>
                      </span>
                    </div>
                  </div>
                  <span className="zamwieniabutton-text45">
                    <span>Ahegao co by tu mówić więcej</span>
                  </span>
                  <div className="zamwieniabutton-frame721">
                    <div className="zamwieniabutton-frame701">
                      <img
                        alt="Dollarsign3453"
                        src="/external/dollarsign3453-tfc.svg"
                        className="zamwieniabutton-dollarsign1"
                      />
                      <span className="zamwieniabutton-text47">
                        <span>Budżet: 20,00 zł</span>
                      </span>
                    </div>
                    <div className="zamwieniabutton-frame711">
                      <div className="zamwieniabutton-person1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span className="zamwieniabutton-text49">!</span>
          </div>
          <div className="zamwieniabutton-group171">
            <div className="zamwieniabutton-group992">
              <div className="zamwieniabutton-frame682"></div>
              <div className="zamwieniabutton-frame772">
                <button className="zamwieniabutton-button3">
                  <span className="zamwieniabutton-text50 SingleLineBodyBase">
                    <span>Otwórz Briff</span>
                  </span>
                </button>
              </div>
              <div className="zamwieniabutton-frame762">
                <div className="zamwieniabutton-frame732">
                  <div className="zamwieniabutton-frame3d-avatars152">
                    <img
                      alt="Avatars3davatar15I345"
                      src="/external/avatars3davatar15i345-475a-200h.png"
                      className="zamwieniabutton-avatars3davatar152"
                    />
                  </div>
                  <span className="zamwieniabutton-text52">
                    <span className="zamwieniabutton-text53">
                      Wykonuje zamówienie:
                    </span>
                    <span> Dupcyper</span>
                  </span>
                </div>
                <div className="zamwieniabutton-frame752">
                  <span className="zamwieniabutton-text55">
                    <span>Wykonaj Ahegao</span>
                  </span>
                  <div className="zamwieniabutton-frame742">
                    <div className="zamwieniabutton-group842">
                      <span className="zamwieniabutton-text57">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="zamwieniabutton-group872">
                      <span className="zamwieniabutton-text59">
                        <span>Design grafika</span>
                      </span>
                    </div>
                    <div className="zamwieniabutton-group862">
                      <span className="zamwieniabutton-text61">
                        <span>Mascot</span>
                      </span>
                    </div>
                  </div>
                  <span className="zamwieniabutton-text63">
                    <span>Ahegao co by tu mówić więcej</span>
                  </span>
                  <div className="zamwieniabutton-frame722">
                    <div className="zamwieniabutton-frame702">
                      <img
                        alt="Dollarsign3453"
                        src="/external/dollarsign3453-u6gb.svg"
                        className="zamwieniabutton-dollarsign2"
                      />
                      <span className="zamwieniabutton-text65">
                        <span>Budżet: 20,00 zł</span>
                      </span>
                    </div>
                    <div className="zamwieniabutton-frame712">
                      <div className="zamwieniabutton-person2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span className="zamwieniabutton-text67">!</span>
          </div>
          <div className="zamwieniabutton-group56">
            <div className="zamwieniabutton-group441">
              <div className="zamwieniabutton-frame211">
                <img
                  alt="Ellipse113453"
                  src="/external/ellipse113453-mdyv-200h.png"
                  className="zamwieniabutton-ellipse111"
                />
                <img
                  alt="Ellipse123453"
                  src="/external/ellipse123453-tbi9-200h.png"
                  className="zamwieniabutton-ellipse121"
                />
                <img
                  alt="Ellipse133453"
                  src="/external/ellipse133453-a9r5-200h.png"
                  className="zamwieniabutton-ellipse131"
                />
              </div>
            </div>
            <img
              alt="Rectangle223453"
              src="/external/rectangle223453-0m2w-200w.png"
              className="zamwieniabutton-rectangle221"
            />
            <img
              alt="pngwing13453"
              src="/external/pngwing13453-z9c-200w.png"
              className="zamwieniabutton-pngwing11"
            />
            <span className="zamwieniabutton-text68">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle733453"
              src="/external/rectangle733453-5bz-200w.png"
              className="zamwieniabutton-rectangle731"
            />
            <span className="zamwieniabutton-text70">
              <span>Zlecenie 3415: Dupp</span>
            </span>
          </div>
          <div className="zamwieniabutton-group102">
            <div className="zamwieniabutton-group442">
              <div className="zamwieniabutton-frame212">
                <img
                  alt="Ellipse113453"
                  src="/external/ellipse113453-96z-200h.png"
                  className="zamwieniabutton-ellipse112"
                />
                <img
                  alt="Ellipse123453"
                  src="/external/ellipse123453-oaz6-200h.png"
                  className="zamwieniabutton-ellipse122"
                />
                <img
                  alt="Ellipse133453"
                  src="/external/ellipse133453-wb4-200h.png"
                  className="zamwieniabutton-ellipse132"
                />
              </div>
            </div>
            <img
              alt="Rectangle223453"
              src="/external/rectangle223453-7ss-200w.png"
              className="zamwieniabutton-rectangle222"
            />
            <img
              alt="pngwing13453"
              src="/external/pngwing13453-27cb-200w.png"
              className="zamwieniabutton-pngwing12"
            />
            <span className="zamwieniabutton-text72">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle733453"
              src="/external/rectangle733453-1nwf-200w.png"
              className="zamwieniabutton-rectangle732"
            />
            <span className="zamwieniabutton-text74">
              <span>Zlecenie 3415: Dupp</span>
            </span>
          </div>
          <div className="zamwieniabutton-group103">
            <div className="zamwieniabutton-group443">
              <div className="zamwieniabutton-frame213">
                <img
                  alt="Ellipse113453"
                  src="/external/ellipse113453-20z-200h.png"
                  className="zamwieniabutton-ellipse113"
                />
                <img
                  alt="Ellipse123453"
                  src="/external/ellipse123453-3y8-200h.png"
                  className="zamwieniabutton-ellipse123"
                />
                <img
                  alt="Ellipse133453"
                  src="/external/ellipse133453-6jp57-200h.png"
                  className="zamwieniabutton-ellipse133"
                />
              </div>
            </div>
            <img
              alt="Rectangle223453"
              src="/external/rectangle223453-eurj-200h.png"
              className="zamwieniabutton-rectangle223"
            />
            <img
              alt="pngwing13453"
              src="/external/pngwing13453-iuo-200h.png"
              className="zamwieniabutton-pngwing13"
            />
            <span className="zamwieniabutton-text76">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle733453"
              src="/external/rectangle733453-ea5s-200w.png"
              className="zamwieniabutton-rectangle733"
            />
            <span className="zamwieniabutton-text78">
              <span>Zlecenie 3415: Dupp</span>
            </span>
          </div>
          <img
            alt="Rectangle1203453"
            src="/external/rectangle1203453-rjb-200h.png"
            className="zamwieniabutton-rectangle120"
          />
          <div className="zamwieniabutton-frame206">
            <span className="zamwieniabutton-text80">
              <span>Zgłoś problem</span>
            </span>
            <span className="zamwieniabutton-text82">
              <span>Zrezygnuj z zlecenia</span>
            </span>
            <span className="zamwieniabutton-text84">
              <span>Zgłoś poprawkę</span>
            </span>
          </div>
        </button>
      </div>
    </div>
  )
}

export default Zamwieniabutton
