import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './widokczat-ubutton.css'

const WIDOKCZATUbutton = (props) => {
  return (
    <div className="widokczat-ubutton-container">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="widokczat-ubutton-dummy-container">
        <button className="widokczat-ubutton-widokczat-ubutton">
          <img
            alt="Rectangle716686"
            src="/external/rectangle716686-81a-400w.png"
            className="widokczat-ubutton-rectangle71"
          />
          <img
            alt="Rectangle706685"
            src="/external/rectangle706685-qeco-200h.png"
            className="widokczat-ubutton-rectangle70"
          />
          <img
            alt="LGOOROBOCZEV00116611"
            src="/external/lgooroboczev00116611-8c4z-200h.png"
            className="widokczat-ubutton-lgooroboczev0011"
          />
          <img
            alt="Rectangle696634"
            src="/external/rectangle696634-vr7k-200h.png"
            className="widokczat-ubutton-rectangle69"
          />
          <div className="widokczat-ubutton-frame19">
            <Link
              to="/u-stawieniakont-abutton"
              className="widokczat-ubutton-text10"
            >
              <span>Konto</span>
            </Link>
            <span className="widokczat-ubutton-text12">
              <span>Wiadomości</span>
            </span>
            <Link to="/zamwieniabutton" className="widokczat-ubutton-text14">
              <span>Zamówienia</span>
            </Link>
            <Link
              to="/oknopowiadomi-ebutton"
              className="widokczat-ubutton-text16"
            >
              <span>Powiadomienia</span>
            </Link>
            <Link
              to="/ekran-zleceotwartychbutton"
              className="widokczat-ubutton-text18"
            >
              <span> zlecenia</span>
            </Link>
          </div>
          <img
            alt="image126642"
            src="/external/image126642-bwg-200h.png"
            className="widokczat-ubutton-image12"
          />
          <span className="widokczat-ubutton-text20">
            <span>Pan Twarug</span>
          </span>
          <img
            alt="Rectangle26644"
            src="/external/rectangle26644-b22e-200h.png"
            className="widokczat-ubutton-rectangle2"
          />
          <span className="widokczat-ubutton-text22">
            <span>Wyślij wiadomość</span>
          </span>
          <div className="widokczat-ubutton-group16">
            <img
              alt="Ellipse76647"
              src="/external/ellipse76647-xh1-200h.png"
              className="widokczat-ubutton-ellipse7"
            />
            <img
              alt="Ellipse86648"
              src="/external/ellipse86648-9dyg-200h.png"
              className="widokczat-ubutton-ellipse8"
            />
            <img
              alt="Ellipse96649"
              src="/external/ellipse96649-7pui-200h.png"
              className="widokczat-ubutton-ellipse9"
            />
            <img
              alt="Ellipse106650"
              src="/external/ellipse106650-f4m-200h.png"
              className="widokczat-ubutton-ellipse10"
            />
          </div>
          <img
            alt="hi26651"
            src="/external/hi26651-iw6i-200h.png"
            className="widokczat-ubutton-hi2"
          />
          <img
            alt="hi16652"
            src="/external/hi16652-npda-200h.png"
            className="widokczat-ubutton-hi1"
          />
          <img
            alt="Rectangle126653"
            src="/external/rectangle126653-lefj-200h.png"
            className="widokczat-ubutton-rectangle12"
          />
          <img
            alt="Rectangle236654"
            src="/external/rectangle236654-c8o-200h.png"
            className="widokczat-ubutton-rectangle23"
          />
          <img
            alt="Rectangle226655"
            src="/external/rectangle226655-8e7-200w.png"
            className="widokczat-ubutton-rectangle22"
          />
          <img
            alt="pngwing16656"
            src="/external/pngwing16656-c6it-200h.png"
            className="widokczat-ubutton-pngwing1"
          />
          <img
            alt="pngwing21532"
            src="/external/pngwing21532-7spg-200h.png"
            className="widokczat-ubutton-pngwing21"
          />
          <div className="widokczat-ubutton-group20">
            <img
              alt="Rectangle246658"
              src="/external/rectangle246658-cccb-200h.png"
              className="widokczat-ubutton-rectangle24"
            />
            <img
              alt="pngwing26659"
              src="/external/pngwing26659-jkgl-200h.png"
              className="widokczat-ubutton-pngwing22"
            />
          </div>
          <span className="widokczat-ubutton-text24">
            <span>Śmieszek artysta</span>
          </span>
          <div className="widokczat-ubutton-frame20"></div>
          <div className="widokczat-ubutton-frame21">
            <img
              alt="Ellipse116663"
              src="/external/ellipse116663-apaj-200h.png"
              className="widokczat-ubutton-ellipse11"
            />
            <img
              alt="Ellipse126664"
              src="/external/ellipse126664-16fa-200h.png"
              className="widokczat-ubutton-ellipse12"
            />
            <img
              alt="Ellipse136665"
              src="/external/ellipse136665-uk6e-200h.png"
              className="widokczat-ubutton-ellipse13"
            />
          </div>
          <div className="widokczat-ubutton-frame1">
            <span className="widokczat-ubutton-text26">
              <span>Chce logo</span>
            </span>
          </div>
          <div className="widokczat-ubutton-frame23">
            <span className="widokczat-ubutton-text28">
              <span>Jakie? Do czego?</span>
            </span>
          </div>
          <div className="widokczat-ubutton-frame24">
            <span className="widokczat-ubutton-text30">
              <span>Budżet?</span>
            </span>
          </div>
          <div className="widokczat-ubutton-frame22">
            <span className="widokczat-ubutton-text32">
              <span>Chce logo bo ja biznesmen. Mam Pindziont złotych</span>
            </span>
          </div>
          <div className="widokczat-ubutton-frame25">
            <span className="widokczat-ubutton-text34">
              <span>Nie jestem zaiteresowana</span>
            </span>
          </div>
          <div className="widokczat-ubutton-frame26">
            <span className="widokczat-ubutton-text36">
              <span>Zrobie ci reklame czy coś</span>
            </span>
          </div>
          <div className="widokczat-ubutton-frame28">
            <span className="widokczat-ubutton-text38">
              <span>No i masz bana rep.</span>
            </span>
          </div>
          <div className="widokczat-ubutton-frame27">
            <span className="widokczat-ubutton-text40">
              <span>XDD</span>
            </span>
          </div>
          <span className="widokczat-ubutton-text42">
            <span>wt.21:06</span>
          </span>
          <span className="widokczat-ubutton-text44">
            <span>wt.21:06</span>
          </span>
          <span className="widokczat-ubutton-text46">
            <span>Odczytano wt.21:06</span>
          </span>
          <img
            alt="image131011"
            src="/external/image131011-7w59-200h.png"
            className="widokczat-ubutton-image13"
          />
          <div className="widokczat-ubutton-frame9">
            <span className="widokczat-ubutton-text48">
              <span>Q&amp;A</span>
            </span>
          </div>
          <img
            alt="Ellipse231011"
            src="/external/ellipse231011-v7r-200h.png"
            className="widokczat-ubutton-ellipse23"
          />
        </button>
      </div>
    </div>
  )
}

export default WIDOKCZATUbutton
