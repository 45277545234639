import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './profil-uytkownika-opiniebutton.css'

const ProfilUytkownikaOpiniebutton = (props) => {
  return (
    <div className="profil-uytkownika-opiniebutton-container1">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="profil-uytkownika-opiniebutton-dummy-container">
        <button className="profil-uytkownika-opiniebutton-profil-uytkownika-opiniebutton">
          <div className="profil-uytkownika-opiniebutton-group115">
            <div className="profil-uytkownika-opiniebutton-frame1111">
              <img
                alt="LGOOROBOCZEV00113452"
                src="/external/lgooroboczev00113452-ox5-200h.png"
                className="profil-uytkownika-opiniebutton-lgooroboczev0011"
              />
              <div className="profil-uytkownika-opiniebutton-group114">
                <div className="profil-uytkownika-opiniebutton-input">
                  <div className="profil-uytkownika-opiniebutton-container2">
                    <span className="profil-uytkownika-opiniebutton-text10">
                      <span>Czego szukasz towarzyszu?</span>
                    </span>
                  </div>
                </div>
                <button className="profil-uytkownika-opiniebutton-button1">
                  <img
                    alt="SVG3452"
                    src="/external/svg3452-g8.svg"
                    className="profil-uytkownika-opiniebutton-svg"
                  />
                </button>
              </div>
              <button className="profil-uytkownika-opiniebutton-item-button">
                <div className="profil-uytkownika-opiniebutton-group112">
                  <span className="profil-uytkownika-opiniebutton-text12">
                    <span>Społeczność Artystyczna</span>
                  </span>
                  <img
                    alt="Vector3452"
                    src="/external/vector3452-58u.svg"
                    className="profil-uytkownika-opiniebutton-vector1"
                  />
                </div>
              </button>
              <div className="profil-uytkownika-opiniebutton-group113">
                <span className="profil-uytkownika-opiniebutton-text14">
                  <span>Q&amp;A</span>
                </span>
                <img
                  alt="Vector3452"
                  src="/external/vector3452-z94.svg"
                  className="profil-uytkownika-opiniebutton-vector2"
                />
              </div>
              <div className="profil-uytkownika-opiniebutton-item-link1">
                <span className="profil-uytkownika-opiniebutton-text16">
                  <span>Sign in</span>
                </span>
              </div>
              <div className="profil-uytkownika-opiniebutton-item-link2">
                <span className="profil-uytkownika-opiniebutton-text18">
                  <span>Join</span>
                </span>
              </div>
            </div>
          </div>
          <img
            alt="Rectangle1013452"
            src="/external/rectangle1013452-on5-200h.png"
            className="profil-uytkownika-opiniebutton-rectangle101"
          />
          <div className="profil-uytkownika-opiniebutton-frame1112">
            <span className="profil-uytkownika-opiniebutton-text20">
              <span>Cosplay</span>
            </span>
            <span className="profil-uytkownika-opiniebutton-text22">
              <span>Pisarz</span>
            </span>
            <span className="profil-uytkownika-opiniebutton-text24">
              <span>Muzyka&amp;audio</span>
            </span>
            <span className="profil-uytkownika-opiniebutton-text26">
              <span>Rysownik</span>
            </span>
            <span className="profil-uytkownika-opiniebutton-text28">
              <span>UI/Ux Design</span>
            </span>
            <span className="profil-uytkownika-opiniebutton-text30">
              <span>Grafika Komputerowa</span>
            </span>
          </div>
          <img
            alt="Rectangle1033452"
            src="/external/rectangle1033452-82g-200h.png"
            className="profil-uytkownika-opiniebutton-rectangle103"
          />
          <div className="profil-uytkownika-opiniebutton-frame188">
            <span className="profil-uytkownika-opiniebutton-text32">
              <span>Profil Artysty</span>
            </span>
            <div className="profil-uytkownika-opiniebutton-frame182">
              <img
                alt="Frame1323452"
                src="/external/frame1323452-20j.svg"
                className="profil-uytkownika-opiniebutton-frame132"
              />
              <button className="profil-uytkownika-opiniebutton-button2">
                <img
                  alt="SendI345"
                  src="/external/sendi345-136h.svg"
                  className="profil-uytkownika-opiniebutton-send"
                />
                <span className="profil-uytkownika-opiniebutton-text34 SingleLineBodyBase">
                  <span>Wyślij Zlecenie</span>
                </span>
              </button>
            </div>
          </div>
          <div className="profil-uytkownika-opiniebutton-group162">
            <div className="profil-uytkownika-opiniebutton-frame181">
              <div className="profil-uytkownika-opiniebutton-frame178">
                <div className="profil-uytkownika-opiniebutton-group157">
                  <img
                    alt="image213452"
                    src="/external/image213452-sbmt-200h.png"
                    className="profil-uytkownika-opiniebutton-image21"
                  />
                  <img
                    alt="Ellipse253452"
                    src="/external/ellipse253452-ubz-200h.png"
                    className="profil-uytkownika-opiniebutton-ellipse25"
                  />
                </div>
                <span className="profil-uytkownika-opiniebutton-text36">
                  <span>Magdalena Maria  Monika</span>
                </span>
                <div className="profil-uytkownika-opiniebutton-frame166">
                  <div className="profil-uytkownika-opiniebutton-frame1651">
                    <img
                      alt="Star13452"
                      src="/external/star13452-8pn.svg"
                      className="profil-uytkownika-opiniebutton-star11"
                    />
                    <img
                      alt="Star23452"
                      src="/external/star23452-tiub.svg"
                      className="profil-uytkownika-opiniebutton-star21"
                    />
                    <img
                      alt="Star33452"
                      src="/external/star33452-ucvr.svg"
                      className="profil-uytkownika-opiniebutton-star31"
                    />
                    <img
                      alt="Star43452"
                      src="/external/star43452-l17r.svg"
                      className="profil-uytkownika-opiniebutton-star41"
                    />
                    <img
                      alt="Star53452"
                      src="/external/star53452-ytno.svg"
                      className="profil-uytkownika-opiniebutton-star51"
                    />
                  </div>
                  <span className="profil-uytkownika-opiniebutton-text38">
                    <span>3/5</span>
                  </span>
                </div>
                <div className="profil-uytkownika-opiniebutton-frame171">
                  <div className="profil-uytkownika-opiniebutton-frame168">
                    <div className="profil-uytkownika-opiniebutton-frame1671">
                      <img
                        alt="awardiconmedalpng13452"
                        src="/external/awardiconmedalpng13452-exhm-200w.png"
                        className="profil-uytkownika-opiniebutton-awardiconmedalpng11"
                      />
                      <span className="profil-uytkownika-opiniebutton-text40">
                        <span>Zweryfikowany Artysta</span>
                      </span>
                    </div>
                  </div>
                  <div className="profil-uytkownika-opiniebutton-frame169">
                    <div className="profil-uytkownika-opiniebutton-frame1672">
                      <img
                        alt="awardiconmedalpng13452"
                        src="/external/awardiconmedalpng13452-3uo9-200w.png"
                        className="profil-uytkownika-opiniebutton-awardiconmedalpng12"
                      />
                      <span className="profil-uytkownika-opiniebutton-text42">
                        <span>Artysta z community</span>
                      </span>
                    </div>
                  </div>
                  <div className="profil-uytkownika-opiniebutton-frame170">
                    <div className="profil-uytkownika-opiniebutton-frame1673">
                      <img
                        alt="awardiconmedalpng13452"
                        src="/external/awardiconmedalpng13452-cpdl-200w.png"
                        className="profil-uytkownika-opiniebutton-awardiconmedalpng13"
                      />
                      <span className="profil-uytkownika-opiniebutton-text44">
                        <span>Wysoki poziom zaufania</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="profil-uytkownika-opiniebutton-frame172">
                  <img
                    alt="icon3452"
                    src="/external/icon3452-11u7.svg"
                    className="profil-uytkownika-opiniebutton-icon1"
                  />
                  <span className="profil-uytkownika-opiniebutton-text46">
                    <span>Wykonane zlecenia: 24</span>
                  </span>
                </div>
              </div>
            </div>
            <img
              alt="Rectangle1163452"
              src="/external/rectangle1163452-qik-200h.png"
              className="profil-uytkownika-opiniebutton-rectangle116"
            />
            <div className="profil-uytkownika-opiniebutton-frame174">
              <img
                alt="mail3452"
                src="/external/mail3452-8l39.svg"
                className="profil-uytkownika-opiniebutton-mail"
              />
              <span className="profil-uytkownika-opiniebutton-text48">
                <span>Opinie</span>
              </span>
            </div>
            <div className="profil-uytkownika-opiniebutton-frame173">
              <img
                alt="icon3452"
                src="/external/icon3452-lu7.svg"
                className="profil-uytkownika-opiniebutton-icon2"
              />
              <Link
                to="/profil-uytkownika-ommiebutton"
                className="profil-uytkownika-opiniebutton-text50"
              >
                <span>O mnie</span>
              </Link>
            </div>
            <div className="profil-uytkownika-opiniebutton-frame175">
              <img
                alt="photo3452"
                src="/external/photo3452-6lm.svg"
                className="profil-uytkownika-opiniebutton-photo"
              />
              <Link
                to="/profil-uytkownika-portfoliobutton"
                className="profil-uytkownika-opiniebutton-text52"
              >
                <span>Portfolio</span>
              </Link>
            </div>
            <div className="profil-uytkownika-opiniebutton-frame176">
              <img
                alt="folderfilled3452"
                src="/external/folderfilled3452-9duf.svg"
                className="profil-uytkownika-opiniebutton-folderfilled"
              />
              <Link
                to="/profil-uytkownika-wykonanezleceniabutton"
                className="profil-uytkownika-opiniebutton-text54"
              >
                <span>
                  Wykonane
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>zlecenia</span>
              </Link>
            </div>
          </div>
          <div className="profil-uytkownika-opiniebutton-group166">
            <span className="profil-uytkownika-opiniebutton-text58">
              <span>Opinie</span>
            </span>
            <div className="profil-uytkownika-opiniebutton-frame200">
              <div className="profil-uytkownika-opiniebutton-frame1991">
                <div className="profil-uytkownika-opiniebutton-frame1981">
                  <div className="profil-uytkownika-opiniebutton-frame3d-avatars221">
                    <img
                      alt="Avatars3davatar22I345"
                      src="/external/avatars3davatar22i345-sgy-200w.png"
                      className="profil-uytkownika-opiniebutton-avatars3davatar221"
                    />
                  </div>
                  <div className="profil-uytkownika-opiniebutton-frame1971">
                    <span className="profil-uytkownika-opiniebutton-text60">
                      <span>Jan Kowalski</span>
                    </span>
                    <div className="profil-uytkownika-opiniebutton-frame1961">
                      <div className="profil-uytkownika-opiniebutton-frame1652">
                        <img
                          alt="Star13452"
                          src="/external/star13452-zfvg.svg"
                          className="profil-uytkownika-opiniebutton-star12"
                        />
                        <img
                          alt="Star23452"
                          src="/external/star23452-sl36.svg"
                          className="profil-uytkownika-opiniebutton-star22"
                        />
                        <img
                          alt="Star33452"
                          src="/external/star33452-5gq.svg"
                          className="profil-uytkownika-opiniebutton-star32"
                        />
                        <img
                          alt="Star43452"
                          src="/external/star43452-0n5.svg"
                          className="profil-uytkownika-opiniebutton-star42"
                        />
                        <img
                          alt="Star53452"
                          src="/external/star53452-azp4.svg"
                          className="profil-uytkownika-opiniebutton-star52"
                        />
                      </div>
                      <span className="profil-uytkownika-opiniebutton-text62">
                        <span>3/5</span>
                      </span>
                    </div>
                  </div>
                </div>
                <span className="profil-uytkownika-opiniebutton-text64">
                  <span>
                    Jestem zachwycony twórczością tego artysty! Jego unikalne
                    podejście do muzyki i tekstów naprawdę wyróżnia go na tle
                    innych. Każda piosenka to osobna historia. Polecam każdemu!”
                  </span>
                </span>
              </div>
            </div>
            <div className="profil-uytkownika-opiniebutton-frame201">
              <div className="profil-uytkownika-opiniebutton-frame1992">
                <div className="profil-uytkownika-opiniebutton-frame1982">
                  <div className="profil-uytkownika-opiniebutton-frame3d-avatars222">
                    <img
                      alt="Avatars3davatar22I345"
                      src="/external/avatars3davatar22i345-i1ba-200w.png"
                      className="profil-uytkownika-opiniebutton-avatars3davatar222"
                    />
                  </div>
                  <div className="profil-uytkownika-opiniebutton-frame1972">
                    <span className="profil-uytkownika-opiniebutton-text66">
                      <span>MuzykaSerca:</span>
                    </span>
                    <div className="profil-uytkownika-opiniebutton-frame1962">
                      <div className="profil-uytkownika-opiniebutton-frame1653">
                        <img
                          alt="Star13452"
                          src="/external/star13452-vpex.svg"
                          className="profil-uytkownika-opiniebutton-star13"
                        />
                        <img
                          alt="Star23452"
                          src="/external/star23452-mjd8.svg"
                          className="profil-uytkownika-opiniebutton-star23"
                        />
                        <img
                          alt="Star33452"
                          src="/external/star33452-skcl.svg"
                          className="profil-uytkownika-opiniebutton-star33"
                        />
                        <img
                          alt="Star43452"
                          src="/external/star43452-ae2.svg"
                          className="profil-uytkownika-opiniebutton-star43"
                        />
                        <img
                          alt="Star53452"
                          src="/external/star53452-leve.svg"
                          className="profil-uytkownika-opiniebutton-star53"
                        />
                      </div>
                      <span className="profil-uytkownika-opiniebutton-text68">
                        <span>3/5</span>
                      </span>
                    </div>
                  </div>
                </div>
                <span className="profil-uytkownika-opiniebutton-text70">
                  <span>
                    Wow, to jest po prostu niesamowite! Od pierwszego utworu do
                    ostatniego, wszystko jest perfekcyjne. Artysta naprawdę ma
                    talent i serce do tworzenia muzyki.”
                  </span>
                </span>
              </div>
            </div>
            <div className="profil-uytkownika-opiniebutton-frame202">
              <div className="profil-uytkownika-opiniebutton-frame1993">
                <div className="profil-uytkownika-opiniebutton-frame1983">
                  <div className="profil-uytkownika-opiniebutton-frame3d-avatars223">
                    <img
                      alt="Avatars3davatar22I345"
                      src="/external/avatars3davatar22i345-pzxu-200w.png"
                      className="profil-uytkownika-opiniebutton-avatars3davatar223"
                    />
                  </div>
                  <div className="profil-uytkownika-opiniebutton-frame1973">
                    <span className="profil-uytkownika-opiniebutton-text72">
                      <span>ArtystaRoku2024:</span>
                    </span>
                    <div className="profil-uytkownika-opiniebutton-frame1963">
                      <div className="profil-uytkownika-opiniebutton-frame1654">
                        <img
                          alt="Star13452"
                          src="/external/star13452-2zt.svg"
                          className="profil-uytkownika-opiniebutton-star14"
                        />
                        <img
                          alt="Star23452"
                          src="/external/star23452-q02.svg"
                          className="profil-uytkownika-opiniebutton-star24"
                        />
                        <img
                          alt="Star33452"
                          src="/external/star33452-pxzf.svg"
                          className="profil-uytkownika-opiniebutton-star34"
                        />
                        <img
                          alt="Star43452"
                          src="/external/star43452-grhp.svg"
                          className="profil-uytkownika-opiniebutton-star44"
                        />
                        <img
                          alt="Star53452"
                          src="/external/star53452-at8h.svg"
                          className="profil-uytkownika-opiniebutton-star54"
                        />
                      </div>
                      <span className="profil-uytkownika-opiniebutton-text74">
                        <span>3/5</span>
                      </span>
                    </div>
                  </div>
                </div>
                <span className="profil-uytkownika-opiniebutton-text76">
                  <span>
                    Nie spodziewałem się, że jego najnowszy album będzie aż tak
                    świetny. Jestem naprawdę pod wrażeniem i czekam na więcej.
                    To jest coś, czego mi brakowało!”
                  </span>
                </span>
              </div>
            </div>
            <div className="profil-uytkownika-opiniebutton-frame203">
              <div className="profil-uytkownika-opiniebutton-frame1994">
                <div className="profil-uytkownika-opiniebutton-frame1984">
                  <div className="profil-uytkownika-opiniebutton-frame3d-avatars224">
                    <img
                      alt="Avatars3davatar22I345"
                      src="/external/avatars3davatar22i345-bujn-200w.png"
                      className="profil-uytkownika-opiniebutton-avatars3davatar224"
                    />
                  </div>
                  <div className="profil-uytkownika-opiniebutton-frame1974">
                    <span className="profil-uytkownika-opiniebutton-text78">
                      <span>StylowyKrytyk</span>
                    </span>
                    <div className="profil-uytkownika-opiniebutton-frame1964">
                      <div className="profil-uytkownika-opiniebutton-frame1655">
                        <img
                          alt="Star13452"
                          src="/external/star13452-5rmj.svg"
                          className="profil-uytkownika-opiniebutton-star15"
                        />
                        <img
                          alt="Star23452"
                          src="/external/star23452-2ous.svg"
                          className="profil-uytkownika-opiniebutton-star25"
                        />
                        <img
                          alt="Star33452"
                          src="/external/star33452-wc1.svg"
                          className="profil-uytkownika-opiniebutton-star35"
                        />
                        <img
                          alt="Star43452"
                          src="/external/star43452-yx2v.svg"
                          className="profil-uytkownika-opiniebutton-star45"
                        />
                        <img
                          alt="Star53452"
                          src="/external/star53452-b3l8.svg"
                          className="profil-uytkownika-opiniebutton-star55"
                        />
                      </div>
                      <span className="profil-uytkownika-opiniebutton-text80">
                        <span>3/5</span>
                      </span>
                    </div>
                  </div>
                </div>
                <span className="profil-uytkownika-opiniebutton-text82">
                  <span>
                    {' '}
                    „Artysta ten to prawdziwy geniusz! Jego prace są pełne
                    emocji i autentyczności. Jako ktoś, kto zna się na muzyce,
                    mogę powiedzieć, że jest na czołowej pozycji w branży.”
                  </span>
                </span>
              </div>
            </div>
            <div className="profil-uytkownika-opiniebutton-frame204">
              <div className="profil-uytkownika-opiniebutton-frame1995">
                <div className="profil-uytkownika-opiniebutton-frame1985">
                  <div className="profil-uytkownika-opiniebutton-frame3d-avatars225">
                    <img
                      alt="Avatars3davatar22I345"
                      src="/external/avatars3davatar22i345-oogo-200w.png"
                      className="profil-uytkownika-opiniebutton-avatars3davatar225"
                    />
                  </div>
                  <div className="profil-uytkownika-opiniebutton-frame1975">
                    <span className="profil-uytkownika-opiniebutton-text84">
                      <span>Melomania:</span>
                    </span>
                    <div className="profil-uytkownika-opiniebutton-frame1965">
                      <div className="profil-uytkownika-opiniebutton-frame1656">
                        <img
                          alt="Star13452"
                          src="/external/star13452-ti2m.svg"
                          className="profil-uytkownika-opiniebutton-star16"
                        />
                        <img
                          alt="Star23452"
                          src="/external/star23452-u2m8.svg"
                          className="profil-uytkownika-opiniebutton-star26"
                        />
                        <img
                          alt="Star33452"
                          src="/external/star33452-dtp9.svg"
                          className="profil-uytkownika-opiniebutton-star36"
                        />
                        <img
                          alt="Star43452"
                          src="/external/star43452-i29x.svg"
                          className="profil-uytkownika-opiniebutton-star46"
                        />
                        <img
                          alt="Star53452"
                          src="/external/star53452-ml4n.svg"
                          className="profil-uytkownika-opiniebutton-star56"
                        />
                      </div>
                      <span className="profil-uytkownika-opiniebutton-text86">
                        <span>3/5</span>
                      </span>
                    </div>
                  </div>
                </div>
                <span className="profil-uytkownika-opiniebutton-text88">
                  <span>
                    Jego ostatni album to prawdziwe arcydzieło! Każdy utwór jest
                    przemyślany i perfekcyjnie wykonany. To jest muzyka, którą
                    chce się słuchać w kółko.”
                  </span>
                </span>
              </div>
            </div>
            <div className="profil-uytkownika-opiniebutton-pagination">
              <div className="profil-uytkownika-opiniebutton-pagination-previous">
                <img
                  alt="ArrowleftI345"
                  src="/external/arrowlefti345-3xon.svg"
                  className="profil-uytkownika-opiniebutton-arrowleft"
                />
                <span className="profil-uytkownika-opiniebutton-text90 SingleLineBodyBase">
                  <span>Previous</span>
                </span>
              </div>
              <div className="profil-uytkownika-opiniebutton-pagination-list">
                <div className="profil-uytkownika-opiniebutton-pagination-page1">
                  <span className="profil-uytkownika-opiniebutton-text92 SingleLineBodyBase">
                    1
                  </span>
                </div>
                <div className="profil-uytkownika-opiniebutton-pagination-page2">
                  <span className="profil-uytkownika-opiniebutton-text93 SingleLineBodyBase">
                    2
                  </span>
                </div>
                <div className="profil-uytkownika-opiniebutton-pagination-page3">
                  <span className="profil-uytkownika-opiniebutton-text94 SingleLineBodyBase">
                    3
                  </span>
                </div>
                <div className="profil-uytkownika-opiniebutton-pagination-gap">
                  <span className="profil-uytkownika-opiniebutton-text95 BodyBaseBold">
                    <span>...</span>
                  </span>
                </div>
                <div className="profil-uytkownika-opiniebutton-pagination-page4">
                  <span className="profil-uytkownika-opiniebutton-text97 SingleLineBodyBase">
                    <span>67</span>
                  </span>
                </div>
                <div className="profil-uytkownika-opiniebutton-pagination-page5">
                  <span className="profil-uytkownika-opiniebutton-text99 SingleLineBodyBase">
                    <span>68</span>
                  </span>
                </div>
              </div>
              <div className="profil-uytkownika-opiniebutton-pagination-next">
                <span className="profil-uytkownika-opiniebutton-text101 SingleLineBodyBase">
                  <span>Next</span>
                </span>
                <img
                  alt="ArrowrightI345"
                  src="/external/arrowrighti345-q0bi.svg"
                  className="profil-uytkownika-opiniebutton-arrowright"
                />
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default ProfilUytkownikaOpiniebutton
