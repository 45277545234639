import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './profil-uytkownika-ommiebutton.css'

const ProfilUytkownikaOmmiebutton = (props) => {
  return (
    <div className="profil-uytkownika-ommiebutton-container1">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="profil-uytkownika-ommiebutton-dummy-container">
        <button className="profil-uytkownika-ommiebutton-profil-uytkownika-ommiebutton">
          <div className="profil-uytkownika-ommiebutton-group115">
            <div className="profil-uytkownika-ommiebutton-frame1111">
              <Link to="/" className="profil-uytkownika-ommiebutton-navlink1">
                <img
                  alt="LGOOROBOCZEV00113451"
                  src="/external/lgooroboczev00113451-w66-200h.png"
                  className="profil-uytkownika-ommiebutton-lgooroboczev0011"
                />
              </Link>
              <div className="profil-uytkownika-ommiebutton-group114">
                <div className="profil-uytkownika-ommiebutton-input">
                  <div className="profil-uytkownika-ommiebutton-container2">
                    <span className="profil-uytkownika-ommiebutton-text10">
                      <span>Czego szukasz towarzyszu?</span>
                    </span>
                  </div>
                </div>
                <button className="profil-uytkownika-ommiebutton-button1">
                  <img
                    alt="SVG3451"
                    src="/external/svg3451-n8p8.svg"
                    className="profil-uytkownika-ommiebutton-svg1"
                  />
                </button>
              </div>
              <button className="profil-uytkownika-ommiebutton-item-button">
                <div className="profil-uytkownika-ommiebutton-group112">
                  <Link
                    to="/communityartystycznebutton"
                    className="profil-uytkownika-ommiebutton-text12"
                  >
                    <span>Społeczność Artystyczna</span>
                  </Link>
                  <img
                    alt="Vector3451"
                    src="/external/vector3451-lg.svg"
                    className="profil-uytkownika-ommiebutton-vector1"
                  />
                </div>
              </button>
              <div className="profil-uytkownika-ommiebutton-group113">
                <Link
                  to="/communityartystycznebutton"
                  className="profil-uytkownika-ommiebutton-text14"
                >
                  <span>Q&amp;A</span>
                </Link>
                <img
                  alt="Vector3451"
                  src="/external/vector3451-gfh.svg"
                  className="profil-uytkownika-ommiebutton-vector2"
                />
              </div>
              <div className="profil-uytkownika-ommiebutton-item-link1">
                <Link
                  to="/logowani-ebutton"
                  className="profil-uytkownika-ommiebutton-text16"
                >
                  <span>Sign in</span>
                </Link>
              </div>
              <Link
                to="/logowani-ebutton"
                className="profil-uytkownika-ommiebutton-navlink2"
              >
                <div className="profil-uytkownika-ommiebutton-item-link2">
                  <span className="profil-uytkownika-ommiebutton-text18">
                    <span>Join</span>
                  </span>
                </div>
              </Link>
            </div>
          </div>
          <img
            alt="Rectangle1013451"
            src="/external/rectangle1013451-fvm7-200h.png"
            className="profil-uytkownika-ommiebutton-rectangle101"
          />
          <Link
            to="/podstrona-grafikbutton"
            className="profil-uytkownika-ommiebutton-navlink3"
          >
            <div className="profil-uytkownika-ommiebutton-frame1112">
              <span className="profil-uytkownika-ommiebutton-text20">
                <span>Cosplay</span>
              </span>
              <span className="profil-uytkownika-ommiebutton-text22">
                <span>Pisarz</span>
              </span>
              <span className="profil-uytkownika-ommiebutton-text24">
                <span>Muzyka&amp;audio</span>
              </span>
              <span className="profil-uytkownika-ommiebutton-text26">
                <span>Rysownik</span>
              </span>
              <span className="profil-uytkownika-ommiebutton-text28">
                <span>UI/Ux Design</span>
              </span>
              <span className="profil-uytkownika-ommiebutton-text30">
                <span>Grafika Komputerowa</span>
              </span>
            </div>
          </Link>
          <img
            alt="Rectangle1033451"
            src="/external/rectangle1033451-6ta-200h.png"
            className="profil-uytkownika-ommiebutton-rectangle103"
          />
          <div className="profil-uytkownika-ommiebutton-frame188">
            <span className="profil-uytkownika-ommiebutton-text32">
              <span>Profil Artysty</span>
            </span>
            <div className="profil-uytkownika-ommiebutton-frame182">
              <img
                alt="Frame1323451"
                src="/external/frame1323451-d7q7.svg"
                className="profil-uytkownika-ommiebutton-frame132"
              />
              <Link
                to="/okn-ooddajzleceniebutton"
                className="profil-uytkownika-ommiebutton-button2"
              >
                <img
                  alt="SendI345"
                  src="/external/sendi345-il.svg"
                  className="profil-uytkownika-ommiebutton-send"
                />
                <span className="profil-uytkownika-ommiebutton-text34 SingleLineBodyBase">
                  <span>Wyślij Zlecenie</span>
                </span>
              </Link>
            </div>
          </div>
          <div className="profil-uytkownika-ommiebutton-group162">
            <div className="profil-uytkownika-ommiebutton-frame181">
              <div className="profil-uytkownika-ommiebutton-frame178">
                <div className="profil-uytkownika-ommiebutton-group157">
                  <img
                    alt="image213452"
                    src="/external/image213452-9t3d-200h.png"
                    className="profil-uytkownika-ommiebutton-image211"
                  />
                  <img
                    alt="Ellipse253452"
                    src="/external/ellipse253452-ndz9-200h.png"
                    className="profil-uytkownika-ommiebutton-ellipse25"
                  />
                </div>
                <span className="profil-uytkownika-ommiebutton-text36">
                  <span>Magdalena Maria  Monika</span>
                </span>
                <div className="profil-uytkownika-ommiebutton-frame166">
                  <div className="profil-uytkownika-ommiebutton-frame165">
                    <img
                      alt="Star13452"
                      src="/external/star13452-ensk.svg"
                      className="profil-uytkownika-ommiebutton-star1"
                    />
                    <img
                      alt="Star23452"
                      src="/external/star23452-mgf6.svg"
                      className="profil-uytkownika-ommiebutton-star2"
                    />
                    <img
                      alt="Star33452"
                      src="/external/star33452-xvio.svg"
                      className="profil-uytkownika-ommiebutton-star3"
                    />
                    <img
                      alt="Star43452"
                      src="/external/star43452-t1ke.svg"
                      className="profil-uytkownika-ommiebutton-star4"
                    />
                    <img
                      alt="Star53452"
                      src="/external/star53452-sn8u.svg"
                      className="profil-uytkownika-ommiebutton-star5"
                    />
                  </div>
                  <span className="profil-uytkownika-ommiebutton-text38">
                    <span>3/5</span>
                  </span>
                </div>
                <div className="profil-uytkownika-ommiebutton-frame171">
                  <div className="profil-uytkownika-ommiebutton-frame168">
                    <div className="profil-uytkownika-ommiebutton-frame1671">
                      <img
                        alt="awardiconmedalpng13452"
                        src="/external/awardiconmedalpng13452-e0z-200w.png"
                        className="profil-uytkownika-ommiebutton-awardiconmedalpng11"
                      />
                      <span className="profil-uytkownika-ommiebutton-text40">
                        <span>Zweryfikowany Artysta</span>
                      </span>
                    </div>
                  </div>
                  <div className="profil-uytkownika-ommiebutton-frame169">
                    <div className="profil-uytkownika-ommiebutton-frame1672">
                      <img
                        alt="awardiconmedalpng13452"
                        src="/external/awardiconmedalpng13452-0pu8-200w.png"
                        className="profil-uytkownika-ommiebutton-awardiconmedalpng12"
                      />
                      <span className="profil-uytkownika-ommiebutton-text42">
                        <span>Artysta z community</span>
                      </span>
                    </div>
                  </div>
                  <div className="profil-uytkownika-ommiebutton-frame170">
                    <div className="profil-uytkownika-ommiebutton-frame1673">
                      <img
                        alt="awardiconmedalpng13452"
                        src="/external/awardiconmedalpng13452-p4y8-200w.png"
                        className="profil-uytkownika-ommiebutton-awardiconmedalpng13"
                      />
                      <span className="profil-uytkownika-ommiebutton-text44">
                        <span>Wysoki poziom zaufania</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="profil-uytkownika-ommiebutton-frame172">
                  <img
                    alt="icon3452"
                    src="/external/icon3452-a71u.svg"
                    className="profil-uytkownika-ommiebutton-icon1"
                  />
                  <span className="profil-uytkownika-ommiebutton-text46">
                    <span>Wykonane zlecenia: 24</span>
                  </span>
                </div>
              </div>
            </div>
            <img
              alt="Rectangle1163452"
              src="/external/rectangle1163452-5x2a-200h.png"
              className="profil-uytkownika-ommiebutton-rectangle116"
            />
            <div className="profil-uytkownika-ommiebutton-frame174">
              <img
                alt="mail3452"
                src="/external/mail3452-k8y5.svg"
                className="profil-uytkownika-ommiebutton-mail"
              />
              <Link
                to="/profil-uytkownika-opiniebutton"
                className="profil-uytkownika-ommiebutton-text48"
              >
                <span>Opinie</span>
              </Link>
            </div>
            <div className="profil-uytkownika-ommiebutton-frame173">
              <img
                alt="icon3452"
                src="/external/icon3452-nlhp.svg"
                className="profil-uytkownika-ommiebutton-icon2"
              />
              <span className="profil-uytkownika-ommiebutton-text50">
                <span>O mnie</span>
              </span>
            </div>
            <div className="profil-uytkownika-ommiebutton-frame175">
              <img
                alt="photo3452"
                src="/external/photo3452-vmfg.svg"
                className="profil-uytkownika-ommiebutton-photo"
              />
              <Link
                to="/profil-uytkownika-portfoliobutton"
                className="profil-uytkownika-ommiebutton-text52"
              >
                <span>Portfolio</span>
              </Link>
            </div>
            <div className="profil-uytkownika-ommiebutton-frame176">
              <img
                alt="folderfilled3452"
                src="/external/folderfilled3452-2m9.svg"
                className="profil-uytkownika-ommiebutton-folderfilled"
              />
              <Link
                to="/profil-uytkownika-wykonanezleceniabutton"
                className="profil-uytkownika-ommiebutton-text54"
              >
                <span>
                  Wykonane
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>zlecenia</span>
              </Link>
            </div>
          </div>
          <div className="profil-uytkownika-ommiebutton-group167">
            <span className="profil-uytkownika-ommiebutton-text58">
              <span>O mnie</span>
            </span>
            <div className="profil-uytkownika-ommiebutton-frame205">
              <img
                alt="image213452"
                src="/external/image213452-upi5-400w.png"
                className="profil-uytkownika-ommiebutton-image212"
              />
              <span className="profil-uytkownika-ommiebutton-text60">
                <span>
                  Witaj w moim artystycznym świecie! Nazywam się [Imię Nazwisko]
                  i jestem [rodzaj artysty, np. malarzem, fotografem, muzykiem]
                  z [miejsce pochodzenia/zamieszkania].
                </span>
                <br></br>
                <span>
                  Moja przygoda ze sztuką rozpoczęła się [krótka historia o
                  początku kariery artystycznej]. Od tego czasu, moja pasja do
                  [rodzaj sztuki] nieustannie rośnie, inspirując mnie do
                  tworzenia [opis twórczości].
                </span>
                <br></br>
                <span>
                  W mojej twórczości staram się [cel artystyczny, np.
                  &quot;uchwycić ulotne momenty codzienności&quot;,
                  &quot;wyrazić emocje poprzez abstrakcyjne formy&quot;].
                  Inspirację czerpię z [źródła inspiracji], co znajduje
                  odzwierciedlenie w moich pracach poprzez [charakterystyczne
                  elementy twórczości].
                </span>
                <br></br>
                <span>
                  Poza sztuką, jestem [inne zainteresowania lub aktywności]. Te
                  doświadczenia często przenikają do mojej twórczości,
                  wzbogacając ją o nowe perspektywy.
                </span>
                <br></br>
                <span>
                  Zapraszam Cię do eksploracji mojej galerii i mam nadzieję, że
                  moja sztuka poruszy Cię tak samo, jak porusza mnie proces jej
                  tworzenia.
                </span>
              </span>
            </div>
            <div className="profil-uytkownika-ommiebutton-frame129">
              <img
                alt="SVG3452"
                src="/external/svg3452-l49e.svg"
                className="profil-uytkownika-ommiebutton-svg2"
              />
              <img
                alt="SVG3452"
                src="/external/svg3452-83v.svg"
                className="profil-uytkownika-ommiebutton-svg3"
              />
              <img
                alt="SVG3452"
                src="/external/svg3452-io9f.svg"
                className="profil-uytkownika-ommiebutton-svg4"
              />
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default ProfilUytkownikaOmmiebutton
