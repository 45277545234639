import React from 'react'

import { Helmet } from 'react-helmet'

import './u-stawieniakont-abutton.css'

const USTAWIENIAKONTAbutton = (props) => {
  return (
    <div className="ustawieniakont-abutton-container1">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="ustawieniakont-abutton-dummy-container">
        <button className="ustawieniakont-abutton-ustawieniakont-abutton">
          <div className="ustawieniakont-abutton-group155">
            <img
              alt="Rectangle933201"
              src="/external/rectangle933201-wijt-600w.png"
              className="ustawieniakont-abutton-rectangle93"
            />
            <img
              alt="Rectangle943201"
              src="/external/rectangle943201-gaw-600w.png"
              className="ustawieniakont-abutton-rectangle94"
            />
          </div>
          <img
            alt="Rectangle712323"
            src="/external/rectangle712323-4tg-1000h.png"
            className="ustawieniakont-abutton-rectangle71"
          />
          <img
            alt="Rectangle702323"
            src="/external/rectangle702323-bxxm-200h.png"
            className="ustawieniakont-abutton-rectangle70"
          />
          <img
            alt="LGOOROBOCZEV00112323"
            src="/external/lgooroboczev00112323-hup-200h.png"
            className="ustawieniakont-abutton-lgooroboczev0011"
          />
          <div className="ustawieniakont-abutton-frame19">
            <span className="ustawieniakont-abutton-text10">
              <span>Konto</span>
            </span>
            <span className="ustawieniakont-abutton-text12">
              <span>Wiadomości</span>
            </span>
            <span className="ustawieniakont-abutton-text14">
              <span>Zamówienia</span>
            </span>
            <span className="ustawieniakont-abutton-text16">
              <span>Powiadomienia</span>
            </span>
            <span className="ustawieniakont-abutton-text18">
              <span>zlecenia</span>
            </span>
          </div>
          <img
            alt="image122323"
            src="/external/image122323-rdg-200h.png"
            className="ustawieniakont-abutton-image12"
          />
          <span className="ustawieniakont-abutton-text20">
            <span>Pan Twarug</span>
          </span>
          <img
            alt="Rectangle122323"
            src="/external/rectangle122323-lmi3-200h.png"
            className="ustawieniakont-abutton-rectangle12"
          />
          <img
            alt="Rectangle232323"
            src="/external/rectangle232323-ripa-200h.png"
            className="ustawieniakont-abutton-rectangle23"
          />
          <img
            alt="image132323"
            src="/external/image132323-13om-200h.png"
            className="ustawieniakont-abutton-image13"
          />
          <div className="ustawieniakont-abutton-frame9">
            <span className="ustawieniakont-abutton-text22">
              <span>Q&amp;A</span>
            </span>
          </div>
          <img
            alt="Ellipse232323"
            src="/external/ellipse232323-dec8-200h.png"
            className="ustawieniakont-abutton-ellipse23"
          />
          <span className="ustawieniakont-abutton-text24">
            <span>Konto</span>
          </span>
          <div className="ustawieniakont-abutton-frame93">
            <div className="ustawieniakont-abutton-group110">
              <div className="ustawieniakont-abutton-group1051">
                <div className="ustawieniakont-abutton-frame871">
                  <span className="ustawieniakont-abutton-text26">
                    <span>Informacje osobiste</span>
                  </span>
                  <div className="ustawieniakont-abutton-frame861"></div>
                </div>
                <div className="ustawieniakont-abutton-frame921">
                  <div className="ustawieniakont-abutton-frame901">
                    <img
                      alt="person2326"
                      src="/external/person2326-1m4o.svg"
                      className="ustawieniakont-abutton-person1"
                    />
                    <div className="ustawieniakont-abutton-frame891">
                      <span className="ustawieniakont-abutton-text28">
                        <span>Wyświetlana nazwa</span>
                      </span>
                      <span className="ustawieniakont-abutton-text30">
                        <span>Pan Twaróg</span>
                      </span>
                    </div>
                  </div>
                  <div className="ustawieniakont-abutton-frame911">
                    <img
                      alt="person2326"
                      src="/external/person2326-g8rq.svg"
                      className="ustawieniakont-abutton-person2"
                    />
                    <div className="ustawieniakont-abutton-frame892">
                      <span className="ustawieniakont-abutton-text32">
                        <span>Imię i nazwisko</span>
                      </span>
                      <span className="ustawieniakont-abutton-text34">
                        <span>Twoja mama</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ustawieniakont-abutton-group1091">
              <div className="ustawieniakont-abutton-group1061">
                <div className="ustawieniakont-abutton-frame872">
                  <span className="ustawieniakont-abutton-text36">
                    <span>Informacje Kontaktowe</span>
                  </span>
                  <div className="ustawieniakont-abutton-frame862"></div>
                </div>
                <div className="ustawieniakont-abutton-frame922">
                  <div className="ustawieniakont-abutton-frame902">
                    <img
                      alt="Phone2326"
                      src="/external/phone2326-35h9.svg"
                      className="ustawieniakont-abutton-phone"
                    />
                    <div className="ustawieniakont-abutton-frame893">
                      <span className="ustawieniakont-abutton-text38">
                        <span>Telefon</span>
                      </span>
                      <span className="ustawieniakont-abutton-text40">
                        <span>+48 500 500 500</span>
                      </span>
                    </div>
                  </div>
                  <div className="ustawieniakont-abutton-frame912">
                    <img
                      alt="Mail2326"
                      src="/external/mail2326-x4ca.svg"
                      className="ustawieniakont-abutton-mail"
                    />
                    <div className="ustawieniakont-abutton-frame894">
                      <span className="ustawieniakont-abutton-text42">
                        <span>Email</span>
                      </span>
                      <span className="ustawieniakont-abutton-text44">
                        <span>Email@XD.com</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ustawieniakont-abutton-group1081">
              <div className="ustawieniakont-abutton-group1071">
                <div className="ustawieniakont-abutton-frame873">
                  <span className="ustawieniakont-abutton-text46">
                    <span>Informacje Kontaktowe</span>
                  </span>
                  <div className="ustawieniakont-abutton-frame863"></div>
                </div>
              </div>
              <div className="ustawieniakont-abutton-frame32">
                <span className="ustawieniakont-abutton-text48">
                  <span>Kod pocztowy</span>
                </span>
                <img
                  alt="Rectangle72327"
                  src="/external/rectangle72327-xglb-200h.png"
                  className="ustawieniakont-abutton-rectangle72"
                />
              </div>
              <div className="ustawieniakont-abutton-frame33">
                <span className="ustawieniakont-abutton-text50">
                  <span>Ulica i numer lokalu</span>
                </span>
                <img
                  alt="Rectangle72327"
                  src="/external/rectangle72327-bvlj-200h.png"
                  className="ustawieniakont-abutton-rectangle73"
                />
              </div>
              <div className="ustawieniakont-abutton-frame31">
                <span className="ustawieniakont-abutton-text52">
                  <span>Miasto</span>
                </span>
                <img
                  alt="Rectangle22327"
                  src="/external/rectangle22327-ysao-200h.png"
                  className="ustawieniakont-abutton-rectangle21"
                />
              </div>
              <div className="ustawieniakont-abutton-frame34">
                <span className="ustawieniakont-abutton-text54">
                  <span>Numer Konta</span>
                </span>
                <img
                  alt="Rectangle22327"
                  src="/external/rectangle22327-qv9h-200h.png"
                  className="ustawieniakont-abutton-rectangle22"
                />
              </div>
            </div>
          </div>
          <div className="ustawieniakont-abutton-group1092">
            <div className="ustawieniakont-abutton-group1052">
              <button className="ustawieniakont-abutton-iconbutton1">
                <div className="ustawieniakont-abutton-container2">
                  <div className="ustawieniakont-abutton-statelayer1">
                    <img
                      alt="IconI232"
                      src="/external/iconi232-phtr.svg"
                      className="ustawieniakont-abutton-icon1"
                    />
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div className="ustawieniakont-abutton-group1082">
            <div className="ustawieniakont-abutton-group1072">
              <button className="ustawieniakont-abutton-iconbutton2">
                <div className="ustawieniakont-abutton-container3">
                  <div className="ustawieniakont-abutton-statelayer2">
                    <img
                      alt="IconI232"
                      src="/external/iconi232-ho7c.svg"
                      className="ustawieniakont-abutton-icon2"
                    />
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div className="ustawieniakont-abutton-frame110">
            <div className="ustawieniakont-abutton-group1062">
              <button className="ustawieniakont-abutton-iconbutton3">
                <div className="ustawieniakont-abutton-container4">
                  <div className="ustawieniakont-abutton-statelayer3">
                    <img
                      alt="IconI232"
                      src="/external/iconi232-srepv.svg"
                      className="ustawieniakont-abutton-icon3"
                    />
                  </div>
                </div>
              </button>
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default USTAWIENIAKONTAbutton
