import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './wiadomosci.css'

const Wiadomosci = (props) => {
  return (
    <div className="wiadomosci-container">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="wiadomosci-dummy-container">
        <button className="wiadomosci-widokczatu2button">
          <div className="wiadomosci-group155">
            <img
              alt="Rectangle933201"
              src="/external/rectangle933201-iv7q-600w.png"
              className="wiadomosci-rectangle93"
            />
            <img
              alt="Rectangle943201"
              src="/external/rectangle943201-ur97-600w.png"
              className="wiadomosci-rectangle94"
            />
          </div>
          <img
            alt="Rectangle711012"
            src="/external/rectangle711012-slos-1000h.png"
            className="wiadomosci-rectangle71"
          />
          <img
            alt="Rectangle701012"
            src="/external/rectangle701012-1ei-200h.png"
            className="wiadomosci-rectangle70"
          />
          <img
            alt="LGOOROBOCZEV00111012"
            src="/external/lgooroboczev00111012-52py-200h.png"
            className="wiadomosci-lgooroboczev0011"
          />
          <div className="wiadomosci-frame19">
            <Link to="/u-stawieniakont-abutton" className="wiadomosci-text10">
              <span>Konto</span>
            </Link>
            <span className="wiadomosci-text12">
              <span>Wiadomości</span>
            </span>
            <span className="wiadomosci-text14">
              <span>Zamówienia</span>
            </span>
            <span className="wiadomosci-text16">
              <span>Powiadomienia</span>
            </span>
            <span className="wiadomosci-text18">
              <span> zlecenia</span>
            </span>
          </div>
          <img
            alt="image121012"
            src="/external/image121012-uih-200h.png"
            className="wiadomosci-image12"
          />
          <span className="wiadomosci-text20">
            <span>Pan Twarug</span>
          </span>
          <img
            alt="Rectangle121012"
            src="/external/rectangle121012-j3k-200h.png"
            className="wiadomosci-rectangle12"
          />
          <img
            alt="Rectangle721033"
            src="/external/rectangle721033-4je-200h.png"
            className="wiadomosci-rectangle72"
          />
          <img
            alt="Rectangle231012"
            src="/external/rectangle231012-nzh-200h.png"
            className="wiadomosci-rectangle23"
          />
          <img
            alt="image131012"
            src="/external/image131012-wx9u-200h.png"
            className="wiadomosci-image13"
          />
          <div className="wiadomosci-frame9">
            <span className="wiadomosci-text22">
              <span>Q&amp;A</span>
            </span>
          </div>
          <img
            alt="Ellipse231012"
            src="/external/ellipse231012-p0gv-200h.png"
            className="wiadomosci-ellipse23"
          />
          <span className="wiadomosci-text24">
            <span>Nieprzeczytane</span>
          </span>
          <span className="wiadomosci-text26">
            <span>Przeczytane</span>
          </span>
          <div className="wiadomosci-group53">
            <div className="wiadomosci-group441">
              <div className="wiadomosci-frame211">
                <img
                  alt="Ellipse111033"
                  src="/external/ellipse111033-gdzi-200h.png"
                  className="wiadomosci-ellipse111"
                />
                <img
                  alt="Ellipse121033"
                  src="/external/ellipse121033-tg7j-200h.png"
                  className="wiadomosci-ellipse121"
                />
                <img
                  alt="Ellipse131033"
                  src="/external/ellipse131033-c8u-200h.png"
                  className="wiadomosci-ellipse131"
                />
              </div>
            </div>
            <img
              alt="Rectangle221033"
              src="/external/rectangle221033-8bpd-200w.png"
              className="wiadomosci-rectangle221"
            />
            <img
              alt="pngwing11033"
              src="/external/pngwing11033-8cj5-200w.png"
              className="wiadomosci-pngwing11"
            />
            <span className="wiadomosci-text28">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle731033"
              src="/external/rectangle731033-m2ye-200w.png"
              className="wiadomosci-rectangle731"
            />
            <span className="wiadomosci-text30">
              <span>
                Hej mam jeszcze kilka propozycji. Odpisz nie rób ze mnei debila
                siuśku...
              </span>
            </span>
            <span className="wiadomosci-text32">
              <span>Zlecenie 3412: Narysuj kotka</span>
            </span>
          </div>
          <div className="wiadomosci-group54">
            <div className="wiadomosci-group442">
              <div className="wiadomosci-frame212">
                <img
                  alt="Ellipse111033"
                  src="/external/ellipse111033-yxyn-200h.png"
                  className="wiadomosci-ellipse112"
                />
                <img
                  alt="Ellipse121033"
                  src="/external/ellipse121033-op1-200h.png"
                  className="wiadomosci-ellipse122"
                />
                <img
                  alt="Ellipse131033"
                  src="/external/ellipse131033-apfg-200h.png"
                  className="wiadomosci-ellipse132"
                />
              </div>
            </div>
            <img
              alt="Rectangle221033"
              src="/external/rectangle221033-392n-200w.png"
              className="wiadomosci-rectangle222"
            />
            <img
              alt="pngwing11033"
              src="/external/pngwing11033-1wgr-200w.png"
              className="wiadomosci-pngwing12"
            />
            <span className="wiadomosci-text34">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle731033"
              src="/external/rectangle731033-ao9-200w.png"
              className="wiadomosci-rectangle732"
            />
            <span className="wiadomosci-text36">
              <span>Opdisz plzzz</span>
            </span>
            <span className="wiadomosci-text38">
              <span>Zlecenie 3413: Może logo k..</span>
            </span>
          </div>
          <div className="wiadomosci-group55">
            <div className="wiadomosci-group443">
              <div className="wiadomosci-frame213">
                <img
                  alt="Ellipse111033"
                  src="/external/ellipse111033-381q-200h.png"
                  className="wiadomosci-ellipse113"
                />
                <img
                  alt="Ellipse121033"
                  src="/external/ellipse121033-jk7r-200h.png"
                  className="wiadomosci-ellipse123"
                />
                <img
                  alt="Ellipse131033"
                  src="/external/ellipse131033-95ll-200h.png"
                  className="wiadomosci-ellipse133"
                />
              </div>
            </div>
            <img
              alt="Rectangle221033"
              src="/external/rectangle221033-c8p6-200w.png"
              className="wiadomosci-rectangle223"
            />
            <img
              alt="pngwing11033"
              src="/external/pngwing11033-cthf-200w.png"
              className="wiadomosci-pngwing13"
            />
            <span className="wiadomosci-text40">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle731033"
              src="/external/rectangle731033-w5w9-200w.png"
              className="wiadomosci-rectangle733"
            />
            <span className="wiadomosci-text42">
              <span>Dobra nie chcesz zleceń to nie pa.</span>
            </span>
            <span className="wiadomosci-text44">
              <span>Zlecenie 3415: Dupp</span>
            </span>
          </div>
          <div className="wiadomosci-group56">
            <div className="wiadomosci-group444">
              <div className="wiadomosci-frame214">
                <img
                  alt="Ellipse111033"
                  src="/external/ellipse111033-rkcb-200h.png"
                  className="wiadomosci-ellipse114"
                />
                <img
                  alt="Ellipse121033"
                  src="/external/ellipse121033-7r5j-200h.png"
                  className="wiadomosci-ellipse124"
                />
                <img
                  alt="Ellipse131033"
                  src="/external/ellipse131033-815o-200h.png"
                  className="wiadomosci-ellipse134"
                />
              </div>
            </div>
            <img
              alt="Rectangle221033"
              src="/external/rectangle221033-kcg9-200w.png"
              className="wiadomosci-rectangle224"
            />
            <img
              alt="pngwing11033"
              src="/external/pngwing11033-n5os-200w.png"
              className="wiadomosci-pngwing14"
            />
            <span className="wiadomosci-text46">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle731033"
              src="/external/rectangle731033-7it8-200w.png"
              className="wiadomosci-rectangle734"
            />
            <span className="wiadomosci-text48">
              <span>Dobra nie chcesz zleceń to nie pa.</span>
            </span>
            <span className="wiadomosci-text50">
              <span>Zlecenie 3415: Dupp</span>
            </span>
          </div>
          <div className="wiadomosci-group57">
            <div className="wiadomosci-group445">
              <div className="wiadomosci-frame215">
                <img
                  alt="Ellipse111033"
                  src="/external/ellipse111033-kxtj-200h.png"
                  className="wiadomosci-ellipse115"
                />
                <img
                  alt="Ellipse121033"
                  src="/external/ellipse121033-t63a-200h.png"
                  className="wiadomosci-ellipse125"
                />
                <img
                  alt="Ellipse131033"
                  src="/external/ellipse131033-llk-200h.png"
                  className="wiadomosci-ellipse135"
                />
              </div>
            </div>
            <img
              alt="Rectangle221033"
              src="/external/rectangle221033-4kc9-200w.png"
              className="wiadomosci-rectangle225"
            />
            <img
              alt="pngwing11033"
              src="/external/pngwing11033-yd7-200w.png"
              className="wiadomosci-pngwing15"
            />
            <span className="wiadomosci-text52">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle731033"
              src="/external/rectangle731033-3918-200w.png"
              className="wiadomosci-rectangle735"
            />
            <span className="wiadomosci-text54">
              <span>Dobra nie chcesz zleceń to nie pa.</span>
            </span>
            <span className="wiadomosci-text56">
              <span>Zlecenie 3415: Dupp</span>
            </span>
          </div>
          <img
            alt="Rectangle741033"
            src="/external/rectangle741033-c52h-200h.png"
            className="wiadomosci-rectangle74"
          />
          <img
            alt="Rectangle751033"
            src="/external/rectangle751033-c1yn-200h.png"
            className="wiadomosci-rectangle75"
          />
          <div className="wiadomosci-frame3d-avatars16">
            <img
              alt="Avatars3davatar16I136"
              src="/external/avatars3davatar16i136-di22-200h.png"
              className="wiadomosci-avatars3davatar16"
            />
          </div>
          <div className="wiadomosci-frame3d-avatars15">
            <img
              alt="Avatars3davatar15I136"
              src="/external/avatars3davatar15i136-7269-200h.png"
              className="wiadomosci-avatars3davatar15"
            />
          </div>
          <div className="wiadomosci-frame3d-avatars22">
            <img
              alt="Avatars3davatar22I136"
              src="/external/avatars3davatar22i136-ugft-200h.png"
              className="wiadomosci-avatars3davatar22"
            />
          </div>
          <div className="wiadomosci-frame3d-avatars6">
            <img
              alt="Avatars3davatar6I136"
              src="/external/avatars3davatar6i136-ac8r-200h.png"
              className="wiadomosci-avatars3davatar6"
            />
          </div>
          <div className="wiadomosci-frame3d-avatars7">
            <img
              alt="Avatars3davatar7I136"
              src="/external/avatars3davatar7i136-7n4g-200h.png"
              className="wiadomosci-avatars3davatar7"
            />
          </div>
        </button>
      </div>
    </div>
  )
}

export default Wiadomosci
