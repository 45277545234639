import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './oknobrif-fotwartybutton.css'

const OKNOBRIFFotwartybutton = (props) => {
  return (
    <div className="oknobrif-fotwartybutton-container1">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="oknobrif-fotwartybutton-dummy-container">
        <button className="oknobrif-fotwartybutton-oknobrif-fotwartybutton">
          <div className="oknobrif-fotwartybutton-group153">
            <img
              alt="Rectangle933453"
              src="/external/rectangle933453-k8f-600w.png"
              className="oknobrif-fotwartybutton-rectangle93"
            />
            <img
              alt="Rectangle1053453"
              src="/external/rectangle1053453-nhhl-600w.png"
              className="oknobrif-fotwartybutton-rectangle105"
            />
            <img
              alt="Rectangle1063453"
              src="/external/rectangle1063453-hi26-600h.png"
              className="oknobrif-fotwartybutton-rectangle106"
            />
          </div>
          <div className="oknobrif-fotwartybutton-group115">
            <div className="oknobrif-fotwartybutton-frame1111">
              <Link to="/" className="oknobrif-fotwartybutton-navlink">
                <img
                  alt="LGOOROBOCZEV00113453"
                  src="/external/lgooroboczev00113453-rkhc-200h.png"
                  className="oknobrif-fotwartybutton-lgooroboczev0011"
                />
              </Link>
              <div className="oknobrif-fotwartybutton-group114">
                <div className="oknobrif-fotwartybutton-input">
                  <div className="oknobrif-fotwartybutton-container2">
                    <span className="oknobrif-fotwartybutton-text10">
                      <span>Czego szukasz towarzyszu?</span>
                    </span>
                  </div>
                </div>
                <button className="oknobrif-fotwartybutton-button1">
                  <img
                    alt="SVG3453"
                    src="/external/svg3453-ktm.svg"
                    className="oknobrif-fotwartybutton-svg"
                  />
                </button>
              </div>
              <button className="oknobrif-fotwartybutton-item-button">
                <div className="oknobrif-fotwartybutton-group112">
                  <Link
                    to="/communityartystycznebutton"
                    className="oknobrif-fotwartybutton-text12"
                  >
                    <span>Społeczność Artystyczna</span>
                  </Link>
                  <img
                    alt="Vector3453"
                    src="/external/vector3453-60lf.svg"
                    className="oknobrif-fotwartybutton-vector1"
                  />
                </div>
              </button>
              <div className="oknobrif-fotwartybutton-group113">
                <Link
                  to="/communityartystycznebutton"
                  className="oknobrif-fotwartybutton-text14"
                >
                  <span>Q&amp;A</span>
                </Link>
                <img
                  alt="Vector3453"
                  src="/external/vector3453-28fg.svg"
                  className="oknobrif-fotwartybutton-vector2"
                />
              </div>
              <div className="oknobrif-fotwartybutton-item-link1">
                <Link
                  to="/logowani-ebutton"
                  className="oknobrif-fotwartybutton-text16"
                >
                  <span>Sign in</span>
                </Link>
              </div>
              <div className="oknobrif-fotwartybutton-item-link2">
                <Link
                  to="/logowani-ebutton"
                  className="oknobrif-fotwartybutton-text18"
                >
                  <span>Join</span>
                </Link>
              </div>
            </div>
          </div>
          <img
            alt="Rectangle1013453"
            src="/external/rectangle1013453-hjj-200h.png"
            className="oknobrif-fotwartybutton-rectangle101"
          />
          <div className="oknobrif-fotwartybutton-frame1112">
            <Link
              to="/podstrona-grafikbutton"
              className="oknobrif-fotwartybutton-text20"
            >
              <span>Cosplay</span>
            </Link>
            <Link
              to="/podstrona-grafikbutton"
              className="oknobrif-fotwartybutton-text22"
            >
              <span>Pisarz</span>
            </Link>
            <Link
              to="/podstrona-grafikbutton"
              className="oknobrif-fotwartybutton-text24"
            >
              <span>Muzyka&amp;audio</span>
            </Link>
            <Link
              to="/podstrona-grafikbutton"
              className="oknobrif-fotwartybutton-text26"
            >
              <span>Rysownik</span>
            </Link>
            <Link
              to="/podstrona-grafikbutton"
              className="oknobrif-fotwartybutton-text28"
            >
              <span>UI/Ux Design</span>
            </Link>
            <Link
              to="/podstrona-grafikbutton"
              className="oknobrif-fotwartybutton-text30"
            >
              <span>Grafika Komputerowa</span>
            </Link>
          </div>
          <img
            alt="Rectangle1033453"
            src="/external/rectangle1033453-e4q6-200h.png"
            className="oknobrif-fotwartybutton-rectangle103"
          />
          <div className="oknobrif-fotwartybutton-frame152">
            <div className="oknobrif-fotwartybutton-frame151">
              <div className="oknobrif-fotwartybutton-group148">
                <img
                  alt="Avatars3davatar163453"
                  src="/external/avatars3davatar163453-r2l9-200w.png"
                  className="oknobrif-fotwartybutton-avatars3davatar16"
                />
                <img
                  alt="Ellipse253453"
                  src="/external/ellipse253453-4xiq-200h.png"
                  className="oknobrif-fotwartybutton-ellipse25"
                />
              </div>
              <div className="oknobrif-fotwartybutton-frame150">
                <span className="oknobrif-fotwartybutton-text32">
                  <span>Zlecenie 3415: Dupp</span>
                </span>
                <span className="oknobrif-fotwartybutton-text34">
                  <span>Wykonaj Dowolne logo!</span>
                </span>
                <div className="oknobrif-fotwartybutton-frame70">
                  <img
                    alt="Dollarsign3453"
                    src="/external/dollarsign3453-tkf7.svg"
                    className="oknobrif-fotwartybutton-dollarsign"
                  />
                  <span className="oknobrif-fotwartybutton-text36">
                    <span>Budżet: 200 zł</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <span className="oknobrif-fotwartybutton-text38">
            <span>
              They always say that it’s a mistake to hire your friends. And they
              are right. So, I hired my best friends.
            </span>
          </span>
          <div className="oknobrif-fotwartybutton-photo16x9"></div>
          <span className="oknobrif-fotwartybutton-text40">
            <span>
              They always say that it’s a mistake to hire your friends. And they
              are right. So, I hired my best friends.
            </span>
          </span>
          <div className="oknobrif-fotwartybutton-numbered-list">
            <div className="oknobrif-fotwartybutton-frame18">
              <img
                alt="Number1I345"
                src="/external/number1i345-t9u.svg"
                className="oknobrif-fotwartybutton-number1"
              />
              <span className="oknobrif-fotwartybutton-text42 Body">
                <span>
                  Four years ago, I was just a guy who had a crush on a girl who
                  had a boyfriend.
                </span>
              </span>
            </div>
            <div className="oknobrif-fotwartybutton-frame21">
              <img
                alt="Number2I345"
                src="/external/number2i345-u1fw.svg"
                className="oknobrif-fotwartybutton-number2"
              />
              <span className="oknobrif-fotwartybutton-text44 Body">
                <span>
                  And I had to do the hardest thing I’ve ever had to do, which
                  was just to wait.
                </span>
              </span>
            </div>
            <div className="oknobrif-fotwartybutton-frame22">
              <img
                alt="Number3I345"
                src="/external/number3i345-q9pl.svg"
                className="oknobrif-fotwartybutton-number3"
              />
              <span className="oknobrif-fotwartybutton-text46 Body">
                <span>
                  For a really long time that’s all I had. I just had little
                  moments with a girl who saw me as a friend.
                </span>
              </span>
            </div>
            <div className="oknobrif-fotwartybutton-frame23">
              <img
                alt="Number4I345"
                src="/external/number4i345-rk3.svg"
                className="oknobrif-fotwartybutton-number4"
              />
              <span className="oknobrif-fotwartybutton-text48 Body">
                <span>
                  And a lot of people told me I was crazy to wait this long for
                  a date with a girl who I worked with.
                </span>
              </span>
            </div>
            <div className="oknobrif-fotwartybutton-frame24">
              <img
                alt="Number5I345"
                src="/external/number5i345-fbrp.svg"
                className="oknobrif-fotwartybutton-number5"
              />
              <span className="oknobrif-fotwartybutton-text50 Body">
                <span>
                  But I think even then I knew that I was waiting for my wife.
                </span>
              </span>
            </div>
          </div>
          <Link
            to="/oknopowiadomi-ebutton"
            className="oknobrif-fotwartybutton-button2"
          >
            <span className="oknobrif-fotwartybutton-text52">
              <span>Zamknij Briff</span>
            </span>
          </Link>
        </button>
      </div>
    </div>
  )
}

export default OKNOBRIFFotwartybutton
