import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './profil-uytkownika-wykonanezleceniabutton.css'

const ProfilUytkownikaWykonanezleceniabutton = (props) => {
  return (
    <div className="profil-uytkownika-wykonanezleceniabutton-container1">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="profil-uytkownika-wykonanezleceniabutton-dummy-container">
        <button className="profil-uytkownika-wykonanezleceniabutton-profil-uytkownika-wykonanezleceniabutton">
          <img
            alt="Rectangle1153451"
            src="/external/rectangle1153451-sqtf-1200h.png"
            className="profil-uytkownika-wykonanezleceniabutton-rectangle115"
          />
          <div className="profil-uytkownika-wykonanezleceniabutton-group115">
            <div className="profil-uytkownika-wykonanezleceniabutton-frame1111">
              <Link
                to="/"
                className="profil-uytkownika-wykonanezleceniabutton-navlink1"
              >
                <img
                  alt="LGOOROBOCZEV00113451"
                  src="/external/lgooroboczev00113451-knd9-200h.png"
                  className="profil-uytkownika-wykonanezleceniabutton-lgooroboczev0011"
                />
              </Link>
              <div className="profil-uytkownika-wykonanezleceniabutton-group114">
                <div className="profil-uytkownika-wykonanezleceniabutton-input">
                  <div className="profil-uytkownika-wykonanezleceniabutton-container2">
                    <span className="profil-uytkownika-wykonanezleceniabutton-text10">
                      <span>Czego szukasz towarzyszu?</span>
                    </span>
                  </div>
                </div>
                <button className="profil-uytkownika-wykonanezleceniabutton-button1">
                  <img
                    alt="SVG3451"
                    src="/external/svg3451-xcfj.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-svg"
                  />
                </button>
              </div>
              <button className="profil-uytkownika-wykonanezleceniabutton-item-button">
                <div className="profil-uytkownika-wykonanezleceniabutton-group112">
                  <Link
                    to="/communityartystycznebutton"
                    className="profil-uytkownika-wykonanezleceniabutton-text12"
                  >
                    <span>Społeczność Artystyczna</span>
                  </Link>
                  <img
                    alt="Vector3451"
                    src="/external/vector3451-1pqm.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-vector1"
                  />
                </div>
              </button>
              <Link
                to="/communityartystycznebutton"
                className="profil-uytkownika-wykonanezleceniabutton-navlink2"
              >
                <div className="profil-uytkownika-wykonanezleceniabutton-group113">
                  <span className="profil-uytkownika-wykonanezleceniabutton-text14">
                    <span>Q&amp;A</span>
                  </span>
                  <img
                    alt="Vector3451"
                    src="/external/vector3451-lxda.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-vector2"
                  />
                </div>
              </Link>
              <div className="profil-uytkownika-wykonanezleceniabutton-item-link1">
                <Link
                  to="/logowani-ebutton"
                  className="profil-uytkownika-wykonanezleceniabutton-text16"
                >
                  <span>Sign in</span>
                </Link>
              </div>
              <Link
                to="/logowani-ebutton"
                className="profil-uytkownika-wykonanezleceniabutton-navlink3"
              >
                <div className="profil-uytkownika-wykonanezleceniabutton-item-link2">
                  <span className="profil-uytkownika-wykonanezleceniabutton-text18">
                    <span>Join</span>
                  </span>
                </div>
              </Link>
            </div>
          </div>
          <img
            alt="Rectangle1013451"
            src="/external/rectangle1013451-f5p-200h.png"
            className="profil-uytkownika-wykonanezleceniabutton-rectangle101"
          />
          <Link
            to="/podstrona-grafikbutton"
            className="profil-uytkownika-wykonanezleceniabutton-navlink4"
          >
            <div className="profil-uytkownika-wykonanezleceniabutton-frame1112">
              <span className="profil-uytkownika-wykonanezleceniabutton-text20">
                <span>Cosplay</span>
              </span>
              <span className="profil-uytkownika-wykonanezleceniabutton-text22">
                <span>Pisarz</span>
              </span>
              <span className="profil-uytkownika-wykonanezleceniabutton-text24">
                <span>Muzyka&amp;audio</span>
              </span>
              <span className="profil-uytkownika-wykonanezleceniabutton-text26">
                <span>Rysownik</span>
              </span>
              <span className="profil-uytkownika-wykonanezleceniabutton-text28">
                <span>UI/Ux Design</span>
              </span>
              <span className="profil-uytkownika-wykonanezleceniabutton-text30">
                <span>Grafika Komputerowa</span>
              </span>
            </div>
          </Link>
          <img
            alt="Rectangle1033451"
            src="/external/rectangle1033451-c64g-200h.png"
            className="profil-uytkownika-wykonanezleceniabutton-rectangle103"
          />
          <div className="profil-uytkownika-wykonanezleceniabutton-frame188">
            <span className="profil-uytkownika-wykonanezleceniabutton-text32">
              <span>Profil Artysty</span>
            </span>
            <div className="profil-uytkownika-wykonanezleceniabutton-frame182">
              <img
                alt="Frame1323451"
                src="/external/frame1323451-bpkg.svg"
                className="profil-uytkownika-wykonanezleceniabutton-frame132"
              />
              <button className="profil-uytkownika-wykonanezleceniabutton-button2">
                <img
                  alt="SendI345"
                  src="/external/sendi345-bmx.svg"
                  className="profil-uytkownika-wykonanezleceniabutton-send"
                />
                <span className="profil-uytkownika-wykonanezleceniabutton-text34 SingleLineBodyBase">
                  <span>Wyślij Zlecenie</span>
                </span>
              </button>
            </div>
          </div>
          <div className="profil-uytkownika-wykonanezleceniabutton-frame189">
            <span className="profil-uytkownika-wykonanezleceniabutton-text36">
              <span>Wykonane zlecenia</span>
            </span>
            <span className="profil-uytkownika-wykonanezleceniabutton-text38">
              <span>Historia wykonanych zleceń</span>
            </span>
          </div>
          <div className="profil-uytkownika-wykonanezleceniabutton-group162">
            <div className="profil-uytkownika-wykonanezleceniabutton-frame181">
              <div className="profil-uytkownika-wykonanezleceniabutton-frame178">
                <div className="profil-uytkownika-wykonanezleceniabutton-group157">
                  <img
                    alt="image213451"
                    src="/external/image213451-1vmt-200h.png"
                    className="profil-uytkownika-wykonanezleceniabutton-image21"
                  />
                  <img
                    alt="Ellipse253451"
                    src="/external/ellipse253451-5rt9-200h.png"
                    className="profil-uytkownika-wykonanezleceniabutton-ellipse25"
                  />
                </div>
                <span className="profil-uytkownika-wykonanezleceniabutton-text40">
                  <span>Magdalena Maria  Monika</span>
                </span>
                <div className="profil-uytkownika-wykonanezleceniabutton-frame1651">
                  <img
                    alt="Star13452"
                    src="/external/star13452-c2ll.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star11"
                  />
                  <img
                    alt="Star23452"
                    src="/external/star23452-t13.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star21"
                  />
                  <img
                    alt="Star33452"
                    src="/external/star33452-mywl.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star31"
                  />
                  <img
                    alt="Star43452"
                    src="/external/star43452-c9bl.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star41"
                  />
                  <img
                    alt="Star53452"
                    src="/external/star53452-b0m.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star51"
                  />
                </div>
                <div className="profil-uytkownika-wykonanezleceniabutton-frame171">
                  <div className="profil-uytkownika-wykonanezleceniabutton-frame168">
                    <div className="profil-uytkownika-wykonanezleceniabutton-frame1671">
                      <img
                        alt="awardiconmedalpng13451"
                        src="/external/awardiconmedalpng13451-9nw-200w.png"
                        className="profil-uytkownika-wykonanezleceniabutton-awardiconmedalpng11"
                      />
                      <span className="profil-uytkownika-wykonanezleceniabutton-text42">
                        <span>Zweryfikowany Artysta</span>
                      </span>
                    </div>
                  </div>
                  <div className="profil-uytkownika-wykonanezleceniabutton-frame169">
                    <div className="profil-uytkownika-wykonanezleceniabutton-frame1672">
                      <img
                        alt="awardiconmedalpng13451"
                        src="/external/awardiconmedalpng13451-mmn-200w.png"
                        className="profil-uytkownika-wykonanezleceniabutton-awardiconmedalpng12"
                      />
                      <span className="profil-uytkownika-wykonanezleceniabutton-text44">
                        <span>Artysta z community</span>
                      </span>
                    </div>
                  </div>
                  <div className="profil-uytkownika-wykonanezleceniabutton-frame170">
                    <div className="profil-uytkownika-wykonanezleceniabutton-frame1673">
                      <img
                        alt="awardiconmedalpng13451"
                        src="/external/awardiconmedalpng13451-p4qf-200w.png"
                        className="profil-uytkownika-wykonanezleceniabutton-awardiconmedalpng13"
                      />
                      <span className="profil-uytkownika-wykonanezleceniabutton-text46">
                        <span>Wysoki poziom zaufania</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="profil-uytkownika-wykonanezleceniabutton-frame172">
                  <img
                    alt="icon3451"
                    src="/external/icon3451-dd4e.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-icon1"
                  />
                  <span className="profil-uytkownika-wykonanezleceniabutton-text48">
                    <span>Wykonane zlecenia: 24</span>
                  </span>
                </div>
              </div>
            </div>
            <img
              alt="Rectangle1163451"
              src="/external/rectangle1163451-8j1g-200h.png"
              className="profil-uytkownika-wykonanezleceniabutton-rectangle116"
            />
            <div className="profil-uytkownika-wykonanezleceniabutton-frame174">
              <img
                alt="mail3451"
                src="/external/mail3451-n17.svg"
                className="profil-uytkownika-wykonanezleceniabutton-mail"
              />
              <Link
                to="/profil-uytkownika-opiniebutton"
                className="profil-uytkownika-wykonanezleceniabutton-text50"
              >
                <span>Opinie</span>
              </Link>
            </div>
            <div className="profil-uytkownika-wykonanezleceniabutton-frame173">
              <img
                alt="icon3451"
                src="/external/icon3451-gqg7.svg"
                className="profil-uytkownika-wykonanezleceniabutton-icon2"
              />
              <Link
                to="/profil-uytkownika-ommiebutton"
                className="profil-uytkownika-wykonanezleceniabutton-text52"
              >
                <span>O mnie</span>
              </Link>
            </div>
            <div className="profil-uytkownika-wykonanezleceniabutton-frame175">
              <img
                alt="photo3451"
                src="/external/photo3451-2oag.svg"
                className="profil-uytkownika-wykonanezleceniabutton-photo"
              />
              <Link
                to="/profil-uytkownika-portfoliobutton"
                className="profil-uytkownika-wykonanezleceniabutton-text54"
              >
                <span>Portfolio</span>
              </Link>
            </div>
            <div className="profil-uytkownika-wykonanezleceniabutton-frame176">
              <img
                alt="folderfilled3451"
                src="/external/folderfilled3451-9zqf.svg"
                className="profil-uytkownika-wykonanezleceniabutton-folderfilled"
              />
              <span className="profil-uytkownika-wykonanezleceniabutton-text56">
                <span>
                  Wykonane
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>zlecenia</span>
              </span>
            </div>
          </div>
          <div className="profil-uytkownika-wykonanezleceniabutton-frame195">
            <div className="profil-uytkownika-wykonanezleceniabutton-group164">
              <div className="profil-uytkownika-wykonanezleceniabutton-frame1901">
                <div className="profil-uytkownika-wykonanezleceniabutton-frame1652">
                  <img
                    alt="Star13451"
                    src="/external/star13451-k2zp.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star12"
                  />
                  <img
                    alt="Star23451"
                    src="/external/star23451-aban.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star22"
                  />
                  <img
                    alt="Star33451"
                    src="/external/star33451-x3np.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star32"
                  />
                  <img
                    alt="Star43451"
                    src="/external/star43451-52e.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star42"
                  />
                  <img
                    alt="Star53451"
                    src="/external/star53451-38ib.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star52"
                  />
                </div>
                <span className="profil-uytkownika-wykonanezleceniabutton-text60">
                  <span>3/5</span>
                </span>
              </div>
              <div className="profil-uytkownika-wykonanezleceniabutton-frame1941">
                <div className="profil-uytkownika-wykonanezleceniabutton-frame1931">
                  <div className="profil-uytkownika-wykonanezleceniabutton-frame1921">
                    <span className="profil-uytkownika-wykonanezleceniabutton-text62">
                      <span>Wektor ART</span>
                    </span>
                    <div className="profil-uytkownika-wykonanezleceniabutton-frame1911">
                      <div className="profil-uytkownika-wykonanezleceniabutton-frame3d-avatars61">
                        <img
                          alt="Avatars3davatar6I345"
                          src="/external/avatars3davatar6i345-15vi-200h.png"
                          className="profil-uytkownika-wykonanezleceniabutton-avatars3davatar61"
                        />
                      </div>
                      <span className="profil-uytkownika-wykonanezleceniabutton-text64">
                        <span> Pan Maruda</span>
                      </span>
                    </div>
                  </div>
                  <span className="profil-uytkownika-wykonanezleceniabutton-text66">
                    <span>
                      &quot;Niesamowita praca! Anna doskonale uchwyciła
                      atmosferę, której szukałem.’’
                    </span>
                  </span>
                </div>
                <div className="profil-uytkownika-wykonanezleceniabutton-maskgroup1">
                  <img
                    alt="fututtuut13452"
                    src="/external/fututtuut13452-3ysg-300h.png"
                    className="profil-uytkownika-wykonanezleceniabutton-fututtuut11"
                  />
                </div>
              </div>
            </div>
            <div className="profil-uytkownika-wykonanezleceniabutton-group1651">
              <div className="profil-uytkownika-wykonanezleceniabutton-frame1902">
                <div className="profil-uytkownika-wykonanezleceniabutton-frame1653">
                  <img
                    alt="Star13452"
                    src="/external/star13452-10wn.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star13"
                  />
                  <img
                    alt="Star23452"
                    src="/external/star23452-pwze.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star23"
                  />
                  <img
                    alt="Star33452"
                    src="/external/star33452-jwfi.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star33"
                  />
                  <img
                    alt="Star43452"
                    src="/external/star43452-woeq.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star43"
                  />
                  <img
                    alt="Star53452"
                    src="/external/star53452-5taf.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star53"
                  />
                </div>
                <span className="profil-uytkownika-wykonanezleceniabutton-text68">
                  <span>3/5</span>
                </span>
              </div>
              <div className="profil-uytkownika-wykonanezleceniabutton-frame1942">
                <div className="profil-uytkownika-wykonanezleceniabutton-frame1932">
                  <div className="profil-uytkownika-wykonanezleceniabutton-frame1922">
                    <span className="profil-uytkownika-wykonanezleceniabutton-text70">
                      <span>Baner Na youtube</span>
                    </span>
                    <div className="profil-uytkownika-wykonanezleceniabutton-frame1912">
                      <div className="profil-uytkownika-wykonanezleceniabutton-frame3d-avatars62">
                        <img
                          alt="Avatars3davatar6I345"
                          src="/external/avatars3davatar6i345-93zg-200h.png"
                          className="profil-uytkownika-wykonanezleceniabutton-avatars3davatar62"
                        />
                      </div>
                      <span className="profil-uytkownika-wykonanezleceniabutton-text72">
                        <span>Dupcyper</span>
                      </span>
                    </div>
                  </div>
                  <span className="profil-uytkownika-wykonanezleceniabutton-text74">
                    <span>
                      &quot;Bardzo zadowolona z efektu końcowego. Drobne
                      poprawki zostały szybko wprowadzone.&quot;
                    </span>
                  </span>
                </div>
                <div className="profil-uytkownika-wykonanezleceniabutton-maskgroup2">
                  <img
                    alt="b113452"
                    src="/external/b113452-zpmp-300h.png"
                    className="profil-uytkownika-wykonanezleceniabutton-b11"
                  />
                </div>
              </div>
            </div>
            <div className="profil-uytkownika-wykonanezleceniabutton-group1652">
              <div className="profil-uytkownika-wykonanezleceniabutton-frame1903">
                <div className="profil-uytkownika-wykonanezleceniabutton-frame1654">
                  <img
                    alt="Star13452"
                    src="/external/star13452-9mtc.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star14"
                  />
                  <img
                    alt="Star23452"
                    src="/external/star23452-ycal.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star24"
                  />
                  <img
                    alt="Star33452"
                    src="/external/star33452-sai9.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star34"
                  />
                  <img
                    alt="Star43452"
                    src="/external/star43452-ls3.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star44"
                  />
                  <img
                    alt="Star53452"
                    src="/external/star53452-o9r.svg"
                    className="profil-uytkownika-wykonanezleceniabutton-star54"
                  />
                </div>
                <span className="profil-uytkownika-wykonanezleceniabutton-text76">
                  <span>3/5</span>
                </span>
              </div>
              <div className="profil-uytkownika-wykonanezleceniabutton-frame1943">
                <div className="profil-uytkownika-wykonanezleceniabutton-frame1933">
                  <div className="profil-uytkownika-wykonanezleceniabutton-frame1923">
                    <span className="profil-uytkownika-wykonanezleceniabutton-text78">
                      <span>Wektor ART</span>
                    </span>
                    <div className="profil-uytkownika-wykonanezleceniabutton-frame1913">
                      <div className="profil-uytkownika-wykonanezleceniabutton-frame3d-avatars63">
                        <img
                          alt="Avatars3davatar6I345"
                          src="/external/avatars3davatar6i345-mzjlj-200h.png"
                          className="profil-uytkownika-wykonanezleceniabutton-avatars3davatar63"
                        />
                      </div>
                      <span className="profil-uytkownika-wykonanezleceniabutton-text80">
                        <span> Pan Maruda</span>
                      </span>
                    </div>
                  </div>
                  <span className="profil-uytkownika-wykonanezleceniabutton-text82">
                    <span>
                      &quot;Niesamowita praca! Anna doskonale uchwyciła
                      atmosferę, której szukałem.’’
                    </span>
                  </span>
                </div>
                <div className="profil-uytkownika-wykonanezleceniabutton-maskgroup3">
                  <img
                    alt="fututtuut13452"
                    src="/external/fututtuut13452-zpla-700w.png"
                    className="profil-uytkownika-wykonanezleceniabutton-fututtuut12"
                  />
                </div>
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default ProfilUytkownikaWykonanezleceniabutton
