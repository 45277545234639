import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './podstrona-grafikbutton.css'

const PodstronaGrafikbutton = (props) => {
  return (
    <div className="podstrona-grafikbutton-container10">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="podstrona-grafikbutton-dummy-container">
        <button className="podstrona-grafikbutton-podstrona-grafikbutton">
          <div className="podstrona-grafikbutton-group152">
            <img
              alt="Rectangle933209"
              src="/external/rectangle933209-ruq4-600w.png"
              className="podstrona-grafikbutton-rectangle93"
            />
            <img
              alt="Rectangle1053209"
              src="/external/rectangle1053209-qkmp-600w.png"
              className="podstrona-grafikbutton-rectangle105"
            />
            <img
              alt="Rectangle1063201"
              src="/external/rectangle1063201-krfq-600w.png"
              className="podstrona-grafikbutton-rectangle106"
            />
          </div>
          <div className="podstrona-grafikbutton-group115">
            <div className="podstrona-grafikbutton-frame1111">
              <Link to="/" className="podstrona-grafikbutton-navlink1">
                <img
                  alt="LGOOROBOCZEV00112694"
                  src="/external/lgooroboczev00112694-uoq-200h.png"
                  className="podstrona-grafikbutton-lgooroboczev0011"
                />
              </Link>
              <div className="podstrona-grafikbutton-group114">
                <div className="podstrona-grafikbutton-input">
                  <div className="podstrona-grafikbutton-container11">
                    <span className="podstrona-grafikbutton-text100">
                      <span>Czego szukasz towarzyszu?</span>
                    </span>
                  </div>
                </div>
                <button className="podstrona-grafikbutton-button10">
                  <img
                    alt="SVG2694"
                    src="/external/svg2694-qm3y.svg"
                    className="podstrona-grafikbutton-svg1"
                  />
                </button>
              </div>
              <button className="podstrona-grafikbutton-item-button">
                <div className="podstrona-grafikbutton-group112">
                  <Link
                    to="/communityartystycznebutton"
                    className="podstrona-grafikbutton-text102"
                  >
                    <span>Społeczność Artystyczna</span>
                  </Link>
                  <img
                    alt="Vector2694"
                    src="/external/vector2694-0oxk.svg"
                    className="podstrona-grafikbutton-vector1"
                  />
                </div>
              </button>
              <div className="podstrona-grafikbutton-group113">
                <Link
                  to="/communityartystycznebutton"
                  className="podstrona-grafikbutton-text104"
                >
                  <span>Q&amp;A</span>
                </Link>
                <img
                  alt="Vector2694"
                  src="/external/vector2694-29q.svg"
                  className="podstrona-grafikbutton-vector2"
                />
              </div>
              <div className="podstrona-grafikbutton-item-link1">
                <Link
                  to="/logowani-ebutton"
                  className="podstrona-grafikbutton-text106"
                >
                  <span>Sign in</span>
                </Link>
              </div>
              <Link
                to="/logowani-ebutton"
                className="podstrona-grafikbutton-navlink2"
              >
                <div className="podstrona-grafikbutton-item-link2">
                  <span className="podstrona-grafikbutton-text108">
                    <span>Join</span>
                  </span>
                </div>
              </Link>
            </div>
          </div>
          <img
            alt="Rectangle1012696"
            src="/external/rectangle1012696-br9k-200h.png"
            className="podstrona-grafikbutton-rectangle101"
          />
          <Link
            to="/podstrona-grafikbutton"
            className="podstrona-grafikbutton-navlink3"
          >
            <div className="podstrona-grafikbutton-frame1112">
              <span className="podstrona-grafikbutton-text110">
                <span>Cosplay</span>
              </span>
              <span className="podstrona-grafikbutton-text112">
                <span>Pisarz</span>
              </span>
              <span className="podstrona-grafikbutton-text114">
                <span>Muzyka&amp;audio</span>
              </span>
              <span className="podstrona-grafikbutton-text116">
                <span>Rysownik</span>
              </span>
              <span className="podstrona-grafikbutton-text118">
                <span>UI/Ux Design</span>
              </span>
              <span className="podstrona-grafikbutton-text120">
                <span>Grafika Komputerowa</span>
              </span>
            </div>
          </Link>
          <div className="podstrona-grafikbutton-frame129">
            <span className="podstrona-grafikbutton-text122">
              <span>Grafik komputerowy</span>
            </span>
            <span className="podstrona-grafikbutton-text124">
              <span>Stwórz co potrzebujesz ^^</span>
            </span>
          </div>
          <img
            alt="Rectangle1032696"
            src="/external/rectangle1032696-g1z-200h.png"
            className="podstrona-grafikbutton-rectangle103"
          />
          <div className="podstrona-grafikbutton-dialog">
            <div className="podstrona-grafikbutton-header">
              <div className="podstrona-grafikbutton-container12">
                <div className="podstrona-grafikbutton-container13">
                  <div className="podstrona-grafikbutton-frame141">
                    <div className="podstrona-grafikbutton-container14">
                      <span className="podstrona-grafikbutton-text126">
                        <span>Filtry</span>
                      </span>
                    </div>
                    <button className="podstrona-grafikbutton-button11">
                      <span className="podstrona-grafikbutton-text128">
                        <span>Wyczyść Wszystko</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="podstrona-grafikbutton-container15">
              <div className="podstrona-grafikbutton-container16">
                <div className="podstrona-grafikbutton-container17">
                  <div className="podstrona-grafikbutton-container18">
                    <span className="podstrona-grafikbutton-text130">
                      <span>ogólne</span>
                    </span>
                  </div>
                  <div className="podstrona-grafikbutton-horizontal-border1">
                    <div className="podstrona-grafikbutton-container19">
                      <div className="podstrona-grafikbutton-container20">
                        <span className="podstrona-grafikbutton-text132">
                          <span>Specjalizacja</span>
                        </span>
                      </div>
                      <div className="podstrona-grafikbutton-container21">
                        <button className="podstrona-grafikbutton-button-open-dropdown1">
                          <div className="podstrona-grafikbutton-container22">
                            <img
                              alt="SVG2693"
                              src="/external/svg2693-53r.svg"
                              className="podstrona-grafikbutton-svg2"
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="podstrona-grafikbutton-horizontal-border2">
                    <div className="podstrona-grafikbutton-container23">
                      <div className="podstrona-grafikbutton-container24">
                        <span className="podstrona-grafikbutton-text134">
                          <span>Czas realizacji</span>
                        </span>
                      </div>
                      <div className="podstrona-grafikbutton-container25">
                        <button className="podstrona-grafikbutton-button-open-dropdown2">
                          <div className="podstrona-grafikbutton-container26">
                            <img
                              alt="SVG2693"
                              src="/external/svg2693-o3f4.svg"
                              className="podstrona-grafikbutton-svg3"
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="podstrona-grafikbutton-horizontal-border3">
                    <div className="podstrona-grafikbutton-container27">
                      <div className="podstrona-grafikbutton-container28">
                        <span className="podstrona-grafikbutton-text136">
                          <span>Opinie klientów</span>
                        </span>
                      </div>
                      <div className="podstrona-grafikbutton-container29">
                        <button className="podstrona-grafikbutton-button-open-dropdown3">
                          <div className="podstrona-grafikbutton-container30">
                            <img
                              alt="SVG2694"
                              src="/external/svg2694-iht8.svg"
                              className="podstrona-grafikbutton-svg4"
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="podstrona-grafikbutton-horizontal-border4">
                    <div className="podstrona-grafikbutton-container31">
                      <div className="podstrona-grafikbutton-container32">
                        <span className="podstrona-grafikbutton-text138">
                          <span>Liczba poprawek</span>
                        </span>
                      </div>
                      <div className="podstrona-grafikbutton-container33">
                        <button className="podstrona-grafikbutton-button-open-dropdown4">
                          <div className="podstrona-grafikbutton-container34">
                            <img
                              alt="SVG2694"
                              src="/external/svg2694-hqmh.svg"
                              className="podstrona-grafikbutton-svg5"
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="podstrona-grafikbutton-horizontal-border5">
                  <div className="podstrona-grafikbutton-container35">
                    <div className="podstrona-grafikbutton-container36">
                      <span className="podstrona-grafikbutton-text140 pro.fiverr.comArialBold15.75">
                        <span>Budżet</span>
                      </span>
                    </div>
                    <div className="podstrona-grafikbutton-container37">
                      <button className="podstrona-grafikbutton-button-open-dropdown5">
                        <div className="podstrona-grafikbutton-container38">
                          <img
                            alt="SVG2694"
                            src="/external/svg2694-wwo.svg"
                            className="podstrona-grafikbutton-svg6"
                          />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="podstrona-grafikbutton-container39">
                  <span className="podstrona-grafikbutton-text142">
                    <span>Detale o artyście</span>
                  </span>
                </div>
                <div className="podstrona-grafikbutton-horizontal-border6">
                  <div className="podstrona-grafikbutton-container40">
                    <span className="podstrona-grafikbutton-text144">
                      <span>Pokaż tylko Online</span>
                    </span>
                  </div>
                  <div className="podstrona-grafikbutton-label1">
                    <div className="podstrona-grafikbutton-background1">
                      <img
                        alt="Background2694"
                        src="/external/background2694-aoda-200h.png"
                        className="podstrona-grafikbutton-background2"
                      />
                    </div>
                  </div>
                </div>
                <div className="podstrona-grafikbutton-horizontal-border7">
                  <div className="podstrona-grafikbutton-container41">
                    <span className="podstrona-grafikbutton-text146">
                      <span>
                        Oferuje konsultacje przed rozpoczęciem projektu
                      </span>
                    </span>
                  </div>
                  <div className="podstrona-grafikbutton-label2">
                    <div className="podstrona-grafikbutton-background3">
                      <img
                        alt="Background2694"
                        src="/external/background2694-fewi-200h.png"
                        className="podstrona-grafikbutton-background4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="podstrona-grafikbutton-footer">
              <button className="podstrona-grafikbutton-button12">
                <span className="podstrona-grafikbutton-text148 pro.fiverr.comArialBold16">
                  <span>Dodaj do filtrów</span>
                </span>
              </button>
            </div>
          </div>
          <div className="podstrona-grafikbutton-pagination-list1">
            <div className="podstrona-grafikbutton-pagination-page10">
              <span className="podstrona-grafikbutton-text150 SingleLineBodyBase">
                1
              </span>
            </div>
            <div className="podstrona-grafikbutton-pagination-page11">
              <span className="podstrona-grafikbutton-text151 SingleLineBodyBase">
                2
              </span>
            </div>
            <div className="podstrona-grafikbutton-pagination-page12">
              <span className="podstrona-grafikbutton-text152 SingleLineBodyBase">
                3
              </span>
            </div>
            <div className="podstrona-grafikbutton-pagination-gap1">
              <span className="podstrona-grafikbutton-text153 BodyBaseBold">
                <span>...</span>
              </span>
            </div>
            <div className="podstrona-grafikbutton-pagination-page13">
              <span className="podstrona-grafikbutton-text155 SingleLineBodyBase">
                <span>67</span>
              </span>
            </div>
            <div className="podstrona-grafikbutton-pagination-page14">
              <span className="podstrona-grafikbutton-text157 SingleLineBodyBase">
                <span>68</span>
              </span>
            </div>
          </div>
          <button className="podstrona-grafikbutton-button13">
            <span className="podstrona-grafikbutton-text159 SingleLineBodyBase">
              <span>Od najnowsze....</span>
            </span>
          </button>
          <span className="podstrona-grafikbutton-text161">
            <span>Sortowanie</span>
          </span>
          <div className="podstrona-grafikbutton-group91">
            <img
              alt="Line42794"
              src="/external/line42794-3xo.svg"
              className="podstrona-grafikbutton-line4"
            />
            <img
              alt="Line52794"
              src="/external/line52794-brcs.svg"
              className="podstrona-grafikbutton-line5"
            />
          </div>
          <div className="podstrona-grafikbutton-pagination">
            <div className="podstrona-grafikbutton-pagination-previous">
              <img
                alt="ArrowleftI279"
                src="/external/arrowlefti279-xbv.svg"
                className="podstrona-grafikbutton-arrowleft"
              />
              <span className="podstrona-grafikbutton-text163 SingleLineBodyBase">
                <span>Previous</span>
              </span>
            </div>
            <div className="podstrona-grafikbutton-pagination-list2">
              <div className="podstrona-grafikbutton-pagination-page15">
                <span className="podstrona-grafikbutton-text165 SingleLineBodyBase">
                  1
                </span>
              </div>
              <div className="podstrona-grafikbutton-pagination-page16">
                <span className="podstrona-grafikbutton-text166 SingleLineBodyBase">
                  2
                </span>
              </div>
              <div className="podstrona-grafikbutton-pagination-page17">
                <span className="podstrona-grafikbutton-text167 SingleLineBodyBase">
                  3
                </span>
              </div>
              <div className="podstrona-grafikbutton-pagination-gap2">
                <span className="podstrona-grafikbutton-text168 BodyBaseBold">
                  <span>...</span>
                </span>
              </div>
              <div className="podstrona-grafikbutton-pagination-page18">
                <span className="podstrona-grafikbutton-text170 SingleLineBodyBase">
                  <span>67</span>
                </span>
              </div>
              <div className="podstrona-grafikbutton-pagination-page19">
                <span className="podstrona-grafikbutton-text172 SingleLineBodyBase">
                  <span>68</span>
                </span>
              </div>
            </div>
            <div className="podstrona-grafikbutton-pagination-next">
              <span className="podstrona-grafikbutton-text174 SingleLineBodyBase">
                <span>Next</span>
              </span>
              <img
                alt="ArrowrightI279"
                src="/external/arrowrighti279-b0p.svg"
                className="podstrona-grafikbutton-arrowright"
              />
            </div>
          </div>
          <div className="podstrona-grafikbutton-group143">
            <div className="podstrona-grafikbutton-group1351">
              <div className="podstrona-grafikbutton-group12910">
                <div className="podstrona-grafikbutton-frame1401">
                  <img
                    alt="Frame1322694"
                    src="/external/frame1322694-kkat.svg"
                    className="podstrona-grafikbutton-frame1321"
                  />
                </div>
                <div className="podstrona-grafikbutton-frame1391">
                  <span className="podstrona-grafikbutton-text176">
                    <span>Wykonam Dla ciebie Dowolne logo!</span>
                  </span>
                  <span className="podstrona-grafikbutton-text178">
                    <span>Ceny Od: 500 PLN</span>
                  </span>
                  <div className="podstrona-grafikbutton-group1251">
                    <div className="podstrona-grafikbutton-frame1381">
                      <img
                        alt="image232724"
                        src="/external/image232724-s96t-200h.png"
                        className="podstrona-grafikbutton-image231"
                      />
                      <img
                        alt="image242724"
                        src="/external/image242724-0ce9-200h.png"
                        className="podstrona-grafikbutton-image241"
                      />
                      <img
                        alt="image252724"
                        src="/external/image252724-rkfm-200h.png"
                        className="podstrona-grafikbutton-image251"
                      />
                    </div>
                    <div className="podstrona-grafikbutton-frame1311">
                      <span className="podstrona-grafikbutton-text180">
                        <span>Zobacz Portfolio</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="podstrona-grafikbutton-frame1451">
                  <div className="podstrona-grafikbutton-group1421">
                    <button className="podstrona-grafikbutton-button14"></button>
                    <div className="podstrona-grafikbutton-frame13610">
                      <span className="podstrona-grafikbutton-text182">
                        <span>Od 3 do 7 dni roboczych</span>
                      </span>
                      <span className="podstrona-grafikbutton-text184">
                        <span>Czas realizacji</span>
                      </span>
                    </div>
                  </div>
                  <div className="podstrona-grafikbutton-group1411">
                    <button className="podstrona-grafikbutton-button15"></button>
                    <div className="podstrona-grafikbutton-frame13611">
                      <span className="podstrona-grafikbutton-text186">
                        <span>Liczba poprawek</span>
                      </span>
                      <span className="podstrona-grafikbutton-text188">
                        <span>Do 3 poprawek w cenie</span>
                      </span>
                    </div>
                  </div>
                  <div className="podstrona-grafikbutton-group1401">
                    <button className="podstrona-grafikbutton-button16"></button>
                    <div className="podstrona-grafikbutton-frame13612">
                      <span className="podstrona-grafikbutton-text190">
                        <span>Formaty plików</span>
                      </span>
                      <span className="podstrona-grafikbutton-text192">
                        <span>JPG, PNG, SVG, AI</span>
                      </span>
                    </div>
                  </div>
                </div>
                <Link
                  to="/profil-uytkownika-ommiebutton"
                  className="podstrona-grafikbutton-navlink4"
                >
                  <div className="podstrona-grafikbutton-group12610">
                    <img
                      alt="image212697"
                      src="/external/image212697-lql-200h.png"
                      className="podstrona-grafikbutton-image211"
                    />
                    <div className="podstrona-grafikbutton-frame1301">
                      <span className="podstrona-grafikbutton-text194">
                        <span>Maria Maria Monika</span>
                      </span>
                      <span className="podstrona-grafikbutton-text196">
                        <span>Grafik Komputerowy</span>
                      </span>
                    </div>
                    <div className="podstrona-grafikbutton-frame1351">
                      <div className="podstrona-grafikbutton-group1161">
                        <span className="podstrona-grafikbutton-text198">
                          <span>LOGO</span>
                        </span>
                      </div>
                      <div className="podstrona-grafikbutton-group861">
                        <span className="podstrona-grafikbutton-text200">
                          <span>Mascot</span>
                        </span>
                      </div>
                      <div className="podstrona-grafikbutton-group841">
                        <span className="podstrona-grafikbutton-text202">
                          <span>LOGO</span>
                        </span>
                      </div>
                      <div className="podstrona-grafikbutton-group871">
                        <span className="podstrona-grafikbutton-text204">
                          <span>Design grafika</span>
                        </span>
                      </div>
                    </div>
                    <img
                      alt="Ellipse252794"
                      src="/external/ellipse252794-z8bu-200h.png"
                      className="podstrona-grafikbutton-ellipse251"
                    />
                  </div>
                </Link>
              </div>
              <div className="podstrona-grafikbutton-group12911">
                <div className="podstrona-grafikbutton-group12611">
                  <div className="podstrona-grafikbutton-group1211">
                    <div className="podstrona-grafikbutton-group1201">
                      <div className="podstrona-grafikbutton-group1181">
                        <img
                          alt="Vector2694"
                          src="/external/vector2694-gifr.svg"
                          className="podstrona-grafikbutton-vector3"
                        />
                        <span className="podstrona-grafikbutton-text206 pro.fiverr.comArialBold16">
                          <span>9.9</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="podstrona-grafikbutton-button17">
              <span className="podstrona-grafikbutton-text208 SingleLineBodyBase">
                <span>Złóż Ofertę</span>
              </span>
            </button>
          </div>
          <div className="podstrona-grafikbutton-group144">
            <div className="podstrona-grafikbutton-group1352">
              <div className="podstrona-grafikbutton-group12912">
                <div className="podstrona-grafikbutton-frame1402">
                  <img
                    alt="Frame1323077"
                    src="/external/frame1323077-y87p.svg"
                    className="podstrona-grafikbutton-frame1322"
                  />
                </div>
                <div className="podstrona-grafikbutton-frame1392">
                  <span className="podstrona-grafikbutton-text210">
                    <span>Wykonam Dla ciebie Dowolne logo!</span>
                  </span>
                  <span className="podstrona-grafikbutton-text212">
                    <span>Ceny Od: 500 PLN</span>
                  </span>
                  <div className="podstrona-grafikbutton-group1252">
                    <div className="podstrona-grafikbutton-frame1382">
                      <img
                        alt="image233078"
                        src="/external/image233078-1o58-200h.png"
                        className="podstrona-grafikbutton-image232"
                      />
                      <img
                        alt="image243078"
                        src="/external/image243078-b8lh-200h.png"
                        className="podstrona-grafikbutton-image242"
                      />
                      <img
                        alt="image253078"
                        src="/external/image253078-zlhi-200h.png"
                        className="podstrona-grafikbutton-image252"
                      />
                    </div>
                    <div className="podstrona-grafikbutton-frame1312">
                      <span className="podstrona-grafikbutton-text214">
                        <span>Zobacz Portfolio</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="podstrona-grafikbutton-frame1452">
                  <div className="podstrona-grafikbutton-group1422">
                    <button className="podstrona-grafikbutton-button18"></button>
                    <div className="podstrona-grafikbutton-frame13613">
                      <span className="podstrona-grafikbutton-text216">
                        <span>Od 3 do 7 dni roboczych</span>
                      </span>
                      <span className="podstrona-grafikbutton-text218">
                        <span>Czas realizacji</span>
                      </span>
                    </div>
                  </div>
                  <div className="podstrona-grafikbutton-group1412">
                    <button className="podstrona-grafikbutton-button19"></button>
                    <div className="podstrona-grafikbutton-frame13614">
                      <span className="podstrona-grafikbutton-text220">
                        <span>Liczba poprawek</span>
                      </span>
                      <span className="podstrona-grafikbutton-text222">
                        <span>Do 3 poprawek w cenie</span>
                      </span>
                    </div>
                  </div>
                  <div className="podstrona-grafikbutton-group1402">
                    <button className="podstrona-grafikbutton-button20"></button>
                    <div className="podstrona-grafikbutton-frame13615">
                      <span className="podstrona-grafikbutton-text224">
                        <span>Formaty plików</span>
                      </span>
                      <span className="podstrona-grafikbutton-text226">
                        <span>JPG, PNG, SVG, AI</span>
                      </span>
                    </div>
                  </div>
                </div>
                <Link
                  to="/profil-uytkownika-ommiebutton"
                  className="podstrona-grafikbutton-navlink5"
                >
                  <div className="podstrona-grafikbutton-group12612">
                    <img
                      alt="image213078"
                      src="/external/image213078-4cc-200h.png"
                      className="podstrona-grafikbutton-image212"
                    />
                    <div className="podstrona-grafikbutton-frame1302">
                      <span className="podstrona-grafikbutton-text228">
                        <span>Maria Maria Monika</span>
                      </span>
                      <span className="podstrona-grafikbutton-text230">
                        <span>Grafik Komputerowy</span>
                      </span>
                    </div>
                    <div className="podstrona-grafikbutton-frame1352">
                      <div className="podstrona-grafikbutton-group1162">
                        <span className="podstrona-grafikbutton-text232">
                          <span>LOGO</span>
                        </span>
                      </div>
                      <div className="podstrona-grafikbutton-group862">
                        <span className="podstrona-grafikbutton-text234">
                          <span>Mascot</span>
                        </span>
                      </div>
                      <div className="podstrona-grafikbutton-group842">
                        <span className="podstrona-grafikbutton-text236">
                          <span>LOGO</span>
                        </span>
                      </div>
                      <div className="podstrona-grafikbutton-group872">
                        <span className="podstrona-grafikbutton-text238">
                          <span>Design grafika</span>
                        </span>
                      </div>
                    </div>
                    <img
                      alt="Ellipse253078"
                      src="/external/ellipse253078-fi65-200h.png"
                      className="podstrona-grafikbutton-ellipse252"
                    />
                  </div>
                </Link>
              </div>
              <div className="podstrona-grafikbutton-group12913">
                <div className="podstrona-grafikbutton-group12613">
                  <div className="podstrona-grafikbutton-group1212">
                    <div className="podstrona-grafikbutton-group1202">
                      <div className="podstrona-grafikbutton-group1182">
                        <img
                          alt="Vector3078"
                          src="/external/vector3078-m0fi.svg"
                          className="podstrona-grafikbutton-vector4"
                        />
                        <span className="podstrona-grafikbutton-text240 pro.fiverr.comArialBold16">
                          <span>9.9</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="podstrona-grafikbutton-button21">
              <span className="podstrona-grafikbutton-text242 SingleLineBodyBase">
                <span>Złóż Ofertę</span>
              </span>
            </button>
          </div>
          <div className="podstrona-grafikbutton-group145">
            <div className="podstrona-grafikbutton-group1353">
              <div className="podstrona-grafikbutton-group12914">
                <div className="podstrona-grafikbutton-frame1403">
                  <img
                    alt="Frame1323078"
                    src="/external/frame1323078-ywl.svg"
                    className="podstrona-grafikbutton-frame1323"
                  />
                </div>
                <div className="podstrona-grafikbutton-frame1393">
                  <span className="podstrona-grafikbutton-text244">
                    <span>Wykonam Dla ciebie Dowolne logo!</span>
                  </span>
                  <span className="podstrona-grafikbutton-text246">
                    <span>Ceny Od: 500 PLN</span>
                  </span>
                  <div className="podstrona-grafikbutton-group1253">
                    <div className="podstrona-grafikbutton-frame1383">
                      <img
                        alt="image233078"
                        src="/external/image233078-7mqy-200h.png"
                        className="podstrona-grafikbutton-image233"
                      />
                      <img
                        alt="image243078"
                        src="/external/image243078-h2r7-200h.png"
                        className="podstrona-grafikbutton-image243"
                      />
                      <img
                        alt="image253078"
                        src="/external/image253078-1gki-200h.png"
                        className="podstrona-grafikbutton-image253"
                      />
                    </div>
                    <div className="podstrona-grafikbutton-frame1313">
                      <span className="podstrona-grafikbutton-text248">
                        <span>Zobacz Portfolio</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="podstrona-grafikbutton-frame1453">
                  <div className="podstrona-grafikbutton-group1423">
                    <button className="podstrona-grafikbutton-button22"></button>
                    <div className="podstrona-grafikbutton-frame13616">
                      <span className="podstrona-grafikbutton-text250">
                        <span>Od 3 do 7 dni roboczych</span>
                      </span>
                      <span className="podstrona-grafikbutton-text252">
                        <span>Czas realizacji</span>
                      </span>
                    </div>
                  </div>
                  <div className="podstrona-grafikbutton-group1413">
                    <button className="podstrona-grafikbutton-button23"></button>
                    <div className="podstrona-grafikbutton-frame13617">
                      <span className="podstrona-grafikbutton-text254">
                        <span>Liczba poprawek</span>
                      </span>
                      <span className="podstrona-grafikbutton-text256">
                        <span>Do 3 poprawek w cenie</span>
                      </span>
                    </div>
                  </div>
                  <div className="podstrona-grafikbutton-group1403">
                    <button className="podstrona-grafikbutton-button24"></button>
                    <div className="podstrona-grafikbutton-frame13618">
                      <span className="podstrona-grafikbutton-text258">
                        <span>Formaty plików</span>
                      </span>
                      <span className="podstrona-grafikbutton-text260">
                        <span>JPG, PNG, SVG, AI</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="podstrona-grafikbutton-group12614">
                  <img
                    alt="image213078"
                    src="/external/image213078-isji-200h.png"
                    className="podstrona-grafikbutton-image213"
                  />
                  <div className="podstrona-grafikbutton-frame1303">
                    <span className="podstrona-grafikbutton-text262">
                      <span>Maria Maria Monika</span>
                    </span>
                    <span className="podstrona-grafikbutton-text264">
                      <span>Grafik Komputerowy</span>
                    </span>
                  </div>
                  <div className="podstrona-grafikbutton-frame1353">
                    <div className="podstrona-grafikbutton-group1163">
                      <span className="podstrona-grafikbutton-text266">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="podstrona-grafikbutton-group863">
                      <span className="podstrona-grafikbutton-text268">
                        <span>Mascot</span>
                      </span>
                    </div>
                    <div className="podstrona-grafikbutton-group843">
                      <span className="podstrona-grafikbutton-text270">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="podstrona-grafikbutton-group873">
                      <span className="podstrona-grafikbutton-text272">
                        <span>Design grafika</span>
                      </span>
                    </div>
                  </div>
                  <img
                    alt="Ellipse253079"
                    src="/external/ellipse253079-8s86-200h.png"
                    className="podstrona-grafikbutton-ellipse253"
                  />
                </div>
              </div>
              <div className="podstrona-grafikbutton-group12915">
                <div className="podstrona-grafikbutton-group12615">
                  <div className="podstrona-grafikbutton-group1213">
                    <div className="podstrona-grafikbutton-group1203">
                      <div className="podstrona-grafikbutton-group1183">
                        <img
                          alt="Vector3079"
                          src="/external/vector3079-n4v6.svg"
                          className="podstrona-grafikbutton-vector5"
                        />
                        <span className="podstrona-grafikbutton-text274 pro.fiverr.comArialBold16">
                          <span>9.9</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="podstrona-grafikbutton-button25">
              <span className="podstrona-grafikbutton-text276 SingleLineBodyBase">
                <span>Złóż Ofertę</span>
              </span>
            </button>
          </div>
          <div className="podstrona-grafikbutton-group146">
            <div className="podstrona-grafikbutton-group1354">
              <div className="podstrona-grafikbutton-group12916">
                <div className="podstrona-grafikbutton-frame1404">
                  <img
                    alt="Frame1323079"
                    src="/external/frame1323079-c02g.svg"
                    className="podstrona-grafikbutton-frame1324"
                  />
                </div>
                <div className="podstrona-grafikbutton-frame1394">
                  <span className="podstrona-grafikbutton-text278">
                    <span>Wykonam Dla ciebie Dowolne logo!</span>
                  </span>
                  <span className="podstrona-grafikbutton-text280">
                    <span>Ceny Od: 500 PLN</span>
                  </span>
                  <div className="podstrona-grafikbutton-group1254">
                    <div className="podstrona-grafikbutton-frame1384">
                      <img
                        alt="image233079"
                        src="/external/image233079-62ln-200h.png"
                        className="podstrona-grafikbutton-image234"
                      />
                      <img
                        alt="image243079"
                        src="/external/image243079-pqh-200h.png"
                        className="podstrona-grafikbutton-image244"
                      />
                      <img
                        alt="image253079"
                        src="/external/image253079-nyud-200h.png"
                        className="podstrona-grafikbutton-image254"
                      />
                    </div>
                    <div className="podstrona-grafikbutton-frame1314">
                      <span className="podstrona-grafikbutton-text282">
                        <span>Zobacz Portfolio</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="podstrona-grafikbutton-frame1454">
                  <div className="podstrona-grafikbutton-group1424">
                    <button className="podstrona-grafikbutton-button26"></button>
                    <div className="podstrona-grafikbutton-frame13619">
                      <span className="podstrona-grafikbutton-text284">
                        <span>Od 3 do 7 dni roboczych</span>
                      </span>
                      <span className="podstrona-grafikbutton-text286">
                        <span>Czas realizacji</span>
                      </span>
                    </div>
                  </div>
                  <div className="podstrona-grafikbutton-group1414">
                    <button className="podstrona-grafikbutton-button27"></button>
                    <div className="podstrona-grafikbutton-frame13620">
                      <span className="podstrona-grafikbutton-text288">
                        <span>Liczba poprawek</span>
                      </span>
                      <span className="podstrona-grafikbutton-text290">
                        <span>Do 3 poprawek w cenie</span>
                      </span>
                    </div>
                  </div>
                  <div className="podstrona-grafikbutton-group1404">
                    <button className="podstrona-grafikbutton-button28"></button>
                    <div className="podstrona-grafikbutton-frame13621">
                      <span className="podstrona-grafikbutton-text292">
                        <span>Formaty plików</span>
                      </span>
                      <span className="podstrona-grafikbutton-text294">
                        <span>JPG, PNG, SVG, AI</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="podstrona-grafikbutton-group12616">
                  <img
                    alt="image213079"
                    src="/external/image213079-t6a-200h.png"
                    className="podstrona-grafikbutton-image214"
                  />
                  <div className="podstrona-grafikbutton-frame1304">
                    <span className="podstrona-grafikbutton-text296">
                      <span>Maria Maria Monika</span>
                    </span>
                    <span className="podstrona-grafikbutton-text298">
                      <span>Grafik Komputerowy</span>
                    </span>
                  </div>
                  <div className="podstrona-grafikbutton-frame1354">
                    <div className="podstrona-grafikbutton-group1164">
                      <span className="podstrona-grafikbutton-text300">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="podstrona-grafikbutton-group864">
                      <span className="podstrona-grafikbutton-text302">
                        <span>Mascot</span>
                      </span>
                    </div>
                    <div className="podstrona-grafikbutton-group844">
                      <span className="podstrona-grafikbutton-text304">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="podstrona-grafikbutton-group874">
                      <span className="podstrona-grafikbutton-text306">
                        <span>Design grafika</span>
                      </span>
                    </div>
                  </div>
                  <img
                    alt="Ellipse253079"
                    src="/external/ellipse253079-4pdr-200h.png"
                    className="podstrona-grafikbutton-ellipse254"
                  />
                </div>
              </div>
              <div className="podstrona-grafikbutton-group12917">
                <div className="podstrona-grafikbutton-group12617">
                  <div className="podstrona-grafikbutton-group1214">
                    <div className="podstrona-grafikbutton-group1204">
                      <div className="podstrona-grafikbutton-group1184">
                        <img
                          alt="Vector3079"
                          src="/external/vector3079-t2i9.svg"
                          className="podstrona-grafikbutton-vector6"
                        />
                        <span className="podstrona-grafikbutton-text308 pro.fiverr.comArialBold16">
                          <span>9.9</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="podstrona-grafikbutton-button29">
              <span className="podstrona-grafikbutton-text310 SingleLineBodyBase">
                <span>Złóż Ofertę</span>
              </span>
            </button>
          </div>
          <div className="podstrona-grafikbutton-group147">
            <div className="podstrona-grafikbutton-group1355">
              <div className="podstrona-grafikbutton-group12918">
                <div className="podstrona-grafikbutton-frame1405">
                  <img
                    alt="Frame1323071"
                    src="/external/frame1323071-wekm.svg"
                    className="podstrona-grafikbutton-frame1325"
                  />
                </div>
                <div className="podstrona-grafikbutton-frame1395">
                  <span className="podstrona-grafikbutton-text312">
                    <span>Wykonam Dla ciebie Dowolne logo!</span>
                  </span>
                  <span className="podstrona-grafikbutton-text314">
                    <span>Ceny Od: 500 PLN</span>
                  </span>
                  <div className="podstrona-grafikbutton-group1255">
                    <div className="podstrona-grafikbutton-frame1385">
                      <img
                        alt="image233071"
                        src="/external/image233071-1jtp-200h.png"
                        className="podstrona-grafikbutton-image235"
                      />
                      <img
                        alt="image243071"
                        src="/external/image243071-79vo-200h.png"
                        className="podstrona-grafikbutton-image245"
                      />
                      <img
                        alt="image253071"
                        src="/external/image253071-hd6t-200h.png"
                        className="podstrona-grafikbutton-image255"
                      />
                    </div>
                    <div className="podstrona-grafikbutton-frame1315">
                      <span className="podstrona-grafikbutton-text316">
                        <span>Zobacz Portfolio</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="podstrona-grafikbutton-frame1455">
                  <div className="podstrona-grafikbutton-group1425">
                    <button className="podstrona-grafikbutton-button30"></button>
                    <div className="podstrona-grafikbutton-frame13622">
                      <span className="podstrona-grafikbutton-text318">
                        <span>Od 3 do 7 dni roboczych</span>
                      </span>
                      <span className="podstrona-grafikbutton-text320">
                        <span>Czas realizacji</span>
                      </span>
                    </div>
                  </div>
                  <div className="podstrona-grafikbutton-group1415">
                    <button className="podstrona-grafikbutton-button31"></button>
                    <div className="podstrona-grafikbutton-frame13623">
                      <span className="podstrona-grafikbutton-text322">
                        <span>Liczba poprawek</span>
                      </span>
                      <span className="podstrona-grafikbutton-text324">
                        <span>Do 3 poprawek w cenie</span>
                      </span>
                    </div>
                  </div>
                  <div className="podstrona-grafikbutton-group1405">
                    <button className="podstrona-grafikbutton-button32"></button>
                    <div className="podstrona-grafikbutton-frame13624">
                      <span className="podstrona-grafikbutton-text326">
                        <span>Formaty plików</span>
                      </span>
                      <span className="podstrona-grafikbutton-text328">
                        <span>JPG, PNG, SVG, AI</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="podstrona-grafikbutton-group12618">
                  <img
                    alt="image213071"
                    src="/external/image213071-s1fq-200h.png"
                    className="podstrona-grafikbutton-image215"
                  />
                  <div className="podstrona-grafikbutton-frame1305">
                    <span className="podstrona-grafikbutton-text330">
                      <span>Maria Maria Monika</span>
                    </span>
                    <span className="podstrona-grafikbutton-text332">
                      <span>Grafik Komputerowy</span>
                    </span>
                  </div>
                  <div className="podstrona-grafikbutton-frame1355">
                    <div className="podstrona-grafikbutton-group1165">
                      <span className="podstrona-grafikbutton-text334">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="podstrona-grafikbutton-group865">
                      <span className="podstrona-grafikbutton-text336">
                        <span>Mascot</span>
                      </span>
                    </div>
                    <div className="podstrona-grafikbutton-group845">
                      <span className="podstrona-grafikbutton-text338">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="podstrona-grafikbutton-group875">
                      <span className="podstrona-grafikbutton-text340">
                        <span>Design grafika</span>
                      </span>
                    </div>
                  </div>
                  <img
                    alt="Ellipse253071"
                    src="/external/ellipse253071-7yu-200h.png"
                    className="podstrona-grafikbutton-ellipse255"
                  />
                </div>
              </div>
              <div className="podstrona-grafikbutton-group12919">
                <div className="podstrona-grafikbutton-group12619">
                  <div className="podstrona-grafikbutton-group1215">
                    <div className="podstrona-grafikbutton-group1205">
                      <div className="podstrona-grafikbutton-group1185">
                        <img
                          alt="Vector3071"
                          src="/external/vector3071-gq9p.svg"
                          className="podstrona-grafikbutton-vector7"
                        />
                        <span className="podstrona-grafikbutton-text342 pro.fiverr.comArialBold16">
                          <span>9.9</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="podstrona-grafikbutton-button33">
              <span className="podstrona-grafikbutton-text344 SingleLineBodyBase">
                <span>Złóż Ofertę</span>
              </span>
            </button>
          </div>
          <div className="podstrona-grafikbutton-hero-heading-left">
            <div className="podstrona-grafikbutton-container42">
              <div className="podstrona-grafikbutton-column1">
                <div className="podstrona-grafikbutton-content">
                  <span className="podstrona-grafikbutton-text346">
                    <span>Title Copy Goes Here Be Awesome</span>
                  </span>
                  <span className="podstrona-grafikbutton-text348">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in eros elementum tristique. Duis
                      cursus, mi quis viverra ornare, eros dolor interdum nulla,
                      ut commodo diam libero vitae erat.
                    </span>
                  </span>
                </div>
                <div className="podstrona-grafikbutton-actions">
                  <button className="podstrona-grafikbutton-button34">
                    <span className="podstrona-grafikbutton-text350">
                      <span>GET STARTED</span>
                    </span>
                  </button>
                </div>
              </div>
              <div className="podstrona-grafikbutton-column2">
                <div className="podstrona-grafikbutton-image-wrapper">
                  <div className="podstrona-grafikbutton-image"></div>
                </div>
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default PodstronaGrafikbutton
