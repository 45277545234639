import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import USTAWIENIAKONTAbutton from './views/u-stawieniakont-abutton'
import ProfilUytkownikaOpiniebutton from './views/profil-uytkownika-opiniebutton'
import OKNOoddajzleceniebutton from './views/okn-ooddajzleceniebutton'
import ProfilUytkownikaOmmiebutton from './views/profil-uytkownika-ommiebutton'
import Desktop1button from './views/desktop1button'
import OKNOZOOFERTbutton from './views/oknozoofer-tbutton'
import TRWAJCEZLECENIAbutton from './views/t-rwajcezleceni-abutton'
import LOGOWANIEbutton from './views/logowani-ebutton'
import Zamwieniabutton from './views/zamwieniabutton'
import PodstronaGrafikbutton from './views/podstrona-grafikbutton'
import ProfilUytkownikaWykonanezleceniabutton from './views/profil-uytkownika-wykonanezleceniabutton'
import OKNOBRIFFotwartybutton from './views/oknobrif-fotwartybutton'
import ProfilUytkownikaPortfoliobutton from './views/profil-uytkownika-portfoliobutton'
import Wiadomosci from './views/wiadomosci'
import WIDOKCZATUbutton from './views/widokczat-ubutton'
import Communityartystycznebutton from './views/communityartystycznebutton'
import TRWAJCEZLECENIAbutton1 from './views/t-rwajcezleceni-abutton1'
import EkranZleceotwartychbutton from './views/ekran-zleceotwartychbutton'
import WIDOKCZATU3button from './views/widokczatu3button'
import OKNOPOWIADOMIEbutton from './views/oknopowiadomi-ebutton'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route
          component={USTAWIENIAKONTAbutton}
          exact
          path="/u-stawieniakont-abutton"
        />
        <Route
          component={ProfilUytkownikaOpiniebutton}
          exact
          path="/profil-uytkownika-opiniebutton"
        />
        <Route
          component={OKNOoddajzleceniebutton}
          exact
          path="/okn-ooddajzleceniebutton"
        />
        <Route
          component={ProfilUytkownikaOmmiebutton}
          exact
          path="/profil-uytkownika-ommiebutton"
        />
        <Route component={Desktop1button} exact path="/" />
        <Route component={OKNOZOOFERTbutton} exact path="/oknozoofer-tbutton" />
        <Route
          component={TRWAJCEZLECENIAbutton}
          exact
          path="/t-rwajcezleceni-abutton"
        />
        <Route component={LOGOWANIEbutton} exact path="/logowani-ebutton" />
        <Route component={Zamwieniabutton} exact path="/zamwieniabutton" />
        <Route
          component={PodstronaGrafikbutton}
          exact
          path="/podstrona-grafikbutton"
        />
        <Route
          component={ProfilUytkownikaWykonanezleceniabutton}
          exact
          path="/profil-uytkownika-wykonanezleceniabutton"
        />
        <Route
          component={OKNOBRIFFotwartybutton}
          exact
          path="/oknobrif-fotwartybutton"
        />
        <Route
          component={ProfilUytkownikaPortfoliobutton}
          exact
          path="/profil-uytkownika-portfoliobutton"
        />
        <Route component={Wiadomosci} exact path="/wiadomosci" />
        <Route component={WIDOKCZATUbutton} exact path="/widokczat-ubutton" />
        <Route
          component={Communityartystycznebutton}
          exact
          path="/communityartystycznebutton"
        />
        <Route
          component={TRWAJCEZLECENIAbutton1}
          exact
          path="/t-rwajcezleceni-abutton1"
        />
        <Route
          component={EkranZleceotwartychbutton}
          exact
          path="/ekran-zleceotwartychbutton"
        />
        <Route component={WIDOKCZATU3button} exact path="/widokczatu3button" />
        <Route
          component={OKNOPOWIADOMIEbutton}
          exact
          path="/oknopowiadomi-ebutton"
        />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
