import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './profil-uytkownika-portfoliobutton.css'

const ProfilUytkownikaPortfoliobutton = (props) => {
  return (
    <div className="profil-uytkownika-portfoliobutton-container1">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="profil-uytkownika-portfoliobutton-dummy-container">
        <button className="profil-uytkownika-portfoliobutton-profil-uytkownika-portfoliobutton">
          <img
            alt="Rectangle1153456"
            src="/external/rectangle1153456-6xja-900w.png"
            className="profil-uytkownika-portfoliobutton-rectangle115"
          />
          <div className="profil-uytkownika-portfoliobutton-group115">
            <div className="profil-uytkownika-portfoliobutton-frame1111">
              <Link
                to="/"
                className="profil-uytkownika-portfoliobutton-navlink1"
              >
                <img
                  alt="LGOOROBOCZEV00112791"
                  src="/external/lgooroboczev00112791-ti92-200h.png"
                  className="profil-uytkownika-portfoliobutton-lgooroboczev0011"
                />
              </Link>
              <div className="profil-uytkownika-portfoliobutton-group114">
                <div className="profil-uytkownika-portfoliobutton-input">
                  <div className="profil-uytkownika-portfoliobutton-container2">
                    <span className="profil-uytkownika-portfoliobutton-text100">
                      <span>Czego szukasz towarzyszu?</span>
                    </span>
                  </div>
                </div>
                <button className="profil-uytkownika-portfoliobutton-button1">
                  <img
                    alt="SVG2791"
                    src="/external/svg2791-hs3.svg"
                    className="profil-uytkownika-portfoliobutton-svg"
                  />
                </button>
              </div>
              <button className="profil-uytkownika-portfoliobutton-item-button">
                <div className="profil-uytkownika-portfoliobutton-group112">
                  <Link
                    to="/communityartystycznebutton"
                    className="profil-uytkownika-portfoliobutton-text102"
                  >
                    <span>Społeczność Artystyczna</span>
                  </Link>
                  <img
                    alt="Vector2791"
                    src="/external/vector2791-ddgl.svg"
                    className="profil-uytkownika-portfoliobutton-vector1"
                  />
                </div>
              </button>
              <div className="profil-uytkownika-portfoliobutton-group113">
                <Link
                  to="/communityartystycznebutton"
                  className="profil-uytkownika-portfoliobutton-text104"
                >
                  <span>Q&amp;A</span>
                </Link>
                <img
                  alt="Vector2791"
                  src="/external/vector2791-2o8f.svg"
                  className="profil-uytkownika-portfoliobutton-vector2"
                />
              </div>
              <div className="profil-uytkownika-portfoliobutton-item-link1">
                <Link
                  to="/logowani-ebutton"
                  className="profil-uytkownika-portfoliobutton-text106"
                >
                  <span>Sign in</span>
                </Link>
              </div>
              <Link
                to="/logowani-ebutton"
                className="profil-uytkownika-portfoliobutton-navlink2"
              >
                <div className="profil-uytkownika-portfoliobutton-item-link2">
                  <span className="profil-uytkownika-portfoliobutton-text108">
                    <span>Join</span>
                  </span>
                </div>
              </Link>
            </div>
          </div>
          <img
            alt="Rectangle1012791"
            src="/external/rectangle1012791-ml5p-200h.png"
            className="profil-uytkownika-portfoliobutton-rectangle101"
          />
          <Link
            to="/podstrona-grafikbutton"
            className="profil-uytkownika-portfoliobutton-navlink3"
          >
            <div className="profil-uytkownika-portfoliobutton-frame1112">
              <span className="profil-uytkownika-portfoliobutton-text110">
                <span>Cosplay</span>
              </span>
              <span className="profil-uytkownika-portfoliobutton-text112">
                <span>Pisarz</span>
              </span>
              <span className="profil-uytkownika-portfoliobutton-text114">
                <span>Muzyka&amp;audio</span>
              </span>
              <span className="profil-uytkownika-portfoliobutton-text116">
                <span>Rysownik</span>
              </span>
              <span className="profil-uytkownika-portfoliobutton-text118">
                <span>UI/Ux Design</span>
              </span>
              <span className="profil-uytkownika-portfoliobutton-text120">
                <span>Grafika Komputerowa</span>
              </span>
            </div>
          </Link>
          <img
            alt="Rectangle1032791"
            src="/external/rectangle1032791-6o9d-200h.png"
            className="profil-uytkownika-portfoliobutton-rectangle103"
          />
          <div className="profil-uytkownika-portfoliobutton-frame188">
            <span className="profil-uytkownika-portfoliobutton-text122">
              <span>Profil Artysty</span>
            </span>
            <div className="profil-uytkownika-portfoliobutton-frame182">
              <img
                alt="Frame1323455"
                src="/external/frame1323455-3qkd.svg"
                className="profil-uytkownika-portfoliobutton-frame132"
              />
              <button className="profil-uytkownika-portfoliobutton-button2">
                <img
                  alt="SendI345"
                  src="/external/sendi345-dbl.svg"
                  className="profil-uytkownika-portfoliobutton-send"
                />
                <span className="profil-uytkownika-portfoliobutton-text124 SingleLineBodyBase">
                  <span>Wyślij Zlecenie</span>
                </span>
              </button>
            </div>
          </div>
          <div className="profil-uytkownika-portfoliobutton-group163">
            <span className="profil-uytkownika-portfoliobutton-text126">
              <span>Portfolio</span>
            </span>
            <div className="profil-uytkownika-portfoliobutton-frame187">
              <button className="profil-uytkownika-portfoliobutton-button3">
                <span className="profil-uytkownika-portfoliobutton-text128">
                  <span>Wybierz Tagi</span>
                </span>
                <img
                  alt="ArrowdownI345"
                  src="/external/arrowdowni345-w73.svg"
                  className="profil-uytkownika-portfoliobutton-arrowdown"
                />
              </button>
              <button className="profil-uytkownika-portfoliobutton-button4">
                <span className="profil-uytkownika-portfoliobutton-text130">
                  <span>LOGOTYPY</span>
                </span>
                <img
                  alt="XI345"
                  src="/external/xi345-wlzp.svg"
                  className="profil-uytkownika-portfoliobutton-x"
                />
              </button>
            </div>
            <div className="profil-uytkownika-portfoliobutton-frame186">
              <div className="profil-uytkownika-portfoliobutton-group156">
                <div className="profil-uytkownika-portfoliobutton-frame1851">
                  <div className="profil-uytkownika-portfoliobutton-frame1841">
                    <div className="profil-uytkownika-portfoliobutton-frame1831">
                      <img
                        alt="dfgg13254"
                        src="/external/dfgg13254-8zjk-300w.png"
                        className="profil-uytkownika-portfoliobutton-dfgg11"
                      />
                      <span className="profil-uytkownika-portfoliobutton-text132">
                        <span>Logo Harem Artystyczny</span>
                      </span>
                      <span className="profil-uytkownika-portfoliobutton-text134">
                        <span>
                          Opis: To jest logo najwięszego community artystycznego
                          w polsce!
                        </span>
                      </span>
                    </div>
                    <div className="profil-uytkownika-portfoliobutton-frame491">
                      <div className="profil-uytkownika-portfoliobutton-frame841">
                        <span className="profil-uytkownika-portfoliobutton-text136">
                          <span>LOGO</span>
                        </span>
                      </div>
                      <div className="profil-uytkownika-portfoliobutton-frame851">
                        <span className="profil-uytkownika-portfoliobutton-text138">
                          <span>Styl Wektorywy</span>
                        </span>
                      </div>
                      <div className="profil-uytkownika-portfoliobutton-frame861">
                        <span className="profil-uytkownika-portfoliobutton-text140">
                          <span>Logotypy</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profil-uytkownika-portfoliobutton-group1571">
                <div className="profil-uytkownika-portfoliobutton-frame1852">
                  <div className="profil-uytkownika-portfoliobutton-frame1842">
                    <div className="profil-uytkownika-portfoliobutton-frame1832">
                      <img
                        alt="dfgg13457"
                        src="/external/dfgg13457-p1k4-300w.png"
                        className="profil-uytkownika-portfoliobutton-dfgg12"
                      />
                      <span className="profil-uytkownika-portfoliobutton-text142">
                        <span>Logo Harem Artystyczny</span>
                      </span>
                      <span className="profil-uytkownika-portfoliobutton-text144">
                        <span>
                          Opis: To jest logo najwięszego community artystycznego
                          w polsce!
                        </span>
                      </span>
                    </div>
                    <div className="profil-uytkownika-portfoliobutton-frame492">
                      <div className="profil-uytkownika-portfoliobutton-frame842">
                        <span className="profil-uytkownika-portfoliobutton-text146">
                          <span>LOGO</span>
                        </span>
                      </div>
                      <div className="profil-uytkownika-portfoliobutton-frame852">
                        <span className="profil-uytkownika-portfoliobutton-text148">
                          <span>Styl Wektorywy</span>
                        </span>
                      </div>
                      <div className="profil-uytkownika-portfoliobutton-frame862">
                        <span className="profil-uytkownika-portfoliobutton-text150">
                          <span>Logotypy</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profil-uytkownika-portfoliobutton-group158">
                <div className="profil-uytkownika-portfoliobutton-frame1853">
                  <div className="profil-uytkownika-portfoliobutton-frame1843">
                    <div className="profil-uytkownika-portfoliobutton-frame1833">
                      <img
                        alt="dfgg13457"
                        src="/external/dfgg13457-6laq-300w.png"
                        className="profil-uytkownika-portfoliobutton-dfgg13"
                      />
                      <span className="profil-uytkownika-portfoliobutton-text152">
                        <span>Logo Harem Artystyczny</span>
                      </span>
                      <span className="profil-uytkownika-portfoliobutton-text154">
                        <span>
                          Opis: To jest logo najwięszego community artystycznego
                          w polsce!
                        </span>
                      </span>
                    </div>
                    <div className="profil-uytkownika-portfoliobutton-frame493">
                      <div className="profil-uytkownika-portfoliobutton-frame843">
                        <span className="profil-uytkownika-portfoliobutton-text156">
                          <span>LOGO</span>
                        </span>
                      </div>
                      <div className="profil-uytkownika-portfoliobutton-frame853">
                        <span className="profil-uytkownika-portfoliobutton-text158">
                          <span>Styl Wektorywy</span>
                        </span>
                      </div>
                      <div className="profil-uytkownika-portfoliobutton-frame863">
                        <span className="profil-uytkownika-portfoliobutton-text160">
                          <span>Logotypy</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profil-uytkownika-portfoliobutton-group161">
                <div className="profil-uytkownika-portfoliobutton-frame1854">
                  <div className="profil-uytkownika-portfoliobutton-frame1844">
                    <div className="profil-uytkownika-portfoliobutton-frame1834">
                      <img
                        alt="dfgg13457"
                        src="/external/dfgg13457-4alw-300w.png"
                        className="profil-uytkownika-portfoliobutton-dfgg14"
                      />
                      <span className="profil-uytkownika-portfoliobutton-text162">
                        <span>Logo Harem Artystyczny</span>
                      </span>
                      <span className="profil-uytkownika-portfoliobutton-text164">
                        <span>
                          Opis: To jest logo najwięszego community artystycznego
                          w polsce!
                        </span>
                      </span>
                    </div>
                    <div className="profil-uytkownika-portfoliobutton-frame494">
                      <div className="profil-uytkownika-portfoliobutton-frame844">
                        <span className="profil-uytkownika-portfoliobutton-text166">
                          <span>LOGO</span>
                        </span>
                      </div>
                      <div className="profil-uytkownika-portfoliobutton-frame854">
                        <span className="profil-uytkownika-portfoliobutton-text168">
                          <span>Styl Wektorywy</span>
                        </span>
                      </div>
                      <div className="profil-uytkownika-portfoliobutton-frame864">
                        <span className="profil-uytkownika-portfoliobutton-text170">
                          <span>Logotypy</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profil-uytkownika-portfoliobutton-group160">
                <div className="profil-uytkownika-portfoliobutton-frame1855">
                  <div className="profil-uytkownika-portfoliobutton-frame1845">
                    <div className="profil-uytkownika-portfoliobutton-frame1835">
                      <img
                        alt="dfgg13457"
                        src="/external/dfgg13457-bfb-300w.png"
                        className="profil-uytkownika-portfoliobutton-dfgg15"
                      />
                      <span className="profil-uytkownika-portfoliobutton-text172">
                        <span>Logo Harem Artystyczny</span>
                      </span>
                      <span className="profil-uytkownika-portfoliobutton-text174">
                        <span>
                          Opis: To jest logo najwięszego community artystycznego
                          w polsce!
                        </span>
                      </span>
                    </div>
                    <div className="profil-uytkownika-portfoliobutton-frame495">
                      <div className="profil-uytkownika-portfoliobutton-frame845">
                        <span className="profil-uytkownika-portfoliobutton-text176">
                          <span>LOGO</span>
                        </span>
                      </div>
                      <div className="profil-uytkownika-portfoliobutton-frame855">
                        <span className="profil-uytkownika-portfoliobutton-text178">
                          <span>Styl Wektorywy</span>
                        </span>
                      </div>
                      <div className="profil-uytkownika-portfoliobutton-frame865">
                        <span className="profil-uytkownika-portfoliobutton-text180">
                          <span>Logotypy</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profil-uytkownika-portfoliobutton-group159">
                <div className="profil-uytkownika-portfoliobutton-frame1856">
                  <div className="profil-uytkownika-portfoliobutton-frame1846">
                    <div className="profil-uytkownika-portfoliobutton-frame1836">
                      <img
                        alt="dfgg13457"
                        src="/external/dfgg13457-jm3d-300w.png"
                        className="profil-uytkownika-portfoliobutton-dfgg16"
                      />
                      <span className="profil-uytkownika-portfoliobutton-text182">
                        <span>Logo Harem Artystyczny</span>
                      </span>
                      <span className="profil-uytkownika-portfoliobutton-text184">
                        <span>
                          Opis: To jest logo najwięszego community artystycznego
                          w polsce!
                        </span>
                      </span>
                    </div>
                    <div className="profil-uytkownika-portfoliobutton-frame496">
                      <div className="profil-uytkownika-portfoliobutton-frame846">
                        <span className="profil-uytkownika-portfoliobutton-text186">
                          <span>LOGO</span>
                        </span>
                      </div>
                      <div className="profil-uytkownika-portfoliobutton-frame856">
                        <span className="profil-uytkownika-portfoliobutton-text188">
                          <span>Styl Wektorywy</span>
                        </span>
                      </div>
                      <div className="profil-uytkownika-portfoliobutton-frame866">
                        <span className="profil-uytkownika-portfoliobutton-text190">
                          <span>Logotypy</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profil-uytkownika-portfoliobutton-group162">
            <div className="profil-uytkownika-portfoliobutton-frame181">
              <div className="profil-uytkownika-portfoliobutton-frame178">
                <div className="profil-uytkownika-portfoliobutton-group1572">
                  <img
                    alt="image213435"
                    src="/external/image213435-ys9r-200h.png"
                    className="profil-uytkownika-portfoliobutton-image21"
                  />
                  <img
                    alt="Ellipse253435"
                    src="/external/ellipse253435-acw-200h.png"
                    className="profil-uytkownika-portfoliobutton-ellipse25"
                  />
                </div>
                <span className="profil-uytkownika-portfoliobutton-text192">
                  <span>Magdalena Maria  Monika</span>
                </span>
                <div className="profil-uytkownika-portfoliobutton-frame166">
                  <div className="profil-uytkownika-portfoliobutton-frame165">
                    <img
                      alt="Star13435"
                      src="/external/star13435-s6k9.svg"
                      className="profil-uytkownika-portfoliobutton-star1"
                    />
                    <img
                      alt="Star23435"
                      src="/external/star23435-te2l.svg"
                      className="profil-uytkownika-portfoliobutton-star2"
                    />
                    <img
                      alt="Star33435"
                      src="/external/star33435-31el.svg"
                      className="profil-uytkownika-portfoliobutton-star3"
                    />
                    <img
                      alt="Star43435"
                      src="/external/star43435-6moi.svg"
                      className="profil-uytkownika-portfoliobutton-star4"
                    />
                    <img
                      alt="Star53435"
                      src="/external/star53435-7sa.svg"
                      className="profil-uytkownika-portfoliobutton-star5"
                    />
                  </div>
                  <span className="profil-uytkownika-portfoliobutton-text194">
                    <span>3/5</span>
                  </span>
                </div>
                <div className="profil-uytkownika-portfoliobutton-frame171">
                  <div className="profil-uytkownika-portfoliobutton-frame168">
                    <div className="profil-uytkownika-portfoliobutton-frame1671">
                      <img
                        alt="awardiconmedalpng13435"
                        src="/external/awardiconmedalpng13435-noar-200w.png"
                        className="profil-uytkownika-portfoliobutton-awardiconmedalpng11"
                      />
                      <span className="profil-uytkownika-portfoliobutton-text196">
                        <span>Zweryfikowany Artysta</span>
                      </span>
                    </div>
                  </div>
                  <div className="profil-uytkownika-portfoliobutton-frame169">
                    <div className="profil-uytkownika-portfoliobutton-frame1672">
                      <img
                        alt="awardiconmedalpng13435"
                        src="/external/awardiconmedalpng13435-2wfb-200w.png"
                        className="profil-uytkownika-portfoliobutton-awardiconmedalpng12"
                      />
                      <span className="profil-uytkownika-portfoliobutton-text198">
                        <span>Artysta z community</span>
                      </span>
                    </div>
                  </div>
                  <div className="profil-uytkownika-portfoliobutton-frame170">
                    <div className="profil-uytkownika-portfoliobutton-frame1673">
                      <img
                        alt="awardiconmedalpng13435"
                        src="/external/awardiconmedalpng13435-rdh-200w.png"
                        className="profil-uytkownika-portfoliobutton-awardiconmedalpng13"
                      />
                      <span className="profil-uytkownika-portfoliobutton-text200">
                        <span>Wysoki poziom zaufania</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="profil-uytkownika-portfoliobutton-frame172">
                  <img
                    alt="icon3435"
                    src="/external/icon3435-i9i.svg"
                    className="profil-uytkownika-portfoliobutton-icon1"
                  />
                  <span className="profil-uytkownika-portfoliobutton-text202">
                    <span>Wykonane zlecenia: 24</span>
                  </span>
                </div>
              </div>
            </div>
            <img
              alt="Rectangle1163457"
              src="/external/rectangle1163457-l8w-200h.png"
              className="profil-uytkownika-portfoliobutton-rectangle116"
            />
            <div className="profil-uytkownika-portfoliobutton-frame174">
              <img
                alt="mail3435"
                src="/external/mail3435-6r5r.svg"
                className="profil-uytkownika-portfoliobutton-mail"
              />
              <Link
                to="/profil-uytkownika-opiniebutton"
                className="profil-uytkownika-portfoliobutton-text204"
              >
                <span>Opinie</span>
              </Link>
            </div>
            <div className="profil-uytkownika-portfoliobutton-frame173">
              <img
                alt="icon3435"
                src="/external/icon3435-tv7d.svg"
                className="profil-uytkownika-portfoliobutton-icon2"
              />
              <Link
                to="/profil-uytkownika-ommiebutton"
                className="profil-uytkownika-portfoliobutton-text206"
              >
                <span>O mnie</span>
              </Link>
            </div>
            <div className="profil-uytkownika-portfoliobutton-frame175">
              <img
                alt="photo3435"
                src="/external/photo3435-apmt.svg"
                className="profil-uytkownika-portfoliobutton-photo"
              />
              <span className="profil-uytkownika-portfoliobutton-text208">
                <span>Portfolio</span>
              </span>
            </div>
            <div className="profil-uytkownika-portfoliobutton-frame176">
              <img
                alt="folderfilled3435"
                src="/external/folderfilled3435-csr2.svg"
                className="profil-uytkownika-portfoliobutton-folderfilled"
              />
              <Link
                to="/profil-uytkownika-wykonanezleceniabutton"
                className="profil-uytkownika-portfoliobutton-text210"
              >
                <span>
                  Wykonane
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>zlecenia</span>
              </Link>
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default ProfilUytkownikaPortfoliobutton
