import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './okn-ooddajzleceniebutton.css'

const OKNOoddajzleceniebutton = (props) => {
  return (
    <div className="okn-ooddajzleceniebutton-container1">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="okn-ooddajzleceniebutton-dummy-container">
        <button className="okn-ooddajzleceniebutton-okn-ooddajzleceniebutton">
          <div className="okn-ooddajzleceniebutton-group153">
            <img
              alt="Rectangle933201"
              src="/external/rectangle933201-oi6r-600w.png"
              className="okn-ooddajzleceniebutton-rectangle93"
            />
            <img
              alt="Rectangle1053201"
              src="/external/rectangle1053201-sahq-600w.png"
              className="okn-ooddajzleceniebutton-rectangle105"
            />
            <img
              alt="Rectangle1063201"
              src="/external/rectangle1063201-60qa-600h.png"
              className="okn-ooddajzleceniebutton-rectangle106"
            />
          </div>
          <div className="okn-ooddajzleceniebutton-group115">
            <div className="okn-ooddajzleceniebutton-frame1111">
              <Link to="/" className="okn-ooddajzleceniebutton-navlink">
                <img
                  alt="LGOOROBOCZEV00113185"
                  src="/external/lgooroboczev00113185-d2f9-200h.png"
                  className="okn-ooddajzleceniebutton-lgooroboczev0011"
                />
              </Link>
              <div className="okn-ooddajzleceniebutton-group114">
                <div className="okn-ooddajzleceniebutton-input1">
                  <div className="okn-ooddajzleceniebutton-container2">
                    <span className="okn-ooddajzleceniebutton-text10">
                      <span>Czego szukasz towarzyszu?</span>
                    </span>
                  </div>
                </div>
                <button className="okn-ooddajzleceniebutton-button1">
                  <img
                    alt="SVG3185"
                    src="/external/svg3185-ov8.svg"
                    className="okn-ooddajzleceniebutton-svg"
                  />
                </button>
              </div>
              <button className="okn-ooddajzleceniebutton-item-button">
                <div className="okn-ooddajzleceniebutton-group112">
                  <Link
                    to="/communityartystycznebutton"
                    className="okn-ooddajzleceniebutton-text12"
                  >
                    <span>Społeczność Artystyczna</span>
                  </Link>
                  <img
                    alt="Vector3185"
                    src="/external/vector3185-rdwg.svg"
                    className="okn-ooddajzleceniebutton-vector1"
                  />
                </div>
              </button>
              <div className="okn-ooddajzleceniebutton-group113">
                <Link
                  to="/communityartystycznebutton"
                  className="okn-ooddajzleceniebutton-text14"
                >
                  <span>Q&amp;A</span>
                </Link>
                <img
                  alt="Vector3185"
                  src="/external/vector3185-2w7o.svg"
                  className="okn-ooddajzleceniebutton-vector2"
                />
              </div>
              <div className="okn-ooddajzleceniebutton-item-link1">
                <Link
                  to="/logowani-ebutton"
                  className="okn-ooddajzleceniebutton-text16"
                >
                  <span>Sign in</span>
                </Link>
              </div>
              <div className="okn-ooddajzleceniebutton-item-link2">
                <Link
                  to="/logowani-ebutton"
                  className="okn-ooddajzleceniebutton-text18"
                >
                  <span>Join</span>
                </Link>
              </div>
            </div>
          </div>
          <img
            alt="Rectangle1013185"
            src="/external/rectangle1013185-cbc9-200h.png"
            className="okn-ooddajzleceniebutton-rectangle101"
          />
          <div className="okn-ooddajzleceniebutton-frame1112">
            <Link
              to="/podstrona-grafikbutton"
              className="okn-ooddajzleceniebutton-text20"
            >
              <span>Cosplay</span>
            </Link>
            <Link
              to="/podstrona-grafikbutton"
              className="okn-ooddajzleceniebutton-text22"
            >
              <span>Pisarz</span>
            </Link>
            <Link
              to="/podstrona-grafikbutton"
              className="okn-ooddajzleceniebutton-text24"
            >
              <span>Muzyka&amp;audio</span>
            </Link>
            <Link
              to="/podstrona-grafikbutton"
              className="okn-ooddajzleceniebutton-text26"
            >
              <span>Rysownik</span>
            </Link>
            <Link
              to="/podstrona-grafikbutton"
              className="okn-ooddajzleceniebutton-text28"
            >
              <span>UI/Ux Design</span>
            </Link>
            <Link
              to="/podstrona-grafikbutton"
              className="okn-ooddajzleceniebutton-text30"
            >
              <span>Grafika Komputerowa</span>
            </Link>
          </div>
          <img
            alt="Rectangle1033185"
            src="/external/rectangle1033185-fr5c-200h.png"
            className="okn-ooddajzleceniebutton-rectangle103"
          />
          <div className="okn-ooddajzleceniebutton-group149">
            <div className="okn-ooddajzleceniebutton-frame152">
              <div className="okn-ooddajzleceniebutton-frame151">
                <div className="okn-ooddajzleceniebutton-group148">
                  <img
                    alt="Avatars3davatar163185"
                    src="/external/avatars3davatar163185-b3q-200w.png"
                    className="okn-ooddajzleceniebutton-avatars3davatar16"
                  />
                  <img
                    alt="Ellipse253185"
                    src="/external/ellipse253185-q3di-200h.png"
                    className="okn-ooddajzleceniebutton-ellipse25"
                  />
                </div>
                <div className="okn-ooddajzleceniebutton-frame150">
                  <span className="okn-ooddajzleceniebutton-text32">
                    <span>Zlecenie 3415: Dupp</span>
                  </span>
                  <span className="okn-ooddajzleceniebutton-text34">
                    <span>Wykonaj Dowolne logo!</span>
                  </span>
                  <div className="okn-ooddajzleceniebutton-frame70">
                    <img
                      alt="Dollarsign3185"
                      src="/external/dollarsign3185-12fg.svg"
                      className="okn-ooddajzleceniebutton-dollarsign"
                    />
                    <span className="okn-ooddajzleceniebutton-text36">
                      <span>Budżet: 200 zł</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="okn-ooddajzleceniebutton-frame153">
              <button className="okn-ooddajzleceniebutton-button2">
                <span className="okn-ooddajzleceniebutton-text38">
                  <span>Oddaj zlecenie</span>
                </span>
              </button>
            </div>
            <div className="okn-ooddajzleceniebutton-frame161">
              <span className="okn-ooddajzleceniebutton-text40">
                <span>Oddaj Zlecenie!</span>
              </span>
              <div className="okn-ooddajzleceniebutton-frame1601">
                <div className="okn-ooddajzleceniebutton-frame1602">
                  <span className="okn-ooddajzleceniebutton-text42">
                    <span>Nazwa Projektu</span>
                  </span>
                  <input
                    type="text"
                    placeholder
                    className="okn-ooddajzleceniebutton-input2"
                  />
                </div>
                <div className="okn-ooddajzleceniebutton-frame156">
                  <span className="okn-ooddajzleceniebutton-text44">
                    <span>Dodatkowy komentarz.</span>
                  </span>
                  <div className="okn-ooddajzleceniebutton-textarea"></div>
                </div>
                <div className="okn-ooddajzleceniebutton-frame159">
                  <span className="okn-ooddajzleceniebutton-text46">
                    <span>Pliki</span>
                  </span>
                  <div className="okn-ooddajzleceniebutton-input3">
                    <input
                      type="text"
                      placeholder="Choose Files"
                      className="okn-ooddajzleceniebutton-input4"
                    />
                    <span className="okn-ooddajzleceniebutton-text48">
                      <span>No file chosen</span>
                    </span>
                  </div>
                  <span className="okn-ooddajzleceniebutton-text50">
                    <span>
                      Możesz wybrać wiele plików. Akceptowane formaty: jpg, png,
                      pdf.
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default OKNOoddajzleceniebutton
