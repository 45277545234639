import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './logowani-ebutton.css'

const LOGOWANIEbutton = (props) => {
  return (
    <div className="logowani-ebutton-container">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="logowani-ebutton-dummy-container">
        <button className="logowani-ebutton-logowani-ebutton">
          <img
            alt="Ellipse41011"
            src="/external/ellipse41011-2ah-1300w.png"
            className="logowani-ebutton-ellipse4"
          />
          <Link to="/" className="logowani-ebutton-navlink1">
            <img
              alt="LGOOROBOCZEV00116691"
              src="/external/lgooroboczev00116691-4ei8h-200h.png"
              className="logowani-ebutton-lgooroboczev0011"
            />
          </Link>
          <div className="logowani-ebutton-frame39">
            <span className="logowani-ebutton-text10">
              <span>Ohayo!</span>
            </span>
            <div className="logowani-ebutton-frame35">
              <span className="logowani-ebutton-text12">
                <span>Nazwa użytkownika</span>
              </span>
              <img
                alt="Rectangle61011"
                src="/external/rectangle61011-zara-200h.png"
                className="logowani-ebutton-rectangle6"
              />
            </div>
            <div className="logowani-ebutton-frame38">
              <div className="logowani-ebutton-frame36">
                <span className="logowani-ebutton-text14">
                  <span>Password</span>
                </span>
                <img
                  alt="Rectangle31011"
                  src="/external/rectangle31011-3eq7-200h.png"
                  className="logowani-ebutton-rectangle3"
                />
              </div>
              <div className="logowani-ebutton-group56">
                <img
                  alt="Rectangle51011"
                  src="/external/rectangle51011-0q9-200h.png"
                  className="logowani-ebutton-rectangle5"
                />
                <span className="logowani-ebutton-text16">
                  <span>Remember me</span>
                </span>
              </div>
            </div>
            <Link to="/widokczatu3button" className="logowani-ebutton-navlink2">
              <div className="logowani-ebutton-group55">
                <span className="logowani-ebutton-text18">
                  <span>Kontynuuj</span>
                </span>
              </div>
            </Link>
            <img
              alt="image141011"
              src="/external/image141011-tzwd-200h.png"
              className="logowani-ebutton-image14"
            />
          </div>
          <img
            alt="image71011"
            src="/external/image71011-7zw-1300w.png"
            className="logowani-ebutton-image7"
          />
        </button>
      </div>
    </div>
  )
}

export default LOGOWANIEbutton
