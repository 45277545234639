import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './widokczatu3button.css'

const WIDOKCZATU3button = (props) => {
  return (
    <div className="widokczatu3button-container">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="widokczatu3button-dummy-container">
        <button className="widokczatu3button-widokczatu3button">
          <div className="widokczatu3button-frame42"></div>
          <Link to="/" className="widokczatu3button-navlink">
            <img
              alt="LGOOROBOCZEV00111154"
              src="/external/lgooroboczev00111154-giy7-200h.png"
              className="widokczatu3button-lgooroboczev0011"
            />
          </Link>
          <div className="widokczatu3button-frame46">
            <span className="widokczatu3button-text10">
              <span>Kim Jesteś?</span>
            </span>
            <div className="widokczatu3button-frame45">
              <div className="widokczatu3button-frame43">
                <img
                  alt="lupa11165"
                  src="/external/lupa11165-lsu-200h.png"
                  className="widokczatu3button-lupa1"
                />
                <span className="widokczatu3button-text12">
                  <span>Szukam Artysty</span>
                </span>
                <span className="widokczatu3button-text14">
                  <span>Szukam kogoś kto mi ogarnie zlecenie</span>
                </span>
              </div>
              <div className="widokczatu3button-frame44">
                <img
                  alt="PENDZEl11166"
                  src="/external/pendzel11166-4cbr-200h.png"
                  className="widokczatu3button-pendz-el1"
                />
                <span className="widokczatu3button-text16">
                  <span>Jestem Artystą</span>
                </span>
                <span className="widokczatu3button-text18">
                  <span>Chcę zarabiać na tym co mnie jara!</span>
                </span>
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default WIDOKCZATU3button
