import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './oknozoofer-tbutton.css'

const OKNOZOOFERTbutton = (props) => {
  return (
    <div className="oknozoofer-tbutton-container1">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="oknozoofer-tbutton-dummy-container">
        <button className="oknozoofer-tbutton-oknozoofer-tbutton">
          <div className="oknozoofer-tbutton-group153">
            <img
              alt="Rectangle933201"
              src="/external/rectangle933201-3gcq-600w.png"
              className="oknozoofer-tbutton-rectangle93"
            />
            <img
              alt="Rectangle1053201"
              src="/external/rectangle1053201-ur58-600w.png"
              className="oknozoofer-tbutton-rectangle105"
            />
            <img
              alt="Rectangle1063201"
              src="/external/rectangle1063201-wpz-600h.png"
              className="oknozoofer-tbutton-rectangle106"
            />
          </div>
          <div className="oknozoofer-tbutton-group115">
            <div className="oknozoofer-tbutton-frame1111">
              <Link to="/" className="oknozoofer-tbutton-navlink1">
                <img
                  alt="LGOOROBOCZEV00112799"
                  src="/external/lgooroboczev00112799-xosv-200h.png"
                  className="oknozoofer-tbutton-lgooroboczev0011"
                />
              </Link>
              <div className="oknozoofer-tbutton-group114">
                <div className="oknozoofer-tbutton-input1">
                  <div className="oknozoofer-tbutton-container2">
                    <span className="oknozoofer-tbutton-text10">
                      <span>Czego szukasz towarzyszu?</span>
                    </span>
                  </div>
                </div>
                <button className="oknozoofer-tbutton-button1">
                  <img
                    alt="SVG2799"
                    src="/external/svg2799-bfdq.svg"
                    className="oknozoofer-tbutton-svg"
                  />
                </button>
              </div>
              <button className="oknozoofer-tbutton-item-button">
                <div className="oknozoofer-tbutton-group112">
                  <Link
                    to="/communityartystycznebutton"
                    className="oknozoofer-tbutton-text12"
                  >
                    <span>Społeczność Artystyczna</span>
                  </Link>
                  <img
                    alt="Vector2799"
                    src="/external/vector2799-cl6.svg"
                    className="oknozoofer-tbutton-vector1"
                  />
                </div>
              </button>
              <Link
                to="/communityartystycznebutton"
                className="oknozoofer-tbutton-navlink2"
              >
                <div className="oknozoofer-tbutton-group113">
                  <span className="oknozoofer-tbutton-text14">
                    <span>Q&amp;A</span>
                  </span>
                  <img
                    alt="Vector2799"
                    src="/external/vector2799-3opj.svg"
                    className="oknozoofer-tbutton-vector2"
                  />
                </div>
              </Link>
              <div className="oknozoofer-tbutton-item-link1">
                <Link
                  to="/logowani-ebutton"
                  className="oknozoofer-tbutton-text16"
                >
                  <span>Sign in</span>
                </Link>
              </div>
              <Link
                to="/logowani-ebutton"
                className="oknozoofer-tbutton-navlink3"
              >
                <div className="oknozoofer-tbutton-item-link2">
                  <span className="oknozoofer-tbutton-text18">
                    <span>Join</span>
                  </span>
                </div>
              </Link>
            </div>
          </div>
          <img
            alt="Rectangle1012799"
            src="/external/rectangle1012799-agf-200h.png"
            className="oknozoofer-tbutton-rectangle101"
          />
          <Link
            to="/podstrona-grafikbutton"
            className="oknozoofer-tbutton-navlink4"
          >
            <div className="oknozoofer-tbutton-frame1112">
              <span className="oknozoofer-tbutton-text20">
                <span>Cosplay</span>
              </span>
              <span className="oknozoofer-tbutton-text22">
                <span>Pisarz</span>
              </span>
              <span className="oknozoofer-tbutton-text24">
                <span>Muzyka&amp;audio</span>
              </span>
              <span className="oknozoofer-tbutton-text26">
                <span>Rysownik</span>
              </span>
              <span className="oknozoofer-tbutton-text28">
                <span>UI/Ux Design</span>
              </span>
              <span className="oknozoofer-tbutton-text30">
                <span>Grafika Komputerowa</span>
              </span>
            </div>
          </Link>
          <img
            alt="Rectangle1032791"
            src="/external/rectangle1032791-1fs-200h.png"
            className="oknozoofer-tbutton-rectangle103"
          />
          <div className="oknozoofer-tbutton-group149">
            <div className="oknozoofer-tbutton-frame152">
              <div className="oknozoofer-tbutton-frame151">
                <div className="oknozoofer-tbutton-group148">
                  <img
                    alt="image213081"
                    src="/external/image213081-110r-200h.png"
                    className="oknozoofer-tbutton-image21"
                  />
                  <img
                    alt="Ellipse253081"
                    src="/external/ellipse253081-dn6i-200h.png"
                    className="oknozoofer-tbutton-ellipse25"
                  />
                </div>
                <div className="oknozoofer-tbutton-frame150">
                  <span className="oknozoofer-tbutton-text32">
                    <span>Maria Maria Monika</span>
                  </span>
                  <span className="oknozoofer-tbutton-text34">
                    <span>Wykonam Dla ciebie Dowolne logo!</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="oknozoofer-tbutton-frame153">
              <button className="oknozoofer-tbutton-button2">
                <span className="oknozoofer-tbutton-text36">
                  <span>Wyślij Zlecenie</span>
                </span>
              </button>
              <button className="oknozoofer-tbutton-button3">
                <span className="oknozoofer-tbutton-text38">
                  <span>Otwórz czat</span>
                </span>
              </button>
            </div>
            <div className="oknozoofer-tbutton-frame161">
              <span className="oknozoofer-tbutton-text40">
                <span>Złóż zlecenie</span>
              </span>
              <div className="oknozoofer-tbutton-frame160">
                <div className="oknozoofer-tbutton-frame154">
                  <span className="oknozoofer-tbutton-text42">
                    <span>Imię i nazwisko</span>
                  </span>
                  <input
                    type="text"
                    placeholder
                    className="oknozoofer-tbutton-input2"
                  />
                </div>
                <div className="oknozoofer-tbutton-frame155">
                  <span className="oknozoofer-tbutton-text44">
                    <span>E-mail</span>
                  </span>
                  <input
                    type="text"
                    placeholder
                    className="oknozoofer-tbutton-input3"
                  />
                </div>
                <div className="oknozoofer-tbutton-frame156">
                  <span className="oknozoofer-tbutton-text46">
                    <span>Opis zlecenia</span>
                  </span>
                  <div className="oknozoofer-tbutton-textarea"></div>
                </div>
                <div className="oknozoofer-tbutton-frame157">
                  <span className="oknozoofer-tbutton-text48">
                    <span>Termin realizacji</span>
                  </span>
                  <div className="oknozoofer-tbutton-input4">
                    <div className="oknozoofer-tbutton-paragraph">
                      <span className="oknozoofer-tbutton-text50">
                        <span>mm</span>
                      </span>
                      <span className="oknozoofer-tbutton-text52">/</span>
                      <span className="oknozoofer-tbutton-text53">
                        <span>dd</span>
                      </span>
                      <span className="oknozoofer-tbutton-text55">/</span>
                      <span className="oknozoofer-tbutton-text56">
                        <span>yyyy</span>
                      </span>
                    </div>
                    <div className="oknozoofer-tbutton-image">
                      <div className="oknozoofer-tbutton-imagefill"></div>
                    </div>
                  </div>
                </div>
                <div className="oknozoofer-tbutton-frame158">
                  <span className="oknozoofer-tbutton-text58">
                    <span>Twoja oferta (w zł)</span>
                  </span>
                  <input
                    type="text"
                    placeholder
                    className="oknozoofer-tbutton-input5"
                  />
                </div>
                <div className="oknozoofer-tbutton-frame159">
                  <span className="oknozoofer-tbutton-text60">
                    <span>Materiały referencyjne</span>
                  </span>
                  <div className="oknozoofer-tbutton-input6">
                    <input
                      type="text"
                      placeholder="Choose Files"
                      className="oknozoofer-tbutton-input7"
                    />
                    <span className="oknozoofer-tbutton-text62">
                      <span>No file chosen</span>
                    </span>
                  </div>
                  <span className="oknozoofer-tbutton-text64">
                    <span>
                      Możesz wybrać wiele plików. Akceptowane formaty: jpg, png,
                      pdf.
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default OKNOZOOFERTbutton
