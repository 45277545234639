import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './oknopowiadomi-ebutton.css'

const OKNOPOWIADOMIEbutton = (props) => {
  return (
    <div className="oknopowiadomi-ebutton-container">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="oknopowiadomi-ebutton-dummy-container">
        <button className="oknopowiadomi-ebutton-oknopowiadomi-ebutton">
          <div className="oknopowiadomi-ebutton-group155">
            <img
              alt="Rectangle933201"
              src="/external/rectangle933201-t2ln-600w.png"
              className="oknopowiadomi-ebutton-rectangle93"
            />
            <img
              alt="Rectangle943201"
              src="/external/rectangle943201-td7-600w.png"
              className="oknopowiadomi-ebutton-rectangle94"
            />
          </div>
          <img
            alt="Rectangle716689"
            src="/external/rectangle716689-ybpb-1000h.png"
            className="oknopowiadomi-ebutton-rectangle71"
          />
          <img
            alt="Rectangle706690"
            src="/external/rectangle706690-zh9-200h.png"
            className="oknopowiadomi-ebutton-rectangle70"
          />
          <img
            alt="image131016"
            src="/external/image131016-voh-200h.png"
            className="oknopowiadomi-ebutton-image13"
          />
          <div className="oknopowiadomi-ebutton-frame9">
            <span className="oknopowiadomi-ebutton-text10">
              <span>Q&amp;A</span>
            </span>
          </div>
          <img
            alt="Rectangle696610"
            src="/external/rectangle696610-wpxj-200h.png"
            className="oknopowiadomi-ebutton-rectangle69"
          />
          <Link
            to="/u-stawieniakont-abutton"
            className="oknopowiadomi-ebutton-text12"
          >
            <span>Konto</span>
          </Link>
          <Link
            to="/oknopowiadomi-ebutton"
            className="oknopowiadomi-ebutton-text14"
          >
            <span>Wiadomości</span>
          </Link>
          <Link to="/zamwieniabutton" className="oknopowiadomi-ebutton-text16">
            <span>Zamówienia</span>
          </Link>
          <span className="oknopowiadomi-ebutton-text18">
            <span>Powiadomienia</span>
          </span>
          <Link
            to="/t-rwajcezleceni-abutton"
            className="oknopowiadomi-ebutton-text20"
          >
            <span>zlecenia</span>
          </Link>
          <img
            alt="image126610"
            src="/external/image126610-0no2-200h.png"
            className="oknopowiadomi-ebutton-image12"
          />
          <span className="oknopowiadomi-ebutton-text22">
            <span>Pan Twarug</span>
          </span>
          <img
            alt="Rectangle236612"
            src="/external/rectangle236612-rrrh-200h.png"
            className="oknopowiadomi-ebutton-rectangle23"
          />
          <img
            alt="Ellipse226615"
            src="/external/ellipse226615-x1l7-200h.png"
            className="oknopowiadomi-ebutton-ellipse22"
          />
          <img
            alt="Ellipse231011"
            src="/external/ellipse231011-ordj-200h.png"
            className="oknopowiadomi-ebutton-ellipse23"
          />
          <div className="oknopowiadomi-ebutton-group53">
            <div className="oknopowiadomi-ebutton-frame291">
              <div className="oknopowiadomi-ebutton-group471">
                <span className="oknopowiadomi-ebutton-text24">
                  <span>Odrzuć zlecenie</span>
                </span>
              </div>
              <Link
                to="/okn-ooddajzleceniebutton"
                className="oknopowiadomi-ebutton-navlink1"
              >
                <div className="oknopowiadomi-ebutton-group461">
                  <span className="oknopowiadomi-ebutton-text26">
                    <span>Potwierdź zlecenie</span>
                  </span>
                </div>
              </Link>
              <div className="oknopowiadomi-ebutton-group441">
                <div className="oknopowiadomi-ebutton-frame211">
                  <img
                    alt="Ellipse111006"
                    src="/external/ellipse111006-6wo-200h.png"
                    className="oknopowiadomi-ebutton-ellipse111"
                  />
                  <img
                    alt="Ellipse121006"
                    src="/external/ellipse121006-9e7i-200h.png"
                    className="oknopowiadomi-ebutton-ellipse121"
                  />
                  <img
                    alt="Ellipse131006"
                    src="/external/ellipse131006-199b-200h.png"
                    className="oknopowiadomi-ebutton-ellipse131"
                  />
                </div>
              </div>
            </div>
            <img
              alt="Rectangle221006"
              src="/external/rectangle221006-1rfx-200w.png"
              className="oknopowiadomi-ebutton-rectangle221"
            />
            <img
              alt="pngwing11007"
              src="/external/pngwing11007-w16s-200w.png"
              className="oknopowiadomi-ebutton-pngwing11"
            />
            <span className="oknopowiadomi-ebutton-text28">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle731007"
              src="/external/rectangle731007-2mas-200w.png"
              className="oknopowiadomi-ebutton-rectangle731"
            />
            <span className="oknopowiadomi-ebutton-text30">
              <span>Nowe zlecenie</span>
            </span>
            <span className="oknopowiadomi-ebutton-text32">
              <span>RYSUNEK KOTA W SUKIENCE #RYSUNEK #DIGITAL ART</span>
            </span>
            <span className="oknopowiadomi-ebutton-text34">
              <span>150zł</span>
            </span>
            <span className="oknopowiadomi-ebutton-text36">
              <span>NARYSUJ KOTA w suk...</span>
            </span>
          </div>
          <div className="oknopowiadomi-ebutton-group52">
            <div className="oknopowiadomi-ebutton-frame292">
              <div className="oknopowiadomi-ebutton-group472">
                <span className="oknopowiadomi-ebutton-text38">
                  <span>Odrzuć zlecenie</span>
                </span>
              </div>
              <Link
                to="/okn-ooddajzleceniebutton"
                className="oknopowiadomi-ebutton-navlink2"
              >
                <div className="oknopowiadomi-ebutton-group462">
                  <span className="oknopowiadomi-ebutton-text40">
                    <span>Potwierdź zlecenie</span>
                  </span>
                </div>
              </Link>
              <div className="oknopowiadomi-ebutton-group442">
                <div className="oknopowiadomi-ebutton-frame212">
                  <img
                    alt="Ellipse111004"
                    src="/external/ellipse111004-o68a-200h.png"
                    className="oknopowiadomi-ebutton-ellipse112"
                  />
                  <img
                    alt="Ellipse121004"
                    src="/external/ellipse121004-96a-200h.png"
                    className="oknopowiadomi-ebutton-ellipse122"
                  />
                  <img
                    alt="Ellipse131004"
                    src="/external/ellipse131004-0pv1-200h.png"
                    className="oknopowiadomi-ebutton-ellipse132"
                  />
                </div>
              </div>
            </div>
            <img
              alt="Rectangle221004"
              src="/external/rectangle221004-itv9-200w.png"
              className="oknopowiadomi-ebutton-rectangle222"
            />
            <img
              alt="pngwing11004"
              src="/external/pngwing11004-tlm-200w.png"
              className="oknopowiadomi-ebutton-pngwing12"
            />
            <span className="oknopowiadomi-ebutton-text42">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle731005"
              src="/external/rectangle731005-nrsl-200w.png"
              className="oknopowiadomi-ebutton-rectangle732"
            />
            <span className="oknopowiadomi-ebutton-text44">
              <span>Nowe zlecenie</span>
            </span>
            <span className="oknopowiadomi-ebutton-text46">
              <span>ZRÓB LOGO #logo</span>
            </span>
            <span className="oknopowiadomi-ebutton-text48">
              <span>1150zł</span>
            </span>
            <span className="oknopowiadomi-ebutton-text50">
              <span>LOGOO</span>
            </span>
          </div>
          <div className="oknopowiadomi-ebutton-group51">
            <div className="oknopowiadomi-ebutton-frame293">
              <div className="oknopowiadomi-ebutton-group473">
                <span className="oknopowiadomi-ebutton-text52">
                  <span>Odrzuć zlecenie</span>
                </span>
              </div>
              <Link
                to="/okn-ooddajzleceniebutton"
                className="oknopowiadomi-ebutton-navlink3"
              >
                <div className="oknopowiadomi-ebutton-group463">
                  <span className="oknopowiadomi-ebutton-text54">
                    <span>Potwierdź zlecenie</span>
                  </span>
                </div>
              </Link>
              <div className="oknopowiadomi-ebutton-group443">
                <div className="oknopowiadomi-ebutton-frame213">
                  <img
                    alt="Ellipse111002"
                    src="/external/ellipse111002-yzqh-200h.png"
                    className="oknopowiadomi-ebutton-ellipse113"
                  />
                  <img
                    alt="Ellipse121002"
                    src="/external/ellipse121002-khgb-200h.png"
                    className="oknopowiadomi-ebutton-ellipse123"
                  />
                  <img
                    alt="Ellipse131002"
                    src="/external/ellipse131002-foi6-200h.png"
                    className="oknopowiadomi-ebutton-ellipse133"
                  />
                </div>
              </div>
            </div>
            <img
              alt="Rectangle221002"
              src="/external/rectangle221002-ivj-200w.png"
              className="oknopowiadomi-ebutton-rectangle223"
            />
            <img
              alt="pngwing11002"
              src="/external/pngwing11002-25pq-200w.png"
              className="oknopowiadomi-ebutton-pngwing13"
            />
            <span className="oknopowiadomi-ebutton-text56">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle731002"
              src="/external/rectangle731002-ujbh-200w.png"
              className="oknopowiadomi-ebutton-rectangle733"
            />
            <span className="oknopowiadomi-ebutton-text58">
              <span>Nowe zlecenie</span>
            </span>
            <span className="oknopowiadomi-ebutton-text60">
              <span>RYSUNEK KOTA W SUKIENCE #RYSUNEK #DIGITAL ART</span>
            </span>
            <span className="oknopowiadomi-ebutton-text62">
              <span>150zł</span>
            </span>
            <span className="oknopowiadomi-ebutton-text64">
              <span>NARYSUJ KOTA w suk...</span>
            </span>
          </div>
          <div className="oknopowiadomi-ebutton-group50">
            <div className="oknopowiadomi-ebutton-frame294">
              <div className="oknopowiadomi-ebutton-group474">
                <span className="oknopowiadomi-ebutton-text66">
                  <span>Odrzuć zlecenie</span>
                </span>
              </div>
              <Link
                to="/okn-ooddajzleceniebutton"
                className="oknopowiadomi-ebutton-navlink4"
              >
                <div className="oknopowiadomi-ebutton-group464">
                  <span className="oknopowiadomi-ebutton-text68">
                    <span>Potwierdź zlecenie</span>
                  </span>
                </div>
              </Link>
              <div className="oknopowiadomi-ebutton-group444">
                <div className="oknopowiadomi-ebutton-frame214">
                  <img
                    alt="Ellipse116633"
                    src="/external/ellipse116633-4t09-200h.png"
                    className="oknopowiadomi-ebutton-ellipse114"
                  />
                  <img
                    alt="Ellipse126633"
                    src="/external/ellipse126633-ls3i-200h.png"
                    className="oknopowiadomi-ebutton-ellipse124"
                  />
                  <img
                    alt="Ellipse136633"
                    src="/external/ellipse136633-a9v-200h.png"
                    className="oknopowiadomi-ebutton-ellipse134"
                  />
                </div>
              </div>
            </div>
            <img
              alt="Rectangle226633"
              src="/external/rectangle226633-blu-200w.png"
              className="oknopowiadomi-ebutton-rectangle224"
            />
            <img
              alt="pngwing16633"
              src="/external/pngwing16633-2i8f-200w.png"
              className="oknopowiadomi-ebutton-pngwing14"
            />
            <span className="oknopowiadomi-ebutton-text70">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle736634"
              src="/external/rectangle736634-heu9-200w.png"
              className="oknopowiadomi-ebutton-rectangle734"
            />
            <span className="oknopowiadomi-ebutton-text72">
              <span>Nowe zlecenie</span>
            </span>
            <span className="oknopowiadomi-ebutton-text74">
              <span>RYSUNEK KOTA W SUKIENCE #RYSUNEK #DIGITAL ART</span>
            </span>
            <span className="oknopowiadomi-ebutton-text76">
              <span>150zł</span>
            </span>
            <span className="oknopowiadomi-ebutton-text78">
              <span>NARYSUJ KOTA w suk...</span>
            </span>
          </div>
          <img
            alt="Rectangle741007"
            src="/external/rectangle741007-rqdb-200h.png"
            className="oknopowiadomi-ebutton-rectangle74"
          />
          <img
            alt="Rectangle751008"
            src="/external/rectangle751008-gyqq-200h.png"
            className="oknopowiadomi-ebutton-rectangle75"
          />
          <Link to="/" className="oknopowiadomi-ebutton-navlink5">
            <img
              alt="LGOOROBOCZEV00112077"
              src="/external/lgooroboczev00112077-km6-200h.png"
              className="oknopowiadomi-ebutton-lgooroboczev0011"
            />
          </Link>
        </button>
      </div>
    </div>
  )
}

export default OKNOPOWIADOMIEbutton
