import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './t-rwajcezleceni-abutton1.css'

const TRWAJCEZLECENIAbutton1 = (props) => {
  return (
    <div className="trwajcezleceni-abutton1-container">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="trwajcezleceni-abutton1-dummy-container">
        <button className="trwajcezleceni-abutton1trwajcezleceni-abutton">
          <div className="trwajcezleceni-abutton1-group155">
            <img
              alt="Rectangle933201"
              src="/external/rectangle933201-22ro-600w.png"
              className="trwajcezleceni-abutton1-rectangle93"
            />
            <img
              alt="Rectangle943201"
              src="/external/rectangle943201-5kfk-600w.png"
              className="trwajcezleceni-abutton1-rectangle94"
            />
          </div>
          <img
            alt="Rectangle712327"
            src="/external/rectangle712327-njdq-1000h.png"
            className="trwajcezleceni-abutton1-rectangle71"
          />
          <img
            alt="Rectangle702327"
            src="/external/rectangle702327-oy1m-200h.png"
            className="trwajcezleceni-abutton1-rectangle70"
          />
          <img
            alt="LGOOROBOCZEV00112327"
            src="/external/lgooroboczev00112327-0ivi-200h.png"
            className="trwajcezleceni-abutton1lgooroboczev0011"
          />
          <div className="trwajcezleceni-abutton1-frame19">
            <Link
              to="/u-stawieniakont-abutton"
              className="trwajcezleceni-abutton1-text10"
            >
              <span>Konto</span>
            </Link>
            <span className="trwajcezleceni-abutton1-text12">
              <span>Wiadomości</span>
            </span>
            <span className="trwajcezleceni-abutton1-text14">
              <span>Zamówienia</span>
            </span>
            <span className="trwajcezleceni-abutton1-text16">
              <span>Powiadomienia</span>
            </span>
            <span className="trwajcezleceni-abutton1-text18">
              <span>Zlecenia</span>
            </span>
          </div>
          <img
            alt="image122327"
            src="/external/image122327-ipbap-200h.png"
            className="trwajcezleceni-abutton1-image12"
          />
          <span className="trwajcezleceni-abutton1-text20">
            <span>Pan Twarug</span>
          </span>
          <img
            alt="Rectangle122327"
            src="/external/rectangle122327-wm7v-200h.png"
            className="trwajcezleceni-abutton1-rectangle12"
          />
          <img
            alt="Rectangle722329"
            src="/external/rectangle722329-fv3-200h.png"
            className="trwajcezleceni-abutton1-rectangle72"
          />
          <img
            alt="Rectangle232327"
            src="/external/rectangle232327-cmcs-200h.png"
            className="trwajcezleceni-abutton1-rectangle23"
          />
          <img
            alt="image132327"
            src="/external/image132327-ackd-200h.png"
            className="trwajcezleceni-abutton1-image13"
          />
          <div className="trwajcezleceni-abutton1-frame9">
            <span className="trwajcezleceni-abutton1-text22">
              <span>Q&amp;A</span>
            </span>
          </div>
          <img
            alt="Ellipse232327"
            src="/external/ellipse232327-uooe-200h.png"
            className="trwajcezleceni-abutton1-ellipse23"
          />
          <span className="trwajcezleceni-abutton1-text24">
            <span>Trwające zlecenia</span>
          </span>
          <span className="trwajcezleceni-abutton1-text26">
            <span>Zakończone zlecenia</span>
          </span>
          <div className="trwajcezleceni-abutton1-group99">
            <div className="trwajcezleceni-abutton1-frame681">
              <button className="trwajcezleceni-abutton1-button1">
                <span className="trwajcezleceni-abutton1-text28 SingleLineBodyBase">
                  <span>Oddaj zlecenie</span>
                </span>
              </button>
            </div>
            <div className="trwajcezleceni-abutton1-frame771">
              <button className="trwajcezleceni-abutton1-button2">
                <span className="trwajcezleceni-abutton1-text30 SingleLineBodyBase">
                  <span>Otwórz Briff</span>
                </span>
              </button>
            </div>
            <div className="trwajcezleceni-abutton1-frame761">
              <div className="trwajcezleceni-abutton1-frame731">
                <div className="trwajcezleceni-abutton1-frame3d-avatars151">
                  <img
                    alt="Avatars3davatar15I232"
                    src="/external/avatars3davatar15i232-od-200h.png"
                    className="trwajcezleceni-abutton1-avatars3davatar151"
                  />
                </div>
                <span className="trwajcezleceni-abutton1-text32">
                  <span>Dupcyper</span>
                </span>
              </div>
              <div className="trwajcezleceni-abutton1-frame751">
                <span className="trwajcezleceni-abutton1-text34">
                  <span>Wykonaj Ahegao</span>
                </span>
                <div className="trwajcezleceni-abutton1-frame741">
                  <div className="trwajcezleceni-abutton1-group841">
                    <span className="trwajcezleceni-abutton1-text36">
                      <span>LOGO</span>
                    </span>
                  </div>
                  <div className="trwajcezleceni-abutton1-group871">
                    <span className="trwajcezleceni-abutton1-text38">
                      <span>Design grafika</span>
                    </span>
                  </div>
                  <div className="trwajcezleceni-abutton1-group861">
                    <span className="trwajcezleceni-abutton1-text40">
                      <span>Mascot</span>
                    </span>
                  </div>
                </div>
                <span className="trwajcezleceni-abutton1-text42">
                  <span>Ahegao co by tu mówić więcej</span>
                </span>
                <div className="trwajcezleceni-abutton1-frame721">
                  <div className="trwajcezleceni-abutton1-frame701">
                    <img
                      alt="Dollarsign2328"
                      src="/external/dollarsign2328-wya8.svg"
                      className="trwajcezleceni-abutton1-dollarsign1"
                    />
                    <span className="trwajcezleceni-abutton1-text44">
                      <span>Budżet: 20,00 zł</span>
                    </span>
                  </div>
                  <div className="trwajcezleceni-abutton1-frame711">
                    <div className="trwajcezleceni-abutton1-person1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="trwajcezleceni-abutton1-group100">
            <div className="trwajcezleceni-abutton1-frame772">
              <button className="trwajcezleceni-abutton1-button3">
                <span className="trwajcezleceni-abutton1-text46 SingleLineBodyBase">
                  <span>Otwórz Briff</span>
                </span>
              </button>
            </div>
            <div className="trwajcezleceni-abutton1-frame762">
              <div className="trwajcezleceni-abutton1-frame732">
                <div className="trwajcezleceni-abutton1-frame3d-avatars152">
                  <img
                    alt="Avatars3davatar15I232"
                    src="/external/avatars3davatar15i232-cz9f-200h.png"
                    className="trwajcezleceni-abutton1-avatars3davatar152"
                  />
                </div>
                <span className="trwajcezleceni-abutton1-text48">
                  <span>Dupcyper</span>
                </span>
              </div>
              <div className="trwajcezleceni-abutton1-frame752">
                <span className="trwajcezleceni-abutton1-text50">
                  <span>Wykonaj Logo lodów</span>
                </span>
                <div className="trwajcezleceni-abutton1-frame742">
                  <div className="trwajcezleceni-abutton1-group842">
                    <span className="trwajcezleceni-abutton1-text52">
                      <span>LOGO</span>
                    </span>
                  </div>
                  <div className="trwajcezleceni-abutton1-group872">
                    <span className="trwajcezleceni-abutton1-text54">
                      <span>Design grafika</span>
                    </span>
                  </div>
                  <div className="trwajcezleceni-abutton1-group862">
                    <span className="trwajcezleceni-abutton1-text56">
                      <span>Mascot</span>
                    </span>
                  </div>
                </div>
                <span className="trwajcezleceni-abutton1-text58">
                  <span>Lody Lody Lody z poznania! loda zrób loda daj</span>
                </span>
                <div className="trwajcezleceni-abutton1-frame722">
                  <div className="trwajcezleceni-abutton1-frame702">
                    <img
                      alt="Dollarsign2329"
                      src="/external/dollarsign2329-ikei.svg"
                      className="trwajcezleceni-abutton1-dollarsign2"
                    />
                    <span className="trwajcezleceni-abutton1-text60">
                      <span>Budżet: 200 zł</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="trwajcezleceni-abutton1-group101">
            <div className="trwajcezleceni-abutton1-frame682">
              <button className="trwajcezleceni-abutton1-button4">
                <span className="trwajcezleceni-abutton1-text62 SingleLineBodyBase">
                  <span>Oddaj zlecenie</span>
                </span>
              </button>
            </div>
            <div className="trwajcezleceni-abutton1-frame773">
              <button className="trwajcezleceni-abutton1-button5">
                <span className="trwajcezleceni-abutton1-text64 SingleLineBodyBase">
                  <span>Otwórz Briff</span>
                </span>
              </button>
            </div>
            <div className="trwajcezleceni-abutton1-frame763">
              <div className="trwajcezleceni-abutton1-frame733">
                <div className="trwajcezleceni-abutton1-frame3d-avatars153">
                  <img
                    alt="Avatars3davatar15I232"
                    src="/external/avatars3davatar15i232-stiu-200h.png"
                    className="trwajcezleceni-abutton1-avatars3davatar153"
                  />
                </div>
                <span className="trwajcezleceni-abutton1-text66">
                  <span>Dupcyper</span>
                </span>
              </div>
              <div className="trwajcezleceni-abutton1-frame753">
                <span className="trwajcezleceni-abutton1-text68">
                  <span>Wykonaj Ahegao</span>
                </span>
                <div className="trwajcezleceni-abutton1-frame743">
                  <div className="trwajcezleceni-abutton1-group843">
                    <span className="trwajcezleceni-abutton1-text70">
                      <span>LOGO</span>
                    </span>
                  </div>
                  <div className="trwajcezleceni-abutton1-group873">
                    <span className="trwajcezleceni-abutton1-text72">
                      <span>Design grafika</span>
                    </span>
                  </div>
                  <div className="trwajcezleceni-abutton1-group863">
                    <span className="trwajcezleceni-abutton1-text74">
                      <span>Mascot</span>
                    </span>
                  </div>
                </div>
                <span className="trwajcezleceni-abutton1-text76">
                  <span>Ahegao co by tu mówić więcej</span>
                </span>
                <div className="trwajcezleceni-abutton1-frame723">
                  <div className="trwajcezleceni-abutton1-frame703">
                    <img
                      alt="Dollarsign2329"
                      src="/external/dollarsign2329-3yo5r.svg"
                      className="trwajcezleceni-abutton1-dollarsign3"
                    />
                    <span className="trwajcezleceni-abutton1-text78">
                      <span>Budżet: 20,00 zł</span>
                    </span>
                  </div>
                  <div className="trwajcezleceni-abutton1-frame712">
                    <div className="trwajcezleceni-abutton1-person2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="trwajcezleceni-abutton1-frame162">
            <span className="trwajcezleceni-abutton1-text80">!</span>
            <span className="trwajcezleceni-abutton1-text81">
              <span>Czeka na potwierdzenie zlecenia zł</span>
            </span>
            <span className="trwajcezleceni-abutton1-text83">!</span>
          </div>
          <div className="trwajcezleceni-abutton1-group56">
            <div className="trwajcezleceni-abutton1-group441">
              <div className="trwajcezleceni-abutton1-frame211">
                <img
                  alt="Ellipse112321"
                  src="/external/ellipse112321-trbg-200h.png"
                  className="trwajcezleceni-abutton1-ellipse111"
                />
                <img
                  alt="Ellipse122321"
                  src="/external/ellipse122321-smuk-200h.png"
                  className="trwajcezleceni-abutton1-ellipse121"
                />
                <img
                  alt="Ellipse132321"
                  src="/external/ellipse132321-vga-200h.png"
                  className="trwajcezleceni-abutton1-ellipse131"
                />
              </div>
            </div>
            <img
              alt="Rectangle222321"
              src="/external/rectangle222321-ypct-200w.png"
              className="trwajcezleceni-abutton1-rectangle221"
            />
            <img
              alt="pngwing12321"
              src="/external/pngwing12321-v53c-200w.png"
              className="trwajcezleceni-abutton1-pngwing11"
            />
            <span className="trwajcezleceni-abutton1-text84">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle732321"
              src="/external/rectangle732321-1oon-200w.png"
              className="trwajcezleceni-abutton1-rectangle731"
            />
            <span className="trwajcezleceni-abutton1-text86">
              <span>Zlecenie 3415: Dupp</span>
            </span>
          </div>
          <div className="trwajcezleceni-abutton1-group102">
            <div className="trwajcezleceni-abutton1-group442">
              <div className="trwajcezleceni-abutton1-frame212">
                <img
                  alt="Ellipse112321"
                  src="/external/ellipse112321-hc72-200h.png"
                  className="trwajcezleceni-abutton1-ellipse112"
                />
                <img
                  alt="Ellipse122321"
                  src="/external/ellipse122321-727p-200h.png"
                  className="trwajcezleceni-abutton1-ellipse122"
                />
                <img
                  alt="Ellipse132321"
                  src="/external/ellipse132321-sj8p-200h.png"
                  className="trwajcezleceni-abutton1-ellipse132"
                />
              </div>
            </div>
            <img
              alt="Rectangle222321"
              src="/external/rectangle222321-uol9-200w.png"
              className="trwajcezleceni-abutton1-rectangle222"
            />
            <img
              alt="pngwing12321"
              src="/external/pngwing12321-yg2-200w.png"
              className="trwajcezleceni-abutton1-pngwing12"
            />
            <span className="trwajcezleceni-abutton1-text88">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle732321"
              src="/external/rectangle732321-smm-200w.png"
              className="trwajcezleceni-abutton1-rectangle732"
            />
            <span className="trwajcezleceni-abutton1-text90">
              <span>Zlecenie 3415: Dupp</span>
            </span>
          </div>
          <div className="trwajcezleceni-abutton1-group103">
            <div className="trwajcezleceni-abutton1-group443">
              <div className="trwajcezleceni-abutton1-frame213">
                <img
                  alt="Ellipse112321"
                  src="/external/ellipse112321-zl0y-200h.png"
                  className="trwajcezleceni-abutton1-ellipse113"
                />
                <img
                  alt="Ellipse122321"
                  src="/external/ellipse122321-ony3-200h.png"
                  className="trwajcezleceni-abutton1-ellipse123"
                />
                <img
                  alt="Ellipse132321"
                  src="/external/ellipse132321-g9s-200h.png"
                  className="trwajcezleceni-abutton1-ellipse133"
                />
              </div>
            </div>
            <img
              alt="Rectangle222321"
              src="/external/rectangle222321-y01-200h.png"
              className="trwajcezleceni-abutton1-rectangle223"
            />
            <img
              alt="pngwing12321"
              src="/external/pngwing12321-4uck-200h.png"
              className="trwajcezleceni-abutton1-pngwing13"
            />
            <span className="trwajcezleceni-abutton1-text92">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle732321"
              src="/external/rectangle732321-dobp-200w.png"
              className="trwajcezleceni-abutton1-rectangle733"
            />
            <span className="trwajcezleceni-abutton1-text94">
              <span>Zlecenie 3415: Dupp</span>
            </span>
          </div>
        </button>
      </div>
    </div>
  )
}

export default TRWAJCEZLECENIAbutton1
