import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './communityartystycznebutton.css'

const Communityartystycznebutton = (props) => {
  return (
    <div className="communityartystycznebutton-container1">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="communityartystycznebutton-dummy-container">
        <button className="communityartystycznebutton-communityartystycznebutton">
          <div className="communityartystycznebutton-group153">
            <img
              alt="Rectangle933201"
              src="/external/rectangle933201-xg37-600w.png"
              className="communityartystycznebutton-rectangle93"
            />
            <img
              alt="Rectangle1053201"
              src="/external/rectangle1053201-rfzb-600w.png"
              className="communityartystycznebutton-rectangle105"
            />
            <img
              alt="Rectangle1063201"
              src="/external/rectangle1063201-hhyp-600w.png"
              className="communityartystycznebutton-rectangle106"
            />
          </div>
          <div className="communityartystycznebutton-group115">
            <div className="communityartystycznebutton-frame1111">
              <Link to="/" className="communityartystycznebutton-navlink1">
                <img
                  alt="LGOOROBOCZEV00112791"
                  src="/external/lgooroboczev00112791-cunv-200h.png"
                  className="communityartystycznebutton-lgooroboczev0011"
                />
              </Link>
              <div className="communityartystycznebutton-group114">
                <div className="communityartystycznebutton-input">
                  <div className="communityartystycznebutton-container2">
                    <span className="communityartystycznebutton-text100">
                      <span>Czego szukasz towarzyszu?</span>
                    </span>
                  </div>
                </div>
                <button className="communityartystycznebutton-button1">
                  <img
                    alt="SVG2791"
                    src="/external/svg2791-qysl.svg"
                    className="communityartystycznebutton-svg1"
                  />
                </button>
              </div>
              <button className="communityartystycznebutton-item-button">
                <Link
                  to="/communityartystycznebutton"
                  className="communityartystycznebutton-navlink2"
                >
                  <div className="communityartystycznebutton-group112">
                    <span className="communityartystycznebutton-text102">
                      <span>Społeczność Artystyczna</span>
                    </span>
                    <img
                      alt="Vector2791"
                      src="/external/vector2791-dh.svg"
                      className="communityartystycznebutton-vector1"
                    />
                  </div>
                </Link>
              </button>
              <div className="communityartystycznebutton-group113">
                <span className="communityartystycznebutton-text104">
                  <span>Q&amp;A</span>
                </span>
                <img
                  alt="Vector2791"
                  src="/external/vector2791-vjpb.svg"
                  className="communityartystycznebutton-vector2"
                />
              </div>
              <div className="communityartystycznebutton-item-link10">
                <Link
                  to="/logowani-ebutton"
                  className="communityartystycznebutton-text106"
                >
                  <span>Sign in</span>
                </Link>
              </div>
              <div className="communityartystycznebutton-item-link11">
                <Link
                  to="/logowani-ebutton"
                  className="communityartystycznebutton-text108"
                >
                  <span>Join</span>
                </Link>
              </div>
            </div>
          </div>
          <img
            alt="Rectangle1012791"
            src="/external/rectangle1012791-dikc-200h.png"
            className="communityartystycznebutton-rectangle101"
          />
          <div className="communityartystycznebutton-frame1112">
            <Link
              to="/podstrona-grafikbutton"
              className="communityartystycznebutton-text110"
            >
              <span>Cosplay</span>
            </Link>
            <Link
              to="/podstrona-grafikbutton"
              className="communityartystycznebutton-text112"
            >
              <span>Pisarz</span>
            </Link>
            <Link
              to="/podstrona-grafikbutton"
              className="communityartystycznebutton-text114"
            >
              <span>Muzyka&amp;audio</span>
            </Link>
            <Link
              to="/podstrona-grafikbutton"
              className="communityartystycznebutton-text116"
            >
              <span>Rysownik</span>
            </Link>
            <Link
              to="/podstrona-grafikbutton"
              className="communityartystycznebutton-text118"
            >
              <span>UI/Ux Design</span>
            </Link>
            <Link
              to="/podstrona-grafikbutton"
              className="communityartystycznebutton-text120"
            >
              <span>Grafika Komputerowa</span>
            </Link>
          </div>
          <img
            alt="Rectangle1032791"
            src="/external/rectangle1032791-qr1b-200h.png"
            className="communityartystycznebutton-rectangle103"
          />
          <div className="communityartystycznebutton-frame163">
            <span className="communityartystycznebutton-text122">
              <span>Społeczność Artystyczna</span>
            </span>
            <span className="communityartystycznebutton-text124">
              <span className="communityartystycznebutton-text125">
                Dołącz Do największego artystycznego serwera czyli
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>Harem Artystyczny</span>
            </span>
          </div>
          <img
            alt="LOGOKURDEHAREM13208"
            src="/external/logokurdeharem13208-ioqd-500h.png"
            className="communityartystycznebutton-logokurdeharem1"
          />
          <div className="communityartystycznebutton-frame164">
            <span className="communityartystycznebutton-text127">
              <span>Witaj w Naszym Artystycznym Świecie!</span>
            </span>
            <span className="communityartystycznebutton-text129">
              <span>
                Czy marzysz o miejscu, gdzie Twoja kreatywność rozkwitnie, a
                pasja znajdzie zrozumienie?
              </span>
              <br></br>
              <span>
                {' '}
                Polska Społeczność Artystyczna to właśnie takie miejsce!
                Jesteśmy dynamiczną i pełną energii grupą artystów z całej
                Polski, połączonych miłością do sztuki we wszystkich jej
                formach.
              </span>
            </span>
          </div>
          <div className="communityartystycznebutton-group150">
            <span className="communityartystycznebutton-text133">
              <span className="communityartystycznebutton-text134">
                {' '}
                Co Oferujemy? 
              </span>
              <br></br>
              <span className="communityartystycznebutton-text136"></span>
              <span>
                Inspirację bez granic: Codzienne wyzwania artystyczne, galerie
                członków i dyskusje, które rozpalą Twoją wyobraźnię.
              </span>
              <br></br>
              <span>
                Wsparcie na każdym kroku: Czy jesteś początkującym, czy
                doświadczonym artystą, znajdziesz tu mentorów i przyjaciół
                gotowych Cię wspierać.
              </span>
              <br></br>
              <span>
                Spotkania, które inspirują: Regularne warsztaty online i
                offline, gdzie możesz uczyć się nowych technik i dzielić się
                swoimi umiejętnościami.
              </span>
              <br></br>
              <span>
                Możliwości rozwoju: Organizujemy wystawy, konkursy i współprace,
                które pomogą Ci rozwinąć skrzydła w świecie sztuki.
              </span>
              <br></br>
              <span>
                Społeczność, która rozumie: Miejsce, gdzie Twoje artystyczne
                rozterki znajdą zrozumienie, a sukcesy - gromkie brawa.
              </span>
            </span>
          </div>
          <span className="communityartystycznebutton-text146">
            <span>
              Dołączając do nas, stajesz się częścią czegoś większego. To nie
              tylko grupa - to ruch, który kształtuje przyszłość polskiej
              sztuki. Każdy głos się liczy, każda kreska ma znaczenie, każdy
              pomysł może zmienić świat.
            </span>
            <br></br>
            <span>
              Nie czekaj - Twoja artystyczna przygoda zaczyna się teraz! Kliknij
              poniższy przycisk i dołącz do nas na Discordzie. Otwórz drzwi do
              świata pełnego kolorów, kształtów i nieograniczonych możliwości!
            </span>
            <br></br>
            <span></span>
          </span>
          <button className="communityartystycznebutton-button2">
            <a
              href="https://discord.gg/harem-artystyczny-436961732754407445"
              target="_blank"
              rel="noreferrer noopener"
              className="communityartystycznebutton-text152"
            >
              <span>DOŁĄCZ DO Haremu Artystycznego NA DISCORD!</span>
            </a>
          </button>
          <div className="communityartystycznebutton-frame129">
            <img
              alt="IMAGE636e0a6a49cf127bf92de1e2iconclydeblurpleRGB13208"
              src="/external/image636e0a6a49cf127bf92de1e2iconclydeblurplergb13208-owcm-200h.png"
              className="communityartystycznebutton-image636e0a6a49cf127bf92de1e2iconclydeblurple-rgb1"
            />
            <img
              alt="SVG3208"
              src="/external/svg3208-hoc7.svg"
              className="communityartystycznebutton-svg2"
            />
            <img
              alt="SVG3208"
              src="/external/svg3208-ugs.svg"
              className="communityartystycznebutton-svg3"
            />
            <img
              alt="SVG3208"
              src="/external/svg3208-yx2.svg"
              className="communityartystycznebutton-svg4"
            />
            <img
              alt="SVG3208"
              src="/external/svg3208-y62.svg"
              className="communityartystycznebutton-svg5"
            />
          </div>
          <div className="communityartystycznebutton-frame130">
            <div className="communityartystycznebutton-frame127">
              <span className="communityartystycznebutton-text154">
                <span>Kategorie</span>
              </span>
              <div className="communityartystycznebutton-frame120">
                <div className="communityartystycznebutton-item-link12">
                  <Link
                    to="/podstrona-grafikbutton"
                    className="communityartystycznebutton-text156"
                  >
                    <span>Graphics &amp; Design</span>
                  </Link>
                </div>
                <div className="communityartystycznebutton-item-link13">
                  <Link
                    to="/podstrona-grafikbutton"
                    className="communityartystycznebutton-text158"
                  >
                    <span>UI UX</span>
                  </Link>
                </div>
                <div className="communityartystycznebutton-item-link14">
                  <Link
                    to="/podstrona-grafikbutton"
                    className="communityartystycznebutton-text160"
                  >
                    <span>Pisarstwo</span>
                  </Link>
                </div>
                <div className="communityartystycznebutton-item-link15">
                  <Link
                    to="/podstrona-grafikbutton"
                    className="communityartystycznebutton-text162"
                  >
                    <span>Animacja i montaż</span>
                  </Link>
                </div>
                <div className="communityartystycznebutton-item-link16">
                  <Link
                    to="/podstrona-grafikbutton"
                    className="communityartystycznebutton-text164"
                  >
                    <span>Audio i muzyka</span>
                  </Link>
                </div>
                <div className="communityartystycznebutton-item-link17">
                  <span className="communityartystycznebutton-text166">
                    <span>Cosplay</span>
                  </span>
                </div>
                <div className="communityartystycznebutton-item-link18">
                  <span className="communityartystycznebutton-text168">
                    <span>Photography</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="communityartystycznebutton-frame126">
              <span className="communityartystycznebutton-text170">
                <span>O nas</span>
              </span>
              <div className="communityartystycznebutton-frame121">
                <span className="communityartystycznebutton-text172">
                  <span>Polityka prywatności</span>
                </span>
                <div className="communityartystycznebutton-item-link19">
                  <span className="communityartystycznebutton-text174">
                    <span>Terms of Service</span>
                  </span>
                </div>
                <span className="communityartystycznebutton-text176">
                  <span>RODO</span>
                </span>
              </div>
            </div>
            <div className="communityartystycznebutton-frame124">
              <span className="communityartystycznebutton-text178">
                <span>Support i edukacja</span>
              </span>
              <div className="communityartystycznebutton-frame122">
                <a
                  href="https://zg"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="communityartystycznebutton-text180"
                >
                  <span>Pomoc i support</span>
                </a>
                <span className="communityartystycznebutton-text182">
                  <span>Trust &amp; Safety</span>
                </span>
                <span className="communityartystycznebutton-text184">
                  <span>Przewodnik</span>
                </span>
                <span className="communityartystycznebutton-text186">
                  <span>Sprzedaż na Underrate</span>
                </span>
                <span className="communityartystycznebutton-text188">
                  <span>Kupowanie na underrate</span>
                </span>
              </div>
            </div>
            <div className="communityartystycznebutton-frame125">
              <span className="communityartystycznebutton-text190">
                <span>Społeczność</span>
              </span>
              <div className="communityartystycznebutton-frame123">
                <span className="communityartystycznebutton-text192">
                  <span>Community Artystyczne</span>
                </span>
                <span className="communityartystycznebutton-text194">
                  <span>Invite a Friend</span>
                </span>
                <span className="communityartystycznebutton-text196">
                  <span>Become a Seller</span>
                </span>
                <span className="communityartystycznebutton-text198">
                  <span>Standardy społeczności</span>
                </span>
              </div>
            </div>
          </div>
          <img
            alt="HorizontalDivider3209"
            src="/external/horizontaldivider3209-5da3-200h.png"
            className="communityartystycznebutton-horizontal-divider"
          />
          <span className="communityartystycznebutton-text200">
            <span>Razem tworzymy przyszłość polskiej sztuki!</span>
          </span>
        </button>
      </div>
    </div>
  )
}

export default Communityartystycznebutton
