import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './ekran-zleceotwartychbutton.css'

const EkranZleceotwartychbutton = (props) => {
  return (
    <div className="ekran-zleceotwartychbutton-container10">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="ekran-zleceotwartychbutton-dummy-container">
        <button className="ekran-zleceotwartychbutton-ekran-zleceotwartychbutton">
          <div className="ekran-zleceotwartychbutton-group155">
            <img
              alt="Rectangle933201"
              src="/external/rectangle933201-f0eb-600w.png"
              className="ekran-zleceotwartychbutton-rectangle93"
            />
            <img
              alt="Rectangle943201"
              src="/external/rectangle943201-9z54-600w.png"
              className="ekran-zleceotwartychbutton-rectangle94"
            />
          </div>
          <Link to="/" className="ekran-zleceotwartychbutton-navlink">
            <img
              alt="LGOOROBOCZEV00112251"
              src="/external/lgooroboczev00112251-x4qem-200h.png"
              className="ekran-zleceotwartychbutton-lgooroboczev0011"
            />
          </Link>
          <div className="ekran-zleceotwartychbutton-frame9">
            <Link
              to="/t-rwajcezleceni-abutton"
              className="ekran-zleceotwartychbutton-text100"
            >
              <span>Zlecenia otwarte</span>
            </Link>
            <span className="ekran-zleceotwartychbutton-text102">
              <span>Q&amp;A</span>
            </span>
            <Link
              to="/logowani-ebutton"
              className="ekran-zleceotwartychbutton-text104"
            >
              <span>Zarejestruj się</span>
            </Link>
            <div className="ekran-zleceotwartychbutton-group40">
              <Link
                to="/logowani-ebutton"
                className="ekran-zleceotwartychbutton-text106"
              >
                <span>Dołącz</span>
              </Link>
            </div>
          </div>
          <div className="ekran-zleceotwartychbutton-pagination">
            <div className="ekran-zleceotwartychbutton-pagination-previous">
              <img
                alt="ArrowleftI225"
                src="/external/arrowlefti225-oji.svg"
                className="ekran-zleceotwartychbutton-arrowleft"
              />
              <span className="ekran-zleceotwartychbutton-text108 SingleLineBodyBase">
                <span>Previous</span>
              </span>
            </div>
            <div className="ekran-zleceotwartychbutton-pagination-list1">
              <div className="ekran-zleceotwartychbutton-pagination-page10">
                <span className="ekran-zleceotwartychbutton-text110 SingleLineBodyBase">
                  1
                </span>
              </div>
              <div className="ekran-zleceotwartychbutton-pagination-page11">
                <span className="ekran-zleceotwartychbutton-text111 SingleLineBodyBase">
                  2
                </span>
              </div>
              <div className="ekran-zleceotwartychbutton-pagination-page12">
                <span className="ekran-zleceotwartychbutton-text112 SingleLineBodyBase">
                  3
                </span>
              </div>
              <div className="ekran-zleceotwartychbutton-pagination-gap1">
                <span className="ekran-zleceotwartychbutton-text113 BodyBaseBold">
                  <span>...</span>
                </span>
              </div>
              <div className="ekran-zleceotwartychbutton-pagination-page13">
                <span className="ekran-zleceotwartychbutton-text115 SingleLineBodyBase">
                  <span>67</span>
                </span>
              </div>
              <div className="ekran-zleceotwartychbutton-pagination-page14">
                <span className="ekran-zleceotwartychbutton-text117 SingleLineBodyBase">
                  <span>68</span>
                </span>
              </div>
            </div>
            <div className="ekran-zleceotwartychbutton-pagination-next">
              <span className="ekran-zleceotwartychbutton-text119 SingleLineBodyBase">
                <span>Next</span>
              </span>
              <img
                alt="ArrowrightI225"
                src="/external/arrowrighti225-6dld.svg"
                className="ekran-zleceotwartychbutton-arrowright"
              />
            </div>
          </div>
          <div className="ekran-zleceotwartychbutton-pagination-list2">
            <div className="ekran-zleceotwartychbutton-pagination-page15">
              <span className="ekran-zleceotwartychbutton-text121 SingleLineBodyBase">
                1
              </span>
            </div>
            <div className="ekran-zleceotwartychbutton-pagination-page16">
              <span className="ekran-zleceotwartychbutton-text122 SingleLineBodyBase">
                2
              </span>
            </div>
            <div className="ekran-zleceotwartychbutton-pagination-page17">
              <span className="ekran-zleceotwartychbutton-text123 SingleLineBodyBase">
                3
              </span>
            </div>
            <div className="ekran-zleceotwartychbutton-pagination-gap2">
              <span className="ekran-zleceotwartychbutton-text124 BodyBaseBold">
                <span>...</span>
              </span>
            </div>
            <div className="ekran-zleceotwartychbutton-pagination-page18">
              <span className="ekran-zleceotwartychbutton-text126 SingleLineBodyBase">
                <span>67</span>
              </span>
            </div>
            <div className="ekran-zleceotwartychbutton-pagination-page19">
              <span className="ekran-zleceotwartychbutton-text128 SingleLineBodyBase">
                <span>68</span>
              </span>
            </div>
          </div>
          <button className="ekran-zleceotwartychbutton-button10">
            <span className="ekran-zleceotwartychbutton-text130 SingleLineBodyBase">
              <span>Od najnowsze....</span>
            </span>
          </button>
          <span className="ekran-zleceotwartychbutton-text132">
            <span>Sortowanie</span>
          </span>
          <div className="ekran-zleceotwartychbutton-group91">
            <img
              alt="Line42251"
              src="/external/line42251-blcx.svg"
              className="ekran-zleceotwartychbutton-line4"
            />
            <img
              alt="Line52251"
              src="/external/line52251-oqf9.svg"
              className="ekran-zleceotwartychbutton-line5"
            />
          </div>
          <div className="ekran-zleceotwartychbutton-frame77">
            <div className="ekran-zleceotwartychbutton-group92">
              <button className="ekran-zleceotwartychbutton-icon-button10">
                <img
                  alt="StarI225"
                  src="/external/stari225-whu.svg"
                  className="ekran-zleceotwartychbutton-star10"
                />
              </button>
              <div className="ekran-zleceotwartychbutton-frame6810">
                <button className="ekran-zleceotwartychbutton-button11">
                  <Link
                    to="/oknozoofer-tbutton"
                    className="ekran-zleceotwartychbutton-text134 SingleLineBodyBase"
                  >
                    <span>Zgłoś ofertę</span>
                  </Link>
                </button>
              </div>
              <div className="ekran-zleceotwartychbutton-frame7610">
                <div className="ekran-zleceotwartychbutton-frame7310">
                  <div className="ekran-zleceotwartychbutton-frame3d-avatars161">
                    <img
                      alt="Avatars3davatar16I225"
                      src="/external/avatars3davatar16i225-xe4-200h.png"
                      className="ekran-zleceotwartychbutton-avatars3davatar161"
                    />
                  </div>
                  <span className="ekran-zleceotwartychbutton-text136">
                    <span>Śmieszek artysta</span>
                  </span>
                </div>
                <div className="ekran-zleceotwartychbutton-frame7510">
                  <span className="ekran-zleceotwartychbutton-text138">
                    <span>Ilustracja Książkowa</span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7410">
                    <div className="ekran-zleceotwartychbutton-group8410">
                      <span className="ekran-zleceotwartychbutton-text140">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8710">
                      <span className="ekran-zleceotwartychbutton-text142">
                        <span>Illustrator</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8610">
                      <span className="ekran-zleceotwartychbutton-text144">
                        <span>Mascot</span>
                      </span>
                    </div>
                  </div>
                  <span className="ekran-zleceotwartychbutton-text146">
                    <span>
                      Szukamy ilustratora do stworzenia 10 ilustracji do książki
                      dla dzieci.
                    </span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7210">
                    <div className="ekran-zleceotwartychbutton-frame7010">
                      <img
                        alt="Dollarsign2251"
                        src="/external/dollarsign2251-zpfu.svg"
                        className="ekran-zleceotwartychbutton-dollarsign10"
                      />
                      <span className="ekran-zleceotwartychbutton-text148">
                        <span>Budżet: 4000,0 zł</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-frame7110">
                      <img
                        alt="person2251"
                        src="/external/person2251-xi7.svg"
                        className="ekran-zleceotwartychbutton-person10"
                      />
                      <span className="ekran-zleceotwartychbutton-text150">
                        <span>Zgłoszenia: 52</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ekran-zleceotwartychbutton-group95">
              <div className="ekran-zleceotwartychbutton-frame6811">
                <Link
                  to="/oknozoofer-tbutton"
                  className="ekran-zleceotwartychbutton-button12"
                >
                  <span className="ekran-zleceotwartychbutton-text152 SingleLineBodyBase">
                    <span>Zgłoś ofertę</span>
                  </span>
                </Link>
              </div>
              <div className="ekran-zleceotwartychbutton-frame7611">
                <div className="ekran-zleceotwartychbutton-frame7311">
                  <div className="ekran-zleceotwartychbutton-frame3d-avatars151">
                    <img
                      alt="Avatars3davatar15I230"
                      src="/external/avatars3davatar15i230-eww7-200h.png"
                      className="ekran-zleceotwartychbutton-avatars3davatar151"
                    />
                  </div>
                  <span className="ekran-zleceotwartychbutton-text154">
                    <span>Dupcyper</span>
                  </span>
                </div>
                <div className="ekran-zleceotwartychbutton-frame7511">
                  <span className="ekran-zleceotwartychbutton-text156">
                    <span>Wykonaj Ahegao</span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7411">
                    <div className="ekran-zleceotwartychbutton-group8411">
                      <span className="ekran-zleceotwartychbutton-text158">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8711">
                      <span className="ekran-zleceotwartychbutton-text160">
                        <span>Design grafika</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8611">
                      <span className="ekran-zleceotwartychbutton-text162">
                        <span>Mascot</span>
                      </span>
                    </div>
                  </div>
                  <span className="ekran-zleceotwartychbutton-text164">
                    <span>Ahegao co by tu mówić więcej</span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7211">
                    <div className="ekran-zleceotwartychbutton-frame7011">
                      <img
                        alt="Dollarsign2302"
                        src="/external/dollarsign2302-8q9o.svg"
                        className="ekran-zleceotwartychbutton-dollarsign11"
                      />
                      <span className="ekran-zleceotwartychbutton-text166">
                        <span>Budżet: 20,00 zł</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-frame7111">
                      <img
                        alt="person2302"
                        src="/external/person2302-gh2g.svg"
                        className="ekran-zleceotwartychbutton-person11"
                      />
                      <span className="ekran-zleceotwartychbutton-text168">
                        <span>Zgłoszenia: 521</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <button className="ekran-zleceotwartychbutton-icon-button11">
                <img
                  alt="StarI230"
                  src="/external/stari230-tb5y.svg"
                  className="ekran-zleceotwartychbutton-star11"
                />
              </button>
            </div>
            <div className="ekran-zleceotwartychbutton-group94">
              <button className="ekran-zleceotwartychbutton-icon-button12">
                <img
                  alt="StarI230"
                  src="/external/stari230-8546.svg"
                  className="ekran-zleceotwartychbutton-star12"
                />
              </button>
              <div className="ekran-zleceotwartychbutton-frame6812">
                <Link
                  to="/oknozoofer-tbutton"
                  className="ekran-zleceotwartychbutton-button13"
                >
                  <span className="ekran-zleceotwartychbutton-text170 SingleLineBodyBase">
                    <span>Zgłoś ofertę</span>
                  </span>
                </Link>
              </div>
              <div className="ekran-zleceotwartychbutton-frame7612">
                <div className="ekran-zleceotwartychbutton-frame7312">
                  <div className="ekran-zleceotwartychbutton-frame3d-avatars221">
                    <img
                      alt="Avatars3davatar22I230"
                      src="/external/avatars3davatar22i230-9qbm-200h.png"
                      className="ekran-zleceotwartychbutton-avatars3davatar221"
                    />
                  </div>
                  <span className="ekran-zleceotwartychbutton-text172">
                    <span>Andrzej Psikuta</span>
                  </span>
                </div>
                <div className="ekran-zleceotwartychbutton-frame7512">
                  <span className="ekran-zleceotwartychbutton-text174">
                    <span>Wykonaj logo</span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7412">
                    <div className="ekran-zleceotwartychbutton-group8412">
                      <span className="ekran-zleceotwartychbutton-text176">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8712">
                      <span className="ekran-zleceotwartychbutton-text178">
                        <span>Design grafika</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8612">
                      <span className="ekran-zleceotwartychbutton-text180">
                        <span>Mascot</span>
                      </span>
                    </div>
                  </div>
                  <span className="ekran-zleceotwartychbutton-text182">
                    <span>
                      Poszukujemy kreatywnego artysty do zaprojektowania logo
                      dla nowej marki odzieżowej.
                    </span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7212">
                    <div className="ekran-zleceotwartychbutton-frame7012">
                      <img
                        alt="Dollarsign2302"
                        src="/external/dollarsign2302-9dh.svg"
                        className="ekran-zleceotwartychbutton-dollarsign12"
                      />
                      <span className="ekran-zleceotwartychbutton-text184">
                        <span>Budżet: 500,00 zł</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-frame7112">
                      <img
                        alt="person2302"
                        src="/external/person2302-jby.svg"
                        className="ekran-zleceotwartychbutton-person12"
                      />
                      <span className="ekran-zleceotwartychbutton-text186">
                        <span>Zgłoszenia: 5</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ekran-zleceotwartychbutton-group96">
              <button className="ekran-zleceotwartychbutton-icon-button13">
                <img
                  alt="StarI230"
                  src="/external/stari230-zglc.svg"
                  className="ekran-zleceotwartychbutton-star13"
                />
              </button>
              <div className="ekran-zleceotwartychbutton-frame6813">
                <Link
                  to="/oknozoofer-tbutton"
                  className="ekran-zleceotwartychbutton-button14"
                >
                  <span className="ekran-zleceotwartychbutton-text188 SingleLineBodyBase">
                    <span>Zgłoś ofertę</span>
                  </span>
                </Link>
              </div>
              <div className="ekran-zleceotwartychbutton-frame7613">
                <div className="ekran-zleceotwartychbutton-frame7313">
                  <div className="ekran-zleceotwartychbutton-frame3d-avatars222">
                    <img
                      alt="Avatars3davatar22I230"
                      src="/external/avatars3davatar22i230-lxcw-200h.png"
                      className="ekran-zleceotwartychbutton-avatars3davatar222"
                    />
                  </div>
                  <span className="ekran-zleceotwartychbutton-text190">
                    <span>Andrzej Psikuta</span>
                  </span>
                </div>
                <div className="ekran-zleceotwartychbutton-frame7513">
                  <span className="ekran-zleceotwartychbutton-text192">
                    <span>Wykonaj logo</span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7413">
                    <div className="ekran-zleceotwartychbutton-group8413">
                      <span className="ekran-zleceotwartychbutton-text194">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8713">
                      <span className="ekran-zleceotwartychbutton-text196">
                        <span>Design grafika</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8613">
                      <span className="ekran-zleceotwartychbutton-text198">
                        <span>Mascot</span>
                      </span>
                    </div>
                  </div>
                  <span className="ekran-zleceotwartychbutton-text200">
                    <span>
                      Poszukujemy kreatywnego artysty do zaprojektowania logo
                      dla nowej marki odzieżowej.
                    </span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7213">
                    <div className="ekran-zleceotwartychbutton-frame7013">
                      <img
                        alt="Dollarsign2307"
                        src="/external/dollarsign2307-w30r.svg"
                        className="ekran-zleceotwartychbutton-dollarsign13"
                      />
                      <span className="ekran-zleceotwartychbutton-text202">
                        <span>Budżet: 500,00 zł</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-frame7113">
                      <img
                        alt="person2307"
                        src="/external/person2307-g4qs.svg"
                        className="ekran-zleceotwartychbutton-person13"
                      />
                      <span className="ekran-zleceotwartychbutton-text204">
                        <span>Zgłoszenia: 5</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ekran-zleceotwartychbutton-group97">
              <button className="ekran-zleceotwartychbutton-icon-button14">
                <img
                  alt="StarI230"
                  src="/external/stari230-juxe.svg"
                  className="ekran-zleceotwartychbutton-star14"
                />
              </button>
              <div className="ekran-zleceotwartychbutton-frame6814">
                <Link
                  to="/oknozoofer-tbutton"
                  className="ekran-zleceotwartychbutton-button15"
                >
                  <span className="ekran-zleceotwartychbutton-text206 SingleLineBodyBase">
                    <span>Zgłoś ofertę</span>
                  </span>
                </Link>
              </div>
              <div className="ekran-zleceotwartychbutton-frame7614">
                <div className="ekran-zleceotwartychbutton-frame7314">
                  <div className="ekran-zleceotwartychbutton-frame3d-avatars162">
                    <img
                      alt="Avatars3davatar16I230"
                      src="/external/avatars3davatar16i230-2dm-200h.png"
                      className="ekran-zleceotwartychbutton-avatars3davatar162"
                    />
                  </div>
                  <span className="ekran-zleceotwartychbutton-text208">
                    <span>Śmieszek artysta</span>
                  </span>
                </div>
                <div className="ekran-zleceotwartychbutton-frame7514">
                  <span className="ekran-zleceotwartychbutton-text210">
                    <span>Ilustracja Książkowa</span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7414">
                    <div className="ekran-zleceotwartychbutton-group8414">
                      <span className="ekran-zleceotwartychbutton-text212">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8714">
                      <span className="ekran-zleceotwartychbutton-text214">
                        <span>Illustrator</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8614">
                      <span className="ekran-zleceotwartychbutton-text216">
                        <span>Mascot</span>
                      </span>
                    </div>
                  </div>
                  <span className="ekran-zleceotwartychbutton-text218">
                    <span>
                      Szukamy ilustratora do stworzenia 10 ilustracji do książki
                      dla dzieci.
                    </span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7214">
                    <div className="ekran-zleceotwartychbutton-frame7014">
                      <img
                        alt="Dollarsign2308"
                        src="/external/dollarsign2308-svor.svg"
                        className="ekran-zleceotwartychbutton-dollarsign14"
                      />
                      <span className="ekran-zleceotwartychbutton-text220">
                        <span>Budżet: 4000,0 zł</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-frame7114">
                      <img
                        alt="person2308"
                        src="/external/person2308-zxnw.svg"
                        className="ekran-zleceotwartychbutton-person14"
                      />
                      <span className="ekran-zleceotwartychbutton-text222">
                        <span>Zgłoszenia: 52</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ekran-zleceotwartychbutton-group98">
              <div className="ekran-zleceotwartychbutton-frame6815">
                <Link
                  to="/oknozoofer-tbutton"
                  className="ekran-zleceotwartychbutton-button16"
                >
                  <span className="ekran-zleceotwartychbutton-text224 SingleLineBodyBase">
                    <span>Zgłoś ofertę</span>
                  </span>
                </Link>
              </div>
              <div className="ekran-zleceotwartychbutton-frame7615">
                <div className="ekran-zleceotwartychbutton-frame7315">
                  <div className="ekran-zleceotwartychbutton-frame3d-avatars152">
                    <img
                      alt="Avatars3davatar15I230"
                      src="/external/avatars3davatar15i230-5f9c-200h.png"
                      className="ekran-zleceotwartychbutton-avatars3davatar152"
                    />
                  </div>
                  <span className="ekran-zleceotwartychbutton-text226">
                    <span>Dupcyper</span>
                  </span>
                </div>
                <div className="ekran-zleceotwartychbutton-frame7515">
                  <span className="ekran-zleceotwartychbutton-text228">
                    <span>Wykonaj Ahegao</span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7415">
                    <div className="ekran-zleceotwartychbutton-group8415">
                      <span className="ekran-zleceotwartychbutton-text230">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8715">
                      <span className="ekran-zleceotwartychbutton-text232">
                        <span>Design grafika</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8615">
                      <span className="ekran-zleceotwartychbutton-text234">
                        <span>Mascot</span>
                      </span>
                    </div>
                  </div>
                  <span className="ekran-zleceotwartychbutton-text236">
                    <span>Ahegao co by tu mówić więcej</span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7215">
                    <div className="ekran-zleceotwartychbutton-frame7015">
                      <img
                        alt="Dollarsign2308"
                        src="/external/dollarsign2308-4l0y.svg"
                        className="ekran-zleceotwartychbutton-dollarsign15"
                      />
                      <span className="ekran-zleceotwartychbutton-text238">
                        <span>Budżet: 20,00 zł</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-frame7115">
                      <img
                        alt="person2308"
                        src="/external/person2308-l4pf.svg"
                        className="ekran-zleceotwartychbutton-person15"
                      />
                      <span className="ekran-zleceotwartychbutton-text240">
                        <span>Zgłoszenia: 521</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <button className="ekran-zleceotwartychbutton-icon-button15">
                <img
                  alt="StarI230"
                  src="/external/stari230-43y.svg"
                  className="ekran-zleceotwartychbutton-star15"
                />
              </button>
            </div>
            <div className="ekran-zleceotwartychbutton-group99">
              <div className="ekran-zleceotwartychbutton-frame6816">
                <Link
                  to="/oknozoofer-tbutton"
                  className="ekran-zleceotwartychbutton-button17"
                >
                  <span className="ekran-zleceotwartychbutton-text242 SingleLineBodyBase">
                    <span>Zgłoś ofertę</span>
                  </span>
                </Link>
              </div>
              <div className="ekran-zleceotwartychbutton-frame7616">
                <div className="ekran-zleceotwartychbutton-frame7316">
                  <div className="ekran-zleceotwartychbutton-frame3d-avatars153">
                    <img
                      alt="Avatars3davatar15I230"
                      src="/external/avatars3davatar15i230-ub1c-200h.png"
                      className="ekran-zleceotwartychbutton-avatars3davatar153"
                    />
                  </div>
                  <span className="ekran-zleceotwartychbutton-text244">
                    <span>Dupcyper</span>
                  </span>
                </div>
                <div className="ekran-zleceotwartychbutton-frame7516">
                  <span className="ekran-zleceotwartychbutton-text246">
                    <span>Wykonaj Ahegao</span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7416">
                    <div className="ekran-zleceotwartychbutton-group8416">
                      <span className="ekran-zleceotwartychbutton-text248">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8716">
                      <span className="ekran-zleceotwartychbutton-text250">
                        <span>Design grafika</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8616">
                      <span className="ekran-zleceotwartychbutton-text252">
                        <span>Mascot</span>
                      </span>
                    </div>
                  </div>
                  <span className="ekran-zleceotwartychbutton-text254">
                    <span>Ahegao co by tu mówić więcej</span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7216">
                    <div className="ekran-zleceotwartychbutton-frame7016">
                      <img
                        alt="Dollarsign2308"
                        src="/external/dollarsign2308-61qr.svg"
                        className="ekran-zleceotwartychbutton-dollarsign16"
                      />
                      <span className="ekran-zleceotwartychbutton-text256">
                        <span>Budżet: 20,00 zł</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-frame7116">
                      <img
                        alt="person2308"
                        src="/external/person2308-huzk.svg"
                        className="ekran-zleceotwartychbutton-person16"
                      />
                      <span className="ekran-zleceotwartychbutton-text258">
                        <span>Zgłoszenia: 521</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <button className="ekran-zleceotwartychbutton-icon-button16">
                <img
                  alt="StarI230"
                  src="/external/stari230-lya.svg"
                  className="ekran-zleceotwartychbutton-star16"
                />
              </button>
            </div>
            <div className="ekran-zleceotwartychbutton-group100">
              <button className="ekran-zleceotwartychbutton-icon-button17">
                <img
                  alt="StarI230"
                  src="/external/stari230-7z4f.svg"
                  className="ekran-zleceotwartychbutton-star17"
                />
              </button>
              <div className="ekran-zleceotwartychbutton-frame6817">
                <Link
                  to="/oknozoofer-tbutton"
                  className="ekran-zleceotwartychbutton-button18"
                >
                  <span className="ekran-zleceotwartychbutton-text260 SingleLineBodyBase">
                    <span>Zgłoś ofertę</span>
                  </span>
                </Link>
              </div>
              <div className="ekran-zleceotwartychbutton-frame7617">
                <div className="ekran-zleceotwartychbutton-frame7317">
                  <div className="ekran-zleceotwartychbutton-frame3d-avatars223">
                    <img
                      alt="Avatars3davatar22I230"
                      src="/external/avatars3davatar22i230-qg4a-200h.png"
                      className="ekran-zleceotwartychbutton-avatars3davatar223"
                    />
                  </div>
                  <span className="ekran-zleceotwartychbutton-text262">
                    <span>Andrzej Psikuta</span>
                  </span>
                </div>
                <div className="ekran-zleceotwartychbutton-frame7517">
                  <span className="ekran-zleceotwartychbutton-text264">
                    <span>Wykonaj logo</span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7417">
                    <div className="ekran-zleceotwartychbutton-group8417">
                      <span className="ekran-zleceotwartychbutton-text266">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8717">
                      <span className="ekran-zleceotwartychbutton-text268">
                        <span>Design grafika</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8617">
                      <span className="ekran-zleceotwartychbutton-text270">
                        <span>Mascot</span>
                      </span>
                    </div>
                  </div>
                  <span className="ekran-zleceotwartychbutton-text272">
                    <span>
                      Poszukujemy kreatywnego artysty do zaprojektowania logo
                      dla nowej marki odzieżowej.
                    </span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7217">
                    <div className="ekran-zleceotwartychbutton-frame7017">
                      <img
                        alt="Dollarsign2309"
                        src="/external/dollarsign2309-pbs.svg"
                        className="ekran-zleceotwartychbutton-dollarsign17"
                      />
                      <span className="ekran-zleceotwartychbutton-text274">
                        <span>Budżet: 500,00 zł</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-frame7117">
                      <img
                        alt="person2309"
                        src="/external/person2309-pzr.svg"
                        className="ekran-zleceotwartychbutton-person17"
                      />
                      <span className="ekran-zleceotwartychbutton-text276">
                        <span>Zgłoszenia: 5</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ekran-zleceotwartychbutton-group101">
              <button className="ekran-zleceotwartychbutton-icon-button18">
                <img
                  alt="StarI230"
                  src="/external/stari230-ic0s.svg"
                  className="ekran-zleceotwartychbutton-star18"
                />
              </button>
              <div className="ekran-zleceotwartychbutton-frame6818">
                <Link
                  to="/oknozoofer-tbutton"
                  className="ekran-zleceotwartychbutton-button19"
                >
                  <span className="ekran-zleceotwartychbutton-text278 SingleLineBodyBase">
                    <span>Zgłoś ofertę</span>
                  </span>
                </Link>
              </div>
              <div className="ekran-zleceotwartychbutton-frame7618">
                <div className="ekran-zleceotwartychbutton-frame7318">
                  <div className="ekran-zleceotwartychbutton-frame3d-avatars163">
                    <img
                      alt="Avatars3davatar16I230"
                      src="/external/avatars3davatar16i230-n1l-200h.png"
                      className="ekran-zleceotwartychbutton-avatars3davatar163"
                    />
                  </div>
                  <span className="ekran-zleceotwartychbutton-text280">
                    <span>Śmieszek artysta</span>
                  </span>
                </div>
                <div className="ekran-zleceotwartychbutton-frame7518">
                  <span className="ekran-zleceotwartychbutton-text282">
                    <span>Ilustracja Książkowa</span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7418">
                    <div className="ekran-zleceotwartychbutton-group8418">
                      <span className="ekran-zleceotwartychbutton-text284">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8718">
                      <span className="ekran-zleceotwartychbutton-text286">
                        <span>Illustrator</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8618">
                      <span className="ekran-zleceotwartychbutton-text288">
                        <span>Mascot</span>
                      </span>
                    </div>
                  </div>
                  <span className="ekran-zleceotwartychbutton-text290">
                    <span>
                      Szukamy ilustratora do stworzenia 10 ilustracji do książki
                      dla dzieci.
                    </span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7218">
                    <div className="ekran-zleceotwartychbutton-frame7018">
                      <img
                        alt="Dollarsign2309"
                        src="/external/dollarsign2309-lnqf.svg"
                        className="ekran-zleceotwartychbutton-dollarsign18"
                      />
                      <span className="ekran-zleceotwartychbutton-text292">
                        <span>Budżet: 4000,0 zł</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-frame7118">
                      <img
                        alt="person2309"
                        src="/external/person2309-9fa.svg"
                        className="ekran-zleceotwartychbutton-person18"
                      />
                      <span className="ekran-zleceotwartychbutton-text294">
                        <span>Zgłoszenia: 52</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ekran-zleceotwartychbutton-group103">
              <button className="ekran-zleceotwartychbutton-icon-button19">
                <img
                  alt="StarI230"
                  src="/external/stari230-llgd.svg"
                  className="ekran-zleceotwartychbutton-star19"
                />
              </button>
              <div className="ekran-zleceotwartychbutton-frame6819">
                <Link
                  to="/oknozoofer-tbutton"
                  className="ekran-zleceotwartychbutton-button20"
                >
                  <span className="ekran-zleceotwartychbutton-text296 SingleLineBodyBase">
                    <span>Zgłoś ofertę</span>
                  </span>
                </Link>
              </div>
              <div className="ekran-zleceotwartychbutton-frame7619">
                <div className="ekran-zleceotwartychbutton-frame7319">
                  <div className="ekran-zleceotwartychbutton-frame3d-avatars164">
                    <img
                      alt="Avatars3davatar16I230"
                      src="/external/avatars3davatar16i230-46kr-200h.png"
                      className="ekran-zleceotwartychbutton-avatars3davatar164"
                    />
                  </div>
                  <span className="ekran-zleceotwartychbutton-text298">
                    <span>Śmieszek artysta</span>
                  </span>
                </div>
                <div className="ekran-zleceotwartychbutton-frame7519">
                  <span className="ekran-zleceotwartychbutton-text300">
                    <span>Ilustracja Książkowa</span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7419">
                    <div className="ekran-zleceotwartychbutton-group8419">
                      <span className="ekran-zleceotwartychbutton-text302">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8719">
                      <span className="ekran-zleceotwartychbutton-text304">
                        <span>Illustrator</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8619">
                      <span className="ekran-zleceotwartychbutton-text306">
                        <span>Mascot</span>
                      </span>
                    </div>
                  </div>
                  <span className="ekran-zleceotwartychbutton-text308">
                    <span>
                      Szukamy ilustratora do stworzenia 10 ilustracji do książki
                      dla dzieci.
                    </span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7219">
                    <div className="ekran-zleceotwartychbutton-frame7019">
                      <img
                        alt="Dollarsign2301"
                        src="/external/dollarsign2301-q7pr.svg"
                        className="ekran-zleceotwartychbutton-dollarsign19"
                      />
                      <span className="ekran-zleceotwartychbutton-text310">
                        <span>Budżet: 4000,0 zł</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-frame7119">
                      <img
                        alt="person2301"
                        src="/external/person2301-j1yn.svg"
                        className="ekran-zleceotwartychbutton-person19"
                      />
                      <span className="ekran-zleceotwartychbutton-text312">
                        <span>Zgłoszenia: 52</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ekran-zleceotwartychbutton-group104">
              <div className="ekran-zleceotwartychbutton-frame6820">
                <Link
                  to="/oknozoofer-tbutton"
                  className="ekran-zleceotwartychbutton-button21"
                >
                  <span className="ekran-zleceotwartychbutton-text314 SingleLineBodyBase">
                    <span>Zgłoś ofertę</span>
                  </span>
                </Link>
              </div>
              <div className="ekran-zleceotwartychbutton-frame7620">
                <div className="ekran-zleceotwartychbutton-frame7320">
                  <div className="ekran-zleceotwartychbutton-frame3d-avatars154">
                    <img
                      alt="Avatars3davatar15I230"
                      src="/external/avatars3davatar15i230-elfq-200h.png"
                      className="ekran-zleceotwartychbutton-avatars3davatar154"
                    />
                  </div>
                  <span className="ekran-zleceotwartychbutton-text316">
                    <span>Dupcyper</span>
                  </span>
                </div>
                <div className="ekran-zleceotwartychbutton-frame7520">
                  <span className="ekran-zleceotwartychbutton-text318">
                    <span>Wykonaj Ahegao</span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7420">
                    <div className="ekran-zleceotwartychbutton-group8420">
                      <span className="ekran-zleceotwartychbutton-text320">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8720">
                      <span className="ekran-zleceotwartychbutton-text322">
                        <span>Design grafika</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8620">
                      <span className="ekran-zleceotwartychbutton-text324">
                        <span>Mascot</span>
                      </span>
                    </div>
                  </div>
                  <span className="ekran-zleceotwartychbutton-text326">
                    <span>Ahegao co by tu mówić więcej</span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7220">
                    <div className="ekran-zleceotwartychbutton-frame7020">
                      <img
                        alt="Dollarsign2301"
                        src="/external/dollarsign2301-wub.svg"
                        className="ekran-zleceotwartychbutton-dollarsign20"
                      />
                      <span className="ekran-zleceotwartychbutton-text328">
                        <span>Budżet: 20,00 zł</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-frame7120">
                      <img
                        alt="person2301"
                        src="/external/person2301-t0op.svg"
                        className="ekran-zleceotwartychbutton-person20"
                      />
                      <span className="ekran-zleceotwartychbutton-text330">
                        <span>Zgłoszenia: 521</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <button className="ekran-zleceotwartychbutton-icon-button20">
                <img
                  alt="StarI230"
                  src="/external/stari230-rzg.svg"
                  className="ekran-zleceotwartychbutton-star20"
                />
              </button>
            </div>
            <div className="ekran-zleceotwartychbutton-group102">
              <button className="ekran-zleceotwartychbutton-icon-button21">
                <img
                  alt="StarI230"
                  src="/external/stari230-w29.svg"
                  className="ekran-zleceotwartychbutton-star21"
                />
              </button>
              <div className="ekran-zleceotwartychbutton-frame6821">
                <Link
                  to="/oknozoofer-tbutton"
                  className="ekran-zleceotwartychbutton-button22"
                >
                  <span className="ekran-zleceotwartychbutton-text332 SingleLineBodyBase">
                    <span>Zgłoś ofertę</span>
                  </span>
                </Link>
              </div>
              <div className="ekran-zleceotwartychbutton-frame7621">
                <div className="ekran-zleceotwartychbutton-frame7321">
                  <div className="ekran-zleceotwartychbutton-frame3d-avatars224">
                    <img
                      alt="Avatars3davatar22I230"
                      src="/external/avatars3davatar22i230-e8y-200h.png"
                      className="ekran-zleceotwartychbutton-avatars3davatar224"
                    />
                  </div>
                  <span className="ekran-zleceotwartychbutton-text334">
                    <span>Andrzej Psikuta</span>
                  </span>
                </div>
                <div className="ekran-zleceotwartychbutton-frame7521">
                  <span className="ekran-zleceotwartychbutton-text336">
                    <span>Wykonaj logo</span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7421">
                    <div className="ekran-zleceotwartychbutton-group8421">
                      <span className="ekran-zleceotwartychbutton-text338">
                        <span>LOGO</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8721">
                      <span className="ekran-zleceotwartychbutton-text340">
                        <span>Design grafika</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-group8621">
                      <span className="ekran-zleceotwartychbutton-text342">
                        <span>Mascot</span>
                      </span>
                    </div>
                  </div>
                  <span className="ekran-zleceotwartychbutton-text344">
                    <span>
                      Poszukujemy kreatywnego artysty do zaprojektowania logo
                      dla nowej marki odzieżowej.
                    </span>
                  </span>
                  <div className="ekran-zleceotwartychbutton-frame7221">
                    <div className="ekran-zleceotwartychbutton-frame7021">
                      <img
                        alt="Dollarsign2309"
                        src="/external/dollarsign2309-zxvn.svg"
                        className="ekran-zleceotwartychbutton-dollarsign21"
                      />
                      <span className="ekran-zleceotwartychbutton-text346">
                        <span>Budżet: 500,00 zł</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-frame7121">
                      <img
                        alt="person2301"
                        src="/external/person2301-jvme.svg"
                        className="ekran-zleceotwartychbutton-person21"
                      />
                      <span className="ekran-zleceotwartychbutton-text348">
                        <span>Zgłoszenia: 5</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ekran-zleceotwartychbutton-dialog">
            <div className="ekran-zleceotwartychbutton-group134">
              <div className="ekran-zleceotwartychbutton-header">
                <div className="ekran-zleceotwartychbutton-container11">
                  <div className="ekran-zleceotwartychbutton-container12">
                    <div className="ekran-zleceotwartychbutton-frame141">
                      <div className="ekran-zleceotwartychbutton-container13">
                        <span className="ekran-zleceotwartychbutton-text350">
                          <span>Filtry</span>
                        </span>
                      </div>
                      <button className="ekran-zleceotwartychbutton-button23">
                        <span className="ekran-zleceotwartychbutton-text352">
                          <span>Wyczyść Wszystko</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ekran-zleceotwartychbutton-container14">
                <div className="ekran-zleceotwartychbutton-container15">
                  <div className="ekran-zleceotwartychbutton-container16">
                    <div className="ekran-zleceotwartychbutton-container17">
                      <span className="ekran-zleceotwartychbutton-text354">
                        <span>ogólne</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-horizontal-border1">
                      <div className="ekran-zleceotwartychbutton-container18">
                        <div className="ekran-zleceotwartychbutton-container19">
                          <span className="ekran-zleceotwartychbutton-text356">
                            <span>Specjalizacja</span>
                          </span>
                        </div>
                        <div className="ekran-zleceotwartychbutton-container20">
                          <button className="ekran-zleceotwartychbutton-button-open-dropdown1">
                            <div className="ekran-zleceotwartychbutton-container21">
                              <img
                                alt="SVG3209"
                                src="/external/svg3209-emxi.svg"
                                className="ekran-zleceotwartychbutton-svg1"
                              />
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="ekran-zleceotwartychbutton-horizontal-border2">
                      <div className="ekran-zleceotwartychbutton-container22">
                        <div className="ekran-zleceotwartychbutton-container23">
                          <span className="ekran-zleceotwartychbutton-text358">
                            <span>Czas realizacji</span>
                          </span>
                        </div>
                        <div className="ekran-zleceotwartychbutton-container24">
                          <button className="ekran-zleceotwartychbutton-button-open-dropdown2">
                            <div className="ekran-zleceotwartychbutton-container25">
                              <img
                                alt="SVG3209"
                                src="/external/svg3209-on5v.svg"
                                className="ekran-zleceotwartychbutton-svg2"
                              />
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="ekran-zleceotwartychbutton-horizontal-border3">
                      <div className="ekran-zleceotwartychbutton-container26">
                        <div className="ekran-zleceotwartychbutton-container27">
                          <span className="ekran-zleceotwartychbutton-text360">
                            <span>Opinie klientów</span>
                          </span>
                        </div>
                        <div className="ekran-zleceotwartychbutton-container28">
                          <button className="ekran-zleceotwartychbutton-button-open-dropdown3">
                            <div className="ekran-zleceotwartychbutton-container29">
                              <img
                                alt="SVG3209"
                                src="/external/svg3209-y8ag.svg"
                                className="ekran-zleceotwartychbutton-svg3"
                              />
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="ekran-zleceotwartychbutton-horizontal-border4">
                      <div className="ekran-zleceotwartychbutton-container30">
                        <div className="ekran-zleceotwartychbutton-container31">
                          <span className="ekran-zleceotwartychbutton-text362">
                            <span>Liczba poprawek</span>
                          </span>
                        </div>
                        <div className="ekran-zleceotwartychbutton-container32">
                          <button className="ekran-zleceotwartychbutton-button-open-dropdown4">
                            <div className="ekran-zleceotwartychbutton-container33">
                              <img
                                alt="SVG3209"
                                src="/external/svg3209-49d3.svg"
                                className="ekran-zleceotwartychbutton-svg4"
                              />
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ekran-zleceotwartychbutton-horizontal-border5">
                    <div className="ekran-zleceotwartychbutton-container34">
                      <div className="ekran-zleceotwartychbutton-container35">
                        <span className="ekran-zleceotwartychbutton-text364 pro.fiverr.comArialBold15.75">
                          <span>Budżet</span>
                        </span>
                      </div>
                      <div className="ekran-zleceotwartychbutton-container36">
                        <button className="ekran-zleceotwartychbutton-button-open-dropdown5">
                          <div className="ekran-zleceotwartychbutton-container37">
                            <img
                              alt="SVG3209"
                              src="/external/svg3209-zet.svg"
                              className="ekran-zleceotwartychbutton-svg5"
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="ekran-zleceotwartychbutton-container38">
                    <span className="ekran-zleceotwartychbutton-text366">
                      <span>Detale o artyście</span>
                    </span>
                    <div className="ekran-zleceotwartychbutton-background1">
                      <img
                        alt="Background3209"
                        src="/external/background3209-4ihb-200h.png"
                        className="ekran-zleceotwartychbutton-background2"
                      />
                    </div>
                  </div>
                  <div className="ekran-zleceotwartychbutton-horizontal-border6">
                    <div className="ekran-zleceotwartychbutton-container39">
                      <span className="ekran-zleceotwartychbutton-text368">
                        <span>Pokaż tylko Online</span>
                      </span>
                    </div>
                    <div className="ekran-zleceotwartychbutton-label">
                      <div className="ekran-zleceotwartychbutton-background3">
                        <img
                          alt="Background3209"
                          src="/external/background3209-cp6r-200h.png"
                          className="ekran-zleceotwartychbutton-background4"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ekran-zleceotwartychbutton-footer"></div>
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default EkranZleceotwartychbutton
