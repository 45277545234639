import React from 'react'

import { Helmet } from 'react-helmet'

import './t-rwajcezleceni-abutton.css'

const TRWAJCEZLECENIAbutton = (props) => {
  return (
    <div className="trwajcezleceni-abutton-container">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="trwajcezleceni-abutton-dummy-container">
        <button className="trwajcezleceni-abutton-trwajcezleceni-abutton">
          <div className="trwajcezleceni-abutton-group155">
            <img
              alt="Rectangle933453"
              src="/external/rectangle933453-cwj-600w.png"
              className="trwajcezleceni-abutton-rectangle93"
            />
            <img
              alt="Rectangle943453"
              src="/external/rectangle943453-jx3p-600w.png"
              className="trwajcezleceni-abutton-rectangle94"
            />
          </div>
          <img
            alt="Rectangle713453"
            src="/external/rectangle713453-aata-1000h.png"
            className="trwajcezleceni-abutton-rectangle71"
          />
          <img
            alt="Rectangle703453"
            src="/external/rectangle703453-li6m-200h.png"
            className="trwajcezleceni-abutton-rectangle70"
          />
          <img
            alt="LGOOROBOCZEV00113453"
            src="/external/lgooroboczev00113453-jilb-200h.png"
            className="trwajcezleceni-abutton-lgooroboczev0011"
          />
          <div className="trwajcezleceni-abutton-frame19">
            <span className="trwajcezleceni-abutton-text10">
              <span>Konto</span>
            </span>
            <span className="trwajcezleceni-abutton-text12">
              <span>Wiadomości</span>
            </span>
            <span className="trwajcezleceni-abutton-text14">
              <span>Zamówienia</span>
            </span>
            <span className="trwajcezleceni-abutton-text16">
              <span>Powiadomienia</span>
            </span>
            <span className="trwajcezleceni-abutton-text18">
              <span>Zlecenia</span>
            </span>
          </div>
          <img
            alt="image123453"
            src="/external/image123453-e0x-200h.png"
            className="trwajcezleceni-abutton-image12"
          />
          <span className="trwajcezleceni-abutton-text20">
            <span>Pan Twarug</span>
          </span>
          <img
            alt="Rectangle123453"
            src="/external/rectangle123453-tync-200h.png"
            className="trwajcezleceni-abutton-rectangle12"
          />
          <img
            alt="Rectangle723453"
            src="/external/rectangle723453-yv7b-200h.png"
            className="trwajcezleceni-abutton-rectangle72"
          />
          <img
            alt="Rectangle233453"
            src="/external/rectangle233453-3at-200h.png"
            className="trwajcezleceni-abutton-rectangle23"
          />
          <img
            alt="image133453"
            src="/external/image133453-qi4b-200h.png"
            className="trwajcezleceni-abutton-image13"
          />
          <div className="trwajcezleceni-abutton-frame9">
            <span className="trwajcezleceni-abutton-text22">
              <span>Q&amp;A</span>
            </span>
          </div>
          <img
            alt="Ellipse233453"
            src="/external/ellipse233453-m6uc-200h.png"
            className="trwajcezleceni-abutton-ellipse23"
          />
          <span className="trwajcezleceni-abutton-text24">
            <span>Trwające zlecenia</span>
          </span>
          <span className="trwajcezleceni-abutton-text26">
            <span>Zakończone zlecenia</span>
          </span>
          <div className="trwajcezleceni-abutton-group99">
            <div className="trwajcezleceni-abutton-frame681">
              <button className="trwajcezleceni-abutton-button1">
                <span className="trwajcezleceni-abutton-text28 SingleLineBodyBase">
                  <span>Oddaj zlecenie</span>
                </span>
              </button>
            </div>
            <div className="trwajcezleceni-abutton-frame771">
              <button className="trwajcezleceni-abutton-button2">
                <span className="trwajcezleceni-abutton-text30 SingleLineBodyBase">
                  <span>Otwórz Briff</span>
                </span>
              </button>
            </div>
            <div className="trwajcezleceni-abutton-frame761">
              <div className="trwajcezleceni-abutton-frame731">
                <div className="trwajcezleceni-abutton-frame3d-avatars151">
                  <img
                    alt="Avatars3davatar15I345"
                    src="/external/avatars3davatar15i345-envq-200h.png"
                    className="trwajcezleceni-abutton-avatars3davatar151"
                  />
                </div>
                <span className="trwajcezleceni-abutton-text32">
                  <span>Dupcyper</span>
                </span>
              </div>
              <div className="trwajcezleceni-abutton-frame751">
                <span className="trwajcezleceni-abutton-text34">
                  <span>Wykonaj Ahegao</span>
                </span>
                <div className="trwajcezleceni-abutton-frame741">
                  <div className="trwajcezleceni-abutton-group841">
                    <span className="trwajcezleceni-abutton-text36">
                      <span>LOGO</span>
                    </span>
                  </div>
                  <div className="trwajcezleceni-abutton-group871">
                    <span className="trwajcezleceni-abutton-text38">
                      <span>Design grafika</span>
                    </span>
                  </div>
                  <div className="trwajcezleceni-abutton-group861">
                    <span className="trwajcezleceni-abutton-text40">
                      <span>Mascot</span>
                    </span>
                  </div>
                </div>
                <span className="trwajcezleceni-abutton-text42">
                  <span>Ahegao co by tu mówić więcej</span>
                </span>
                <div className="trwajcezleceni-abutton-frame721">
                  <div className="trwajcezleceni-abutton-frame701">
                    <img
                      alt="Dollarsign3453"
                      src="/external/dollarsign3453-iad.svg"
                      className="trwajcezleceni-abutton-dollarsign1"
                    />
                    <span className="trwajcezleceni-abutton-text44">
                      <span>Budżet: 20,00 zł</span>
                    </span>
                  </div>
                  <div className="trwajcezleceni-abutton-frame711">
                    <div className="trwajcezleceni-abutton-person1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="trwajcezleceni-abutton-group100">
            <div className="trwajcezleceni-abutton-frame772">
              <button className="trwajcezleceni-abutton-button3">
                <span className="trwajcezleceni-abutton-text46 SingleLineBodyBase">
                  <span>Otwórz Briff</span>
                </span>
              </button>
            </div>
            <div className="trwajcezleceni-abutton-frame762">
              <div className="trwajcezleceni-abutton-frame732">
                <div className="trwajcezleceni-abutton-frame3d-avatars152">
                  <img
                    alt="Avatars3davatar15I345"
                    src="/external/avatars3davatar15i345-ll0f-200h.png"
                    className="trwajcezleceni-abutton-avatars3davatar152"
                  />
                </div>
                <span className="trwajcezleceni-abutton-text48">
                  <span>Dupcyper</span>
                </span>
              </div>
              <div className="trwajcezleceni-abutton-frame752">
                <span className="trwajcezleceni-abutton-text50">
                  <span>Wykonaj Logo lodów</span>
                </span>
                <div className="trwajcezleceni-abutton-frame742">
                  <div className="trwajcezleceni-abutton-group842">
                    <span className="trwajcezleceni-abutton-text52">
                      <span>LOGO</span>
                    </span>
                  </div>
                  <div className="trwajcezleceni-abutton-group872">
                    <span className="trwajcezleceni-abutton-text54">
                      <span>Design grafika</span>
                    </span>
                  </div>
                  <div className="trwajcezleceni-abutton-group862">
                    <span className="trwajcezleceni-abutton-text56">
                      <span>Mascot</span>
                    </span>
                  </div>
                </div>
                <span className="trwajcezleceni-abutton-text58">
                  <span>Lody Lody Lody z poznania! loda zrób loda daj</span>
                </span>
                <div className="trwajcezleceni-abutton-frame722">
                  <div className="trwajcezleceni-abutton-frame702">
                    <img
                      alt="Dollarsign3453"
                      src="/external/dollarsign3453-t47.svg"
                      className="trwajcezleceni-abutton-dollarsign2"
                    />
                    <span className="trwajcezleceni-abutton-text60">
                      <span>Budżet: 200 zł</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="trwajcezleceni-abutton-group101">
            <div className="trwajcezleceni-abutton-frame682">
              <button className="trwajcezleceni-abutton-button4">
                <span className="trwajcezleceni-abutton-text62 SingleLineBodyBase">
                  <span>Oddaj zlecenie</span>
                </span>
              </button>
            </div>
            <div className="trwajcezleceni-abutton-frame773">
              <button className="trwajcezleceni-abutton-button5">
                <span className="trwajcezleceni-abutton-text64 SingleLineBodyBase">
                  <span>Otwórz Briff</span>
                </span>
              </button>
            </div>
            <div className="trwajcezleceni-abutton-frame763">
              <div className="trwajcezleceni-abutton-frame733">
                <div className="trwajcezleceni-abutton-frame3d-avatars153">
                  <img
                    alt="Avatars3davatar15I345"
                    src="/external/avatars3davatar15i345-cgok-200h.png"
                    className="trwajcezleceni-abutton-avatars3davatar153"
                  />
                </div>
                <span className="trwajcezleceni-abutton-text66">
                  <span>Dupcyper</span>
                </span>
              </div>
              <div className="trwajcezleceni-abutton-frame753">
                <span className="trwajcezleceni-abutton-text68">
                  <span>Wykonaj Ahegao</span>
                </span>
                <div className="trwajcezleceni-abutton-frame743">
                  <div className="trwajcezleceni-abutton-group843">
                    <span className="trwajcezleceni-abutton-text70">
                      <span>LOGO</span>
                    </span>
                  </div>
                  <div className="trwajcezleceni-abutton-group873">
                    <span className="trwajcezleceni-abutton-text72">
                      <span>Design grafika</span>
                    </span>
                  </div>
                  <div className="trwajcezleceni-abutton-group863">
                    <span className="trwajcezleceni-abutton-text74">
                      <span>Mascot</span>
                    </span>
                  </div>
                </div>
                <span className="trwajcezleceni-abutton-text76">
                  <span>Ahegao co by tu mówić więcej</span>
                </span>
                <div className="trwajcezleceni-abutton-frame723">
                  <div className="trwajcezleceni-abutton-frame703">
                    <img
                      alt="Dollarsign3453"
                      src="/external/dollarsign3453-828p.svg"
                      className="trwajcezleceni-abutton-dollarsign3"
                    />
                    <span className="trwajcezleceni-abutton-text78">
                      <span>Budżet: 20,00 zł</span>
                    </span>
                  </div>
                  <div className="trwajcezleceni-abutton-frame712">
                    <div className="trwajcezleceni-abutton-person2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="trwajcezleceni-abutton-frame162">
            <span className="trwajcezleceni-abutton-text80">!</span>
            <span className="trwajcezleceni-abutton-text81">
              <span>ZGŁOSZONO POPRAWKI DO PROJEKTU</span>
            </span>
            <span className="trwajcezleceni-abutton-text83">!</span>
          </div>
          <div className="trwajcezleceni-abutton-group56">
            <div className="trwajcezleceni-abutton-group441">
              <div className="trwajcezleceni-abutton-frame211">
                <img
                  alt="Ellipse113453"
                  src="/external/ellipse113453-x1r4-200h.png"
                  className="trwajcezleceni-abutton-ellipse111"
                />
                <img
                  alt="Ellipse123453"
                  src="/external/ellipse123453-38g5-200h.png"
                  className="trwajcezleceni-abutton-ellipse121"
                />
                <img
                  alt="Ellipse133453"
                  src="/external/ellipse133453-9tb8-200h.png"
                  className="trwajcezleceni-abutton-ellipse131"
                />
              </div>
            </div>
            <img
              alt="Rectangle223453"
              src="/external/rectangle223453-8u2g-200w.png"
              className="trwajcezleceni-abutton-rectangle221"
            />
            <img
              alt="pngwing13453"
              src="/external/pngwing13453-7ayc-200w.png"
              className="trwajcezleceni-abutton-pngwing11"
            />
            <span className="trwajcezleceni-abutton-text84">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle733453"
              src="/external/rectangle733453-cr5-200w.png"
              className="trwajcezleceni-abutton-rectangle731"
            />
            <span className="trwajcezleceni-abutton-text86">
              <span>Zlecenie 3415: Dupp</span>
            </span>
          </div>
          <div className="trwajcezleceni-abutton-group102">
            <div className="trwajcezleceni-abutton-group442">
              <div className="trwajcezleceni-abutton-frame212">
                <img
                  alt="Ellipse113453"
                  src="/external/ellipse113453-avsm-200h.png"
                  className="trwajcezleceni-abutton-ellipse112"
                />
                <img
                  alt="Ellipse123453"
                  src="/external/ellipse123453-4kkh-200h.png"
                  className="trwajcezleceni-abutton-ellipse122"
                />
                <img
                  alt="Ellipse133453"
                  src="/external/ellipse133453-b9gq-200h.png"
                  className="trwajcezleceni-abutton-ellipse132"
                />
              </div>
            </div>
            <img
              alt="Rectangle223453"
              src="/external/rectangle223453-whsp-200w.png"
              className="trwajcezleceni-abutton-rectangle222"
            />
            <img
              alt="pngwing13453"
              src="/external/pngwing13453-lxz-200w.png"
              className="trwajcezleceni-abutton-pngwing12"
            />
            <span className="trwajcezleceni-abutton-text88">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle733453"
              src="/external/rectangle733453-yep-200w.png"
              className="trwajcezleceni-abutton-rectangle732"
            />
            <span className="trwajcezleceni-abutton-text90">
              <span>Zlecenie 3415: Dupp</span>
            </span>
          </div>
          <div className="trwajcezleceni-abutton-group103">
            <div className="trwajcezleceni-abutton-group443">
              <div className="trwajcezleceni-abutton-frame213">
                <img
                  alt="Ellipse113453"
                  src="/external/ellipse113453-eh27-200h.png"
                  className="trwajcezleceni-abutton-ellipse113"
                />
                <img
                  alt="Ellipse123453"
                  src="/external/ellipse123453-csd-200h.png"
                  className="trwajcezleceni-abutton-ellipse123"
                />
                <img
                  alt="Ellipse133453"
                  src="/external/ellipse133453-lvfn-200h.png"
                  className="trwajcezleceni-abutton-ellipse133"
                />
              </div>
            </div>
            <img
              alt="Rectangle223453"
              src="/external/rectangle223453-un6h-200h.png"
              className="trwajcezleceni-abutton-rectangle223"
            />
            <img
              alt="pngwing13453"
              src="/external/pngwing13453-rai3-200h.png"
              className="trwajcezleceni-abutton-pngwing13"
            />
            <span className="trwajcezleceni-abutton-text92">
              <span>Śmieszek Artysta</span>
            </span>
            <img
              alt="Rectangle733453"
              src="/external/rectangle733453-tr9p-200w.png"
              className="trwajcezleceni-abutton-rectangle733"
            />
            <span className="trwajcezleceni-abutton-text94">
              <span>Zlecenie 3415: Dupp</span>
            </span>
          </div>
          <img
            alt="Ellipse263453"
            src="/external/ellipse263453-7fao-200h.png"
            className="trwajcezleceni-abutton-ellipse26"
          />
          <img
            alt="Ellipse273453"
            src="/external/ellipse273453-7x7mp-200h.png"
            className="trwajcezleceni-abutton-ellipse27"
          />
        </button>
      </div>
    </div>
  )
}

export default TRWAJCEZLECENIAbutton
