import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './desktop1button.css'

const Desktop1button = (props) => {
  return (
    <div className="desktop1button-container1">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="desktop1button-dummy-container">
        <button className="desktop1button-desktop1button">
          <div className="desktop1button-group154">
            <img
              alt="Rectangle1053201"
              src="/external/rectangle1053201-y7co-600w.png"
              className="desktop1button-rectangle1051"
            />
            <img
              alt="Rectangle1063201"
              src="/external/rectangle1063201-0hw-600h.png"
              className="desktop1button-rectangle1061"
            />
          </div>
          <div className="desktop1button-group153">
            <img
              alt="Rectangle933201"
              src="/external/rectangle933201-s27e-600w.png"
              className="desktop1button-rectangle93"
            />
            <img
              alt="Rectangle1053201"
              src="/external/rectangle1053201-dedr-600w.png"
              className="desktop1button-rectangle1052"
            />
            <img
              alt="Rectangle1063201"
              src="/external/rectangle1063201-zbe9-600w.png"
              className="desktop1button-rectangle1062"
            />
          </div>
          <img
            alt="Ellipse92250"
            src="/external/ellipse92250-gay2-1400w.png"
            className="desktop1button-ellipse91"
          />
          <img
            alt="Ellipse92251"
            src="/external/ellipse92251-ira-1000w.png"
            className="desktop1button-ellipse92"
          />
          <div className="desktop1button-frame10">
            <span className="desktop1button-text10">
              <span>Stwórz swoją  Przyszłość!</span>
            </span>
            <span className="desktop1button-text12">
              <span>
                Dołącz do tysięcy innych firm, którzy korzystają z platformy
                Underrate, aby znaleźć artystów do swoich biznesów!
              </span>
            </span>
          </div>
          <span className="desktop1button-text14"></span>
          <img
            alt="Ellipse92510"
            src="/external/ellipse92510-d0p-1400w.png"
            className="desktop1button-ellipse93"
          />
          <img
            alt="Beznazwy112210"
            src="/external/beznazwy112210-rfc-700w.png"
            className="desktop1button-beznazwy11"
          />
          <img
            alt="Rectangle692510"
            src="/external/rectangle692510-3od3-200h.png"
            className="desktop1button-rectangle69"
          />
          <div className="desktop1button-group1151">
            <div className="desktop1button-frame111">
              <Link to="/" className="desktop1button-navlink1">
                <img
                  alt="LGOOROBOCZEV0011205"
                  src="/external/lgooroboczev0011205-pmch-200h.png"
                  className="desktop1button-lgooroboczev00111"
                />
              </Link>
              <div className="desktop1button-group114">
                <div className="desktop1button-input1">
                  <div className="desktop1button-container2">
                    <span className="desktop1button-text15">
                      <span>Czego szukasz towarzyszu?</span>
                    </span>
                  </div>
                </div>
                <button className="desktop1button-button1">
                  <img
                    alt="SVG2541"
                    src="/external/svg2541-osow.svg"
                    className="desktop1button-svg1"
                  />
                </button>
              </div>
              <button className="desktop1button-item-button">
                <div className="desktop1button-group112">
                  <Link
                    to="/communityartystycznebutton"
                    className="desktop1button-text17"
                  >
                    <span>Społeczność Artystyczna</span>
                  </Link>
                  <img
                    alt="Vector2541"
                    src="/external/vector2541-4i6.svg"
                    className="desktop1button-vector1"
                  />
                </div>
              </button>
              <div className="desktop1button-group113">
                <Link
                  to="/communityartystycznebutton"
                  className="desktop1button-text19"
                >
                  <span>Q&amp;A</span>
                </Link>
                <img
                  alt="Vector2541"
                  src="/external/vector2541-jd9.svg"
                  className="desktop1button-vector5"
                />
              </div>
              <div className="desktop1button-item-link10">
                <Link to="/logowani-ebutton" className="desktop1button-text21">
                  <span>Sign in</span>
                </Link>
              </div>
              <Link to="/logowani-ebutton" className="desktop1button-navlink2">
                <div className="desktop1button-item-link11">
                  <span className="desktop1button-text23">
                    <span>Join</span>
                  </span>
                </div>
              </Link>
            </div>
          </div>
          <div className="desktop1button-background">
            <img
              alt="LGOOROBOCZEV00112584"
              src="/external/lgooroboczev00112584-tsl8-200h.png"
              className="desktop1button-lgooroboczev00112"
            />
            <div className="desktop1button-frame115">
              <div className="desktop1button-frame114">
                <span className="desktop1button-text25">
                  <span className="desktop1button-text26">
                    Rozpocznij Nowy Projekt z
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>Najlepszymi Artystami na Underrate!</span>
                </span>
                <span className="desktop1button-text28">
                  <span>
                    Underrate to idealne miejsce dla wszystkich twórców i
                    zleceniodawców. Ułatwiamy artystom prezentowanie swoich
                    dzieł i zdobywanie zleceń, a zleceniodawcom zapewniamy
                    szybki dostęp do utalentowanych wykonawców. Nasza platforma
                    gwarantuje bezpieczeństwo transakcji i profesjonalne
                    wykonanie każdego projektu!
                  </span>
                </span>
              </div>
              <div className="desktop1button-frame113">
                <span className="desktop1button-text30">
                  <span>Co potrzebujesz, by zacząć?</span>
                </span>
                <div className="desktop1button-frame112">
                  <span className="desktop1button-text32">
                    <span>Dla artystów:</span>
                    <br></br>
                    <span>Załóż konto na ArtystaHub.</span>
                    <br></br>
                    <span>
                      Dodaj swoje portfolio, prezentując swoje najlepsze prace.
                    </span>
                    <br></br>
                    <span>
                      Czekaj na zainteresowanych zleceniodawców i przyjmuj
                      zlecenia.
                    </span>
                  </span>
                  <span className="desktop1button-text40">
                    <span>Dla zleceniodawców:</span>
                    <br></br>
                    <span>Załóż konto na ArtystaHub.</span>
                    <br></br>
                    <span>
                      Przeglądaj portfolia artystów i znajdź idealnego
                      wykonawcę.
                    </span>
                    <br></br>
                    <span>
                      Zleć projekt,
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </div>
              </div>
              <button className="desktop1button-button2">
                <Link
                  to="/logowani-ebutton"
                  className="desktop1button-text48 SingleLineBodyBase"
                >
                  <span>Zacznijmy Działać!</span>
                </Link>
              </button>
            </div>
            <div className="desktop1button-maskgroup">
              <div className="desktop1button-group116">
                <img
                  alt="Vector22584"
                  src="/external/vector22584-evhg.svg"
                  className="desktop1button-vector2"
                />
                <img
                  alt="Vector32584"
                  src="/external/vector32584-u9nie.svg"
                  className="desktop1button-vector3"
                />
                <img
                  alt="Vector42584"
                  src="/external/vector42584-6stu.svg"
                  className="desktop1button-vector4"
                />
              </div>
              <img
                alt="wray3scaled12584"
                src="/external/wray3scaled12584-e5f-500w.png"
                className="desktop1button-wray3scaled1"
              />
            </div>
          </div>
          <div className="desktop1button-group117">
            <div className="desktop1button-overlay-shadow10">
              <div className="desktop1button-link10">
                <div className="desktop1button-highlight-spng"></div>
              </div>
            </div>
            <div className="desktop1button-overlay-shadow11">
              <div className="desktop1button-link11">
                <div className="desktop1button-rockband-jpe-gjpg"></div>
              </div>
            </div>
            <div className="desktop1button-overlay-shadow12">
              <div className="desktop1button-link12">
                <div className="desktop1button-frontjpg"></div>
              </div>
            </div>
            <div className="desktop1button-overlay-shadow13">
              <div className="desktop1button-link13">
                <div className="desktop1button-rendering202-gold20-rectangular20-handles2028-aluminum"></div>
              </div>
            </div>
            <div className="desktop1button-overlay-shadow14">
              <div className="desktop1button-link14">
                <div className="desktop1button-interiorpng"></div>
              </div>
            </div>
            <div className="desktop1button-overlay-shadow15">
              <div className="desktop1button-link15">
                <div className="desktop1button-mockupjpg"></div>
              </div>
            </div>
            <div className="desktop1button-overlay-shadow16">
              <div className="desktop1button-link16">
                <div className="desktop1button-coping20modern20minimalist20logo20designs20busines"></div>
              </div>
            </div>
            <div className="desktop1button-overlay-shadow17">
              <div className="desktop1button-link17">
                <div className="desktop1button-view201png"></div>
              </div>
            </div>
            <div className="desktop1button-overlay-shadow18">
              <div className="desktop1button-link18">
                <div className="desktop1button-frame1png"></div>
              </div>
            </div>
            <div className="desktop1button-overlay-shadow19">
              <div className="desktop1button-link19">
                <div className="desktop1button-frame42120bedroom20v120r2png"></div>
              </div>
            </div>
            <div className="desktop1button-overlay-shadow20">
              <div className="desktop1button-link20">
                <div className="desktop1button-fernsehwand2020-wohnzimmer20mit20-esstischfinalkjpg"></div>
              </div>
            </div>
            <div className="desktop1button-overlay-shadow21">
              <div className="desktop1button-link21">
                <div className="desktop1button-frame4jpg"></div>
              </div>
            </div>
          </div>
          <div className="desktop1button-frame119">
            <img
              alt="IMAGE636e0a6a49cf127bf92de1e2iconclydeblurpleRGB12694"
              src="/external/image636e0a6a49cf127bf92de1e2iconclydeblurplergb12694-nfnd-200h.png"
              className="desktop1button-image636e0a6a49cf127bf92de1e2iconclydeblurple-rgb1"
            />
            <img
              alt="SVG2548"
              src="/external/svg2548-vm9i.svg"
              className="desktop1button-svg2"
            />
            <img
              alt="SVG2548"
              src="/external/svg2548-03mj.svg"
              className="desktop1button-svg3"
            />
            <img
              alt="SVG2548"
              src="/external/svg2548-rj9.svg"
              className="desktop1button-svg4"
            />
            <img
              alt="SVG2548"
              src="/external/svg2548-t2l8.svg"
              className="desktop1button-svg5"
            />
          </div>
          <div className="desktop1button-frame128">
            <div className="desktop1button-frame127">
              <span className="desktop1button-text50">
                <span>Kategorie</span>
              </span>
              <div className="desktop1button-frame120">
                <div className="desktop1button-item-link12">
                  <span className="desktop1button-text52">
                    <span>Graphics &amp; Design</span>
                  </span>
                </div>
                <div className="desktop1button-item-link13">
                  <span className="desktop1button-text54">
                    <span>UI UX</span>
                  </span>
                </div>
                <div className="desktop1button-item-link14">
                  <span className="desktop1button-text56">
                    <span>Pisarstwo</span>
                  </span>
                </div>
                <div className="desktop1button-item-link15">
                  <span className="desktop1button-text58">
                    <span>Animacja i montaż</span>
                  </span>
                </div>
                <div className="desktop1button-item-link16">
                  <span className="desktop1button-text60">
                    <span>Audio i muzyka</span>
                  </span>
                </div>
                <div className="desktop1button-item-link17">
                  <span className="desktop1button-text62">
                    <span>Cosplay</span>
                  </span>
                </div>
                <div className="desktop1button-item-link18">
                  <span className="desktop1button-text64">
                    <span>Photography</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="desktop1button-frame126">
              <span className="desktop1button-text66">
                <span>O nas</span>
              </span>
              <div className="desktop1button-frame121">
                <span className="desktop1button-text68">
                  <span>Polityka prywatności</span>
                </span>
                <div className="desktop1button-item-link19">
                  <span className="desktop1button-text70">
                    <span>Terms of Service</span>
                  </span>
                </div>
                <span className="desktop1button-text72">
                  <span>RODO</span>
                </span>
              </div>
            </div>
            <div className="desktop1button-frame124">
              <span className="desktop1button-text74">
                <span>Support i edukacja</span>
              </span>
              <div className="desktop1button-frame122">
                <span className="desktop1button-text76">
                  <span>Pomoc i support</span>
                </span>
                <span className="desktop1button-text78">
                  <span>Trust &amp; Safety</span>
                </span>
                <span className="desktop1button-text80">
                  <span>Przewodnik</span>
                </span>
                <span className="desktop1button-text82">
                  <span>Sprzedaż na Underrate</span>
                </span>
                <span className="desktop1button-text84">
                  <span>Kupowanie na underrate</span>
                </span>
              </div>
            </div>
            <div className="desktop1button-frame125">
              <span className="desktop1button-text86">
                <span>Społeczność</span>
              </span>
              <div className="desktop1button-frame123">
                <span className="desktop1button-text88">
                  <span>Community Artystyczne</span>
                </span>
                <span className="desktop1button-text90">
                  <span>Invite a Friend</span>
                </span>
                <span className="desktop1button-text92">
                  <span>Become a Seller</span>
                </span>
                <span className="desktop1button-text94">
                  <span>Standardy społeczności</span>
                </span>
              </div>
            </div>
          </div>
          <img
            alt="HorizontalDivider2541"
            src="/external/horizontaldivider2541-o3s-200h.png"
            className="desktop1button-horizontal-divider"
          />
          <span className="desktop1button-text96">
            <span>Wykonane na stronie!</span>
          </span>
          <div className="desktop1button-heading51">
            <div className="desktop1button-naadamlogox2a79031dpng"></div>
          </div>
          <div className="desktop1button-picturetestimonialvideostillnaadamjpg"></div>
          <img
            alt="Image2585"
            src="/external/image2585-pbdl-200h.png"
            className="desktop1button-image1"
          />
          <img
            alt="Heading52585"
            src="/external/heading52585-uozj-200h.png"
            className="desktop1button-heading52"
          />
          <img
            alt="Image2585"
            src="/external/image2585-5fs4-200h.png"
            className="desktop1button-image2"
          />
          <img
            alt="Heading52585"
            src="/external/heading52585-t5ae-200h.png"
            className="desktop1button-heading53"
          />
          <img
            alt="Image2585"
            src="/external/image2585-b4kj-200h.png"
            className="desktop1button-image3"
          />
          <img
            alt="image202585"
            src="/external/image202585-t7pc-400h.png"
            className="desktop1button-image20"
          />
          <span className="desktop1button-text98">
            <span>Opinie o Underrate</span>
          </span>
          <div className="desktop1button-frame118">
            <div className="desktop1button-frame116">
              <span className="desktop1button-text100">
                <span>Jakiś chuj z avengersów</span>
              </span>
            </div>
            <div className="desktop1button-frame117">
              <img
                alt="MarvelsTheAvengerslogosvg12585"
                src="/external/marvelstheavengerslogosvg12585-n1ji-200h.png"
                className="desktop1button-marvels-the-avengerslogosvg1"
              />
            </div>
            <span className="desktop1button-text102">
              <span>
                Underrate to świetne rozwiązanie dla każdego kto prowadzi swój
                biznes! Szybko znalazłem idealnego grafika dzięki przejrzystemu
                interfejsowi i bogatej ofercie portfoliów. Gwarancja zadatku
                dała mi pewność, że projekt będzie wykonany profesjonalnie i
                zgodnie z oczekiwaniami
              </span>
            </span>
          </div>
          <div className="desktop1button-group1152">
            <div className="desktop1button-input2">
              <div className="desktop1button-container3"></div>
              <span className="desktop1button-text104">
                <span>Czego szukasz towarzyszu?</span>
              </span>
            </div>
            <button className="desktop1button-button3">
              <img
                alt="SVG2574"
                src="/external/svg2574-bwtg.svg"
                className="desktop1button-svg6"
              />
            </button>
          </div>
        </button>
      </div>
    </div>
  )
}

export default Desktop1button
